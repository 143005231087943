import { render } from 'preact';
import { ClientContext } from '../core/ClientContextFactory';
import { PrimerClientError } from '../errors';
import { ErrorCode } from '../types';

export const teardownUI = (
  root: Document | Element | ShadowRoot | DocumentFragment,
) => render(null, root);

export const createTeardownError = () =>
  PrimerClientError.fromErrorCode(ErrorCode.PRIMER_TEARDOWN, {
    message: 'Primer has been torn down',
  });

export const throwTeardownError = () => {
  throw createTeardownError();
};

export const throwIfTeardown = (context: ClientContext) => {
  if (context.session.isTeardown) {
    throwTeardownError();
  }
};

export const throwAsyncIfTeardown = async <T>(
  fn: () => Promise<T>,
  context: ClientContext,
): Promise<T> => {
  throwIfTeardown(context);
  const result = await fn();
  throwIfTeardown(context);
  return result;
};
