import { useCheckoutContext } from '@primer-io/shared-library/contexts';
import { SceneEnum } from '@primer-sdk-web/enums/Checkout';
import { useDisableComponent } from '@primer-sdk-web/utils/hooks';
import { FunctionalComponent } from 'preact';
import { FeatherProps } from 'preact-feather/dist/types';

type Props = {
  buttonId?: string;
  rootId?: string;
  backgroundColor: string;
  text?: string;
  textColor?: string;
  icon?: any;
  iconAlt?: string;
  iconHeight?: string;
  featherIcon?: FunctionalComponent<FeatherProps>;

  onClick: () => void;
  onLoad?: () => void;
};

const IconTextButton: FunctionalComponent<Props> = ({
  buttonId,
  rootId,
  backgroundColor,
  text,
  textColor,
  icon,
  iconAlt,
  iconHeight,
  featherIcon: FeatherIcon,
  onClick,
  onLoad,
}) => {
  const { className } = useCheckoutContext();

  const { disableNavigation } = useDisableComponent({
    scene: SceneEnum.CHOOSE_PAYMENT_METHOD,
  });

  return (
    <div
      id={rootId}
      style={{ background: backgroundColor }}
      className={className('apmButton', { managed: true, logo: true })}
    >
      <button
        type='button'
        role='button'
        className='PrimerCheckout__iconTextButton'
        id={buttonId}
        onClick={onClick}
        disabled={disableNavigation}
        style={{ color: textColor }}
      >
        {icon && (
          <img
            style={{ height: iconHeight }}
            alt={iconAlt}
            src={icon}
            onLoad={onLoad}
          />
        )}
        {FeatherIcon && !icon && <FeatherIcon className='icon' />}
        {text && (
          <span className='PrimerCheckout__iconTextButtonLabel'>{text}</span>
        )}
      </button>
    </div>
  );
};

export default IconTextButton;
