import { ClientContext } from '../../core/ClientContextFactory';
import { ElementID } from '../../enums/Checkout';
import ThreeDSecure from '../../three-d-secure/ThreeDSecure';
import { WindowSize } from '../../three-d-secure/types';
import { IViewUtils } from './types';

const sceneRootId = 'primer-checkout-scene-3ds';

const ThreeDSUtils = {
  setup(context: ClientContext, viewUtils: IViewUtils): ThreeDSecure {
    const threeDS = new ThreeDSecure({ context });

    threeDS.setup({
      container: `#${ElementID.THREE_DS_MODAL}`,
      onChallengeStart: (size?: WindowSize) => {
        if (size) {
          const body = window.document.getElementById(
            'primer-checkout-3ds-modal',
          );
          if (body) {
            body.style.height = `min(calc(100vh - 16px), ${size.height}px)`;
            body.style.width = `min(calc(100vw - 16px), ${size.width}px)`;
          }
        }
        viewUtils.toggleVisibilityAnimated(sceneRootId, true, {
          duration: 500,
        });
      },
      onChallengeEnd: () =>
        viewUtils.toggleVisibilityAnimated(sceneRootId, false, {
          duration: 500,
        }),
    });

    return threeDS;
  },
};

export default ThreeDSUtils;
