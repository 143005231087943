import { VaultListItem, PaymentMethodToken } from '../../types';
import { PaymentInstrumentType } from '../../enums/Tokens';
import { Icons } from '../../assets/icons/Icons';
import { VaultedPaymentMethod } from './Vault';

export function formatVaultedToken(
  token: VaultedPaymentMethod,
): Nullable<VaultListItem> {
  switch (token.paymentInstrumentType) {
    case PaymentInstrumentType.CARD:
      return {
        id: token.id,
        type: PaymentInstrumentType.CARD,
        details: {
          last4Digits: token.paymentInstrumentData.last4Digits,
          cardNumber: `●●●● ${token.paymentInstrumentData.last4Digits}`,
          name: token.paymentInstrumentData.cardholderName,
          icon: iconFromNetwork(token.paymentInstrumentData.network),
          network: token.paymentInstrumentData.binData.network,
          userDescription: token.userDescription,
        },
      };

    case PaymentInstrumentType.PAYPAL_VAULTED:
      return {
        id: token.id,
        type: PaymentInstrumentType.PAYPAL_VAULTED,
        details: {
          icon: Icons.paypal,
          email: token.paymentInstrumentData.externalPayerInfo?.email,
        },
      };

    default:
      return null;
  }
}

function iconFromNetwork(network = ''): string {
  const lower = network.toLowerCase();
  const icon = Icons[lower];
  return icon || Icons.genericCard;
}

export const paymentMethodTokenToVaultedPaymentMethod = ({
  token,
  tokenType,
  ...other
}: PaymentMethodToken): VaultedPaymentMethod => ({
  id: token,
  ...other,
});
