import { useLayoutEffect, useMemo, useRef } from 'preact/hooks';
import {
  useCheckoutContext,
  useCheckoutStore,
  useSelector,
  useSceneLoaderContext,
} from '@primer-io/shared-library/contexts';
import styled from 'styled-components';
import {
  useIsCurrentScene,
  useScene,
  useSceneEntering,
  useSceneMounting,
} from '../utils/hooks';
import { SceneEnum } from '../enums/Checkout';
import CreditCardForm from '../payment-methods/credit-card/CreditCardForm';
import VaultMenu from '../checkout-modules/vault/components/VaultMenu';
import VaultedPaymentMethod from '../checkout-modules/vault/components/VaultedPaymentMethod/VaultedPaymentMethod';
import { AnimatePresence } from '../utils/AnimatePresence';
import {
  useWait,
  WaitForComponents,
} from '../utils/SceneLoader/WaitForComponents';
import PaymentMethodButton from '../components/PaymentMethodButton';
import { VaultManagerOptions } from '../types';
import { PaymentMethodType } from '../loader';
import { CardTokenizationService } from '../checkout/CardTokenizationService';
import VaultTitle from '../components/VaultTitle';
import { TextDividerStyle } from '../components/TextDivider';

const SCENE = SceneEnum.MANAGE_PAYMENT_METHODS;

export const sceneRootId = 'primer-checkout-scene-manage-payment-methods';

const Divider = styled.div`
  ${TextDividerStyle}
`;

const VaultManagerScene = () => {
  const store = useCheckoutStore();
  const { viewUtils, options } = useCheckoutContext();
  const { setIsMounted } = useSceneLoaderContext();
  const vault = useSelector((s) => s.vault);
  const labels = useSelector((s) => s.translation);

  const cardTokenizationService = useRef(
    new CardTokenizationService(store, options),
  ).current;

  const emptyTextRef = useRef<HTMLDivElement>();
  const isCurrentScene = useIsCurrentScene(SceneEnum.MANAGE_PAYMENT_METHODS);

  const paymentMethods = useSelector((s) => s.paymentMethods);

  const paymentMethodButtons = useMemo(() => {
    return Object.entries(paymentMethods).filter(
      ([key]) => key !== PaymentMethodType.PAYMENT_CARD,
    );
  }, [paymentMethods]);

  const vaultOptions = options as VaultManagerOptions;

  const { ready } = useWait(() => {
    setIsMounted();
  }, ['components', 'animation']);

  const toggleEmpty = async (
    isEmpty: boolean,
    animated: boolean,
  ): Promise<void> => {
    viewUtils.toggleVisibilityAnimated('primer-checkout-actions', !isEmpty, {
      duration: animated ? 300 : 0,
    });

    viewUtils.toggleVisibilityAnimated(
      'primer-checkout-saved-payment-methods-empty-text',
      isEmpty,
      {
        duration: animated ? 300 : 0,
        animateHeight: true,
        autoHeight: true,
      },
    );

    viewUtils.toggleVisibilityAnimated(
      'primer-checkout-payment-method-divider',
      !isEmpty,
      {
        duration: animated ? 300 : 0,
        animateHeight: true,
        autoHeight: true,
      },
    );
  };

  useSceneMounting(() => {
    (async () => {
      await toggleEmpty(!store.hasVault, false);
      ready('animation');
    })();
  }, SCENE);

  useLayoutEffect(() => {
    if (isCurrentScene) {
      toggleEmpty(!store.hasVault, true);
    }
  }, [store.hasVault]);

  useScene(() => {
    const handleSubmitButtonClick = async () => {
      cardTokenizationService.tokenizeCard();
    };

    store.addSubmitButtonClickListener(handleSubmitButtonClick);
    return () => {
      store.removeSubmitButtonClickListener(handleSubmitButtonClick);
    };
  }, SCENE);

  useSceneEntering(() => {
    store.setSubmitButtonVisible(true);
    store.setSubmitButtonContent(labels?.addCard ?? 'add card');
    store.setSubmitButtonDisabled(false);
  }, SCENE);

  ///////////////////////////////////////////
  // Render
  ///////////////////////////////////////////

  const renderEditButton = () => {
    return (
      !(vaultOptions.deletionDisabled ?? false) && (
        <div
          id='primer-checkout-vault-edit-button'
          data-testId='CheckoutVaultScene.vaultEditButton'
          className='PrimerCheckout__savedPaymentMethodActions'
        >
          <VaultMenu />
        </div>
      )
    );
  };

  return (
    <WaitForComponents onReady={() => ready('components')}>
      <div
        id={sceneRootId}
        className='PrimerCheckout__scene PrimerCheckout__scene--managePaymentMethods PrimerCheckout--exited'
      >
        <div>
          <div
            id='primer-checkout-vault'
            className='PrimerCheckout__sceneElement PrimerCheckout__savedPaymentMethods'
          >
            <div className='PrimerCheckout__savedPaymentMethods__title'>
              <VaultTitle>{labels?.managePaymentMethods}</VaultTitle>
              {renderEditButton()}
            </div>

            <div
              ref={emptyTextRef}
              id='primer-checkout-saved-payment-methods-empty-text'
              className='PrimerCheckout__emptyText'
            >
              <span className='PrimerCheckout__label'>
                {labels?.savedPaymentMethodsEmpty}
              </span>
            </div>

            <div className='PrimerCheckout__savedPaymentMethodContainer'>
              <AnimatePresence>
                {vault.items.map((vaultedItem) => (
                  <VaultedPaymentMethod
                    key={vaultedItem.id}
                    vaultedItem={vaultedItem}
                  />
                ))}
              </AnimatePresence>
            </div>
          </div>

          <Divider
            id='primer-checkout-payment-method-divider'
            className='PrimerCheckout__sceneElement PrimerCheckout__divider'
          >
            <span className='PrimerCheckout__label PrimerCheckout__label--muted'>
              {labels?.newPaymentMethod}
            </span>
          </Divider>
          {paymentMethodButtons.length > 0 && (
            <div
              id='primer-checkout-apm-button-container'
              className='PrimerCheckout__sceneElement PrimerCheckout__apmButtonContainer'
            >
              {paymentMethodButtons.map(([key, paymentMethod]) => (
                <PaymentMethodButton
                  key={paymentMethod.displayName}
                  id={key}
                  paymentMethod={paymentMethod}
                />
              ))}
            </div>
          )}

          <CreditCardForm />
          <div></div>
        </div>
      </div>
    </WaitForComponents>
  );
};

export default VaultManagerScene;
