import { CardMetadata } from '../../hosted-scripts/CardMetadata';
import { BaseStore, IBaseState } from '../../store/BaseStore';

type FieldState = {
  error?: string;
  isFocused: boolean;
};
interface State extends IBaseState {
  numberField: FieldState;
  expiryDateField: FieldState;
  cvvField: FieldState;
  cardholderNameField: FieldState;

  metadata: CardMetadata;
}

const getDefaultFieldState = () => ({ error: undefined, isFocused: false });

const defaultState: State = {
  numberField: getDefaultFieldState(),
  expiryDateField: getDefaultFieldState(),
  cvvField: getDefaultFieldState(),
  cardholderNameField: getDefaultFieldState(),

  metadata: {
    cvvLength: 3,
    type: null,
    possibleTypes: [],
    cardNumberLength: 0,
  },
};

export const CreditCardStoreSelector = {
  getNumberFieldError: (s: State) => s.numberField.error,
  getNumberFieldFocused: (s: State) => s.numberField.isFocused,

  getCvvFieldError: (s: State) => s.cvvField.error,
  getCvvFieldFocused: (s: State) => s.cvvField.isFocused,

  getExpiryDateFieldError: (s: State) => s.expiryDateField.error,
  getExpiryDateFieldFocused: (s: State) => s.expiryDateField.isFocused,

  getCardholderNameFieldError: (s: State) => s.cardholderNameField.error,
  getCardholderNameFieldFocused: (s: State) => s.cardholderNameField.isFocused,

  getMetadata: (s: State) => s.metadata,
  getCardNetwork: (s: State) => s.metadata.type,
};

export class CreditCardStore extends BaseStore<State> {
  setNumberFieldError(error?: string | null) {
    this.produceState((draft) => {
      draft.numberField.error = error ?? undefined;
    });
  }

  setNumberFieldFocused(isFocused: boolean) {
    this.produceState((draft) => {
      draft.numberField.isFocused = isFocused;
    });
  }

  setExpiryDateFieldError(error?: string | null) {
    this.produceState((draft) => {
      draft.expiryDateField.error = error ?? undefined;
    });
  }

  setExpiryDateFieldFocused(isFocused: boolean) {
    this.produceState((draft) => {
      draft.expiryDateField.isFocused = isFocused;
    });
  }

  setCvvFieldError(error?: string | null) {
    this.produceState((draft) => {
      draft.cvvField.error = error ?? undefined;
    });
  }

  setCvvFieldFocused(isFocused: boolean) {
    this.produceState((draft) => {
      draft.cvvField.isFocused = isFocused;
    });
  }

  setCardholderNameFieldError(error?: string | null) {
    this.produceState((draft) => {
      draft.cardholderNameField.error = error ?? undefined;
    });
  }

  setMetadata(metadata: CardMetadata) {
    this.produceState((draft) => {
      draft.metadata = metadata;
    });
  }

  get currentCardNetwork(): string | null {
    return CreditCardStoreSelector.getCardNetwork(this.getState());
  }
}

const createCreditCardStore = () => new CreditCardStore(defaultState);
export default createCreditCardStore;
