import urljoin from 'url-join';
import {
  createErrorMonitoring,
  ErrorMonitoring,
} from '../monitoring/ErrorMonitoring';
import { Analytics, createAnalytics } from '../analytics/Analytics';
import { ApiEvent } from '../analytics/constants/enums';
import { CheckoutUXFlow } from '../enums/Checkout';
import { PaymentFlow } from '../enums/Tokenization';
import { PaymentMethodType } from '../enums/Tokens';
import { InternalClientSession } from '../models/ClientSession';
import {
  UniversalCheckoutOptions,
  SinglePaymentMethodCheckoutOptions,
  VaultManagerOptions,
} from '../types';
import { Nullable } from '../utilities';
import { Environment } from '../utils/Environment';
import ModuleFactory from '../utils/ModuleFederation/ModuleFactory';
import Package from '../utils/ModuleFederation/Package';

import { ScriptLoader } from '../utils/ScriptLoader';
import toCamelCase from '../utils/toCamelCase';
import { Api } from './Api';
import ClientConfigurationHandler from './ClientConfigurationHandler';
import {
  createClientTokenHandler,
  IClientTokenHandler,
  DecodedClientToken,
} from './ClientTokenHandler';
import { IFrameEventType } from './IFrameEventType';
import { IFrameFactory } from './IFrameFactory';
import { IFrameMessageBus } from './IFrameMessageBus';
import { LongPoll } from './LongPoll';
import {
  ErrorCode,
  PrimerClientError,
  throwPrimerClientError,
} from '../errors';
import { InternalFlowOptions } from '../internalTypes';

export interface PaymentMethodConfig {
  id: string;
  type: PaymentMethodType;
  options:
    | {
        threeDSecureToken?: string;
        threeDSecureInitUrl?: string;
        threeDSecureProvider?: string;
        threeDSecureEnabled?: boolean;
      }
    | any;
}

export interface CheckoutModuleConfig extends Record<string, any> {
  type: string;
}

export interface ClientSessionInfo {
  assetsUrl: string;
  coreUrl: string;
  pciUrl: string;
  modulesUrl: string;
  env: string;
  production: boolean;
  paymentFlow: PaymentFlow;
  paymentMethods: PaymentMethodConfig[];
  checkoutModules: CheckoutModuleConfig[];
  threeDSecureToken: Nullable<string>;
  threeDSecureInitUrl: Nullable<string>;
  threeDSecureProvider: Nullable<string>;
  threeDSecureEnabled: boolean;
  accessToken: string;
  isTeardown: boolean;
  clientSession: InternalClientSession;
}

export interface ClientContext {
  clientTokenHandler: IClientTokenHandler;
  clientConfigurationHandler: ClientConfigurationHandler;
  iframes: IFrameFactory;
  messageBus: IFrameMessageBus;
  api: Api;
  longPoll: LongPoll;
  analytics: Analytics;
  errorMonitoring: ErrorMonitoring;
  scriptLoader: ScriptLoader;
  session: ClientSessionInfo;
  moduleFactory: ModuleFactory;
  packages: Package[];
  clientOptions: InternalFlowOptions;
}

export interface ClientConfiguration {
  coreUrl: string;
  pciUrl: string;
  env: string;
  paymentMethods: PaymentMethodConfig[];
  checkoutModules: CheckoutModuleConfig[];
  clientSession: InternalClientSession;
}

//TODO: Remove when all payment methods are in an external module
const paymentMethodsNotInExternalModule = [PaymentMethodType.PAYMENT_CARD];

export const ClientContextFactory = {
  async create({
    options,
    renderOptions: clientOptions,
  }: {
    options: { clientToken: string };
    renderOptions:
      | UniversalCheckoutOptions
      | VaultManagerOptions
      | SinglePaymentMethodCheckoutOptions;
  }): Promise<ClientContext> {
    const clientTokenHandler = createClientTokenHandler();

    const {
      accessToken,
      analyticsUrl,
      paymentFlow,
    } = initializeClientTokenHandler(clientTokenHandler, options.clientToken);

    const assetsUrl = getAssetsURL();

    const messageBus = new IFrameMessageBus();
    const iframes = new IFrameFactory({ messageBus, assetsUrl });
    const api = new Api({ iframes, messageBus, accessToken });
    const longPoll = new LongPoll({ api });

    clientTokenHandler.addNewClientTokenListener(
      ({ accessToken: newAccessToken }) => api.setAccessToken(newAccessToken),
    );

    const clientConfigurationHandler = new ClientConfigurationHandler(
      api,
      clientTokenHandler,
    );

    const configurationData = await getClientConfiguration(
      api,
      options.clientToken,
      clientConfigurationHandler,
    );

    if (configurationData.paymentMethods.length === 0) {
      throw PrimerClientError.fromErrorCode(ErrorCode.NO_PAYMENT_METHODS, {
        message: `No payment methods found for this session. Cannot initialize the SDK. \nMake sure that the payment methods are properly configured on your Dashboard, and that at least one payment method can be displayed with the data provided in the Client Session.`,
      });
    }

    const { clientSession } = configurationData;

    const analytics = initializeAnalytics(
      configurationData,
      clientOptions,
      analyticsUrl,
    );

    const errorMonitoring = createErrorMonitoring();

    messageBus.publish('api-controller', {
      type: IFrameEventType.SESSION,
      payload: {
        coreUrl: configurationData.coreUrl,
        pciUrl: configurationData.pciUrl,
      },
    });

    const paymentMethods = filterPaymentMethodConfigs(
      configurationData.paymentMethods,
      clientOptions,
    );
    if (paymentMethods.length === 0) {
      throw PrimerClientError.fromErrorCode(ErrorCode.NO_PAYMENT_METHODS, {
        message: `\`allowedPaymentMethods\` filtered out all the payment methods. Cannot initialize the SDK.\n Make sure that \`allowedPaymentMethods\` does not disallow all your supported payment methods.`,
      });
    }

    const scriptLoader = new ScriptLoader();
    const moduleFactory = new ModuleFactory(scriptLoader);
    const modulesUrl = getModulesUrl();

    const packages = await buildPackages(
      paymentMethods,
      modulesUrl,
      moduleFactory,
    );

    const cardPaymentMethod = configurationData?.paymentMethods.find(
      (elm) => elm.type === PaymentMethodType.PAYMENT_CARD,
    );

    const { checkoutModules } = configurationData;

    return {
      clientOptions,
      clientTokenHandler,
      clientConfigurationHandler,
      iframes,
      messageBus,
      api,
      longPoll,
      analytics,
      errorMonitoring,
      scriptLoader,
      moduleFactory,
      packages,
      session: {
        isTeardown: false,
        assetsUrl,
        coreUrl: Environment.get(
          'PRIMER_CORE_API_URL',
          configurationData.coreUrl,
        ),
        pciUrl: Environment.get('PRIMER_PCI_API_URL', configurationData.pciUrl),
        modulesUrl,
        env: configurationData.env,
        production: configurationData.env === 'PRODUCTION',
        paymentMethods,
        checkoutModules,
        paymentFlow: paymentFlow || PaymentFlow.DEFAULT,
        accessToken,
        clientSession,

        // TODO: refactor the usage of 3DS tokens
        get threeDSecureToken(): Nullable<string> {
          return cardPaymentMethod?.options?.threeDSecureToken ?? null;
        },
        get threeDSecureInitUrl(): Nullable<string> {
          return cardPaymentMethod?.options?.threeDSecureInitUrl ?? null;
        },
        get threeDSecureProvider(): string {
          return cardPaymentMethod?.options?.threeDSecureProvider ?? 'CARDINAL';
        },
        get threeDSecureEnabled(): boolean {
          return (
            cardPaymentMethod?.options?.threeDSecureEnabled ??
            Boolean(cardPaymentMethod?.options?.threeDSecureInitUrl ?? null)
          );
        },
      },
    };
  },
};

function initializeClientTokenHandler(
  clientTokenHandler: IClientTokenHandler,
  clientToken: string,
): DecodedClientToken {
  clientTokenHandler.setClientToken(clientToken);
  const decodedClientToken = clientTokenHandler.getCurrentDecodedClientToken();
  if (!decodedClientToken) {
    throw new TypeError('Cannot parse client token');
  }

  return decodedClientToken;
}

async function getClientConfiguration(
  api: Api,
  clientToken: string,
  clientConfigurationHandler: ClientConfigurationHandler,
) {
  const data = await clientConfigurationHandler.getClientConfiguration(
    clientToken,
  );

  return data;
}

export function initializeAnalytics(
  configurationData: ClientConfiguration,
  renderOptions: InternalFlowOptions,
  analyticsUrl?: string,
): Analytics {
  let amount = configurationData.clientSession?.order?.totalOrderAmount;

  if (typeof amount === 'string') {
    amount = parseFloat(amount);
  }

  const analytics = createAnalytics(analyticsUrl);

  analytics.setContext({
    amount,
    currency: configurationData.clientSession?.order?.currencyCode,
    isVault: renderOptions.uxFlow === CheckoutUXFlow.MANAGE_PAYMENT_METHODS,
  });

  analytics.call({ event: ApiEvent.startedCheckout });
  analytics.time({ event: ApiEvent.loadedCheckoutUi });
  analytics.time({ event: ApiEvent.completedCheckout });

  analytics.setSdkEnvironment(configurationData.env);

  return analytics;
}

export function getAssetsURL(): string {
  const version = Environment.get('PRIMER_SDK_VERSION', '0.0.0-local');
  return urljoin(Environment.get('PRIMER_ASSETS_URL', ''), version);
}

const getModulesUrl = () => {
  const version = Environment.get('PRIMER_SDK_VERSION', '0.0.0-local');
  return urljoin(Environment.get('PRIMER_MODULES_URL', ''), version);
};

export async function buildPackages(
  paymentMethods: PaymentMethodConfig[],
  modulesUrl: string,
  moduleFactory: ModuleFactory,
) {
  return (
    await Promise.all(
      paymentMethods
        //TODO: Remove when all payment methods are in an external module
        .filter(
          (paymentMethod) =>
            !paymentMethodsNotInExternalModule.includes(paymentMethod.type),
        )
        //
        .map(async (paymentMethod) => {
          const moduleName = paymentMethod.type
            .toLowerCase()
            .replace(/_/g, '-');
          const remotePath = urljoin(
            modulesUrl,
            '/payment-methods/',
            moduleName,
          );
          const pack = moduleFactory.getPackage({
            remotePath,
            scope: toCamelCase(paymentMethod.type),
          });

          try {
            await pack.getDeclaration();
          } catch (e) {
            return null;
          }

          return pack;
        }),
    )
  ).filter((p) => !!p) as Package[];
}

const DEACTIVATED_PAYMENT_METHODS = ['ADYEN_BANK_TRANSFER'];

export const filterPaymentMethodConfigs = (
  paymentMethods: PaymentMethodConfig[],
  renderOptions: InternalFlowOptions,
): PaymentMethodConfig[] => {
  paymentMethods = paymentMethods.filter(
    (paymentMethod) =>
      !DEACTIVATED_PAYMENT_METHODS.includes(paymentMethod.type),
  );

  if (
    !renderOptions.uxFlow ||
    renderOptions.uxFlow === CheckoutUXFlow.CHECKOUT
  ) {
    paymentMethods = paymentMethods.filter(
      (paymentMethod) =>
        (renderOptions as UniversalCheckoutOptions).allowedPaymentMethods?.includes(
          paymentMethod.type,
        ) ?? true,
    );
  } else if (
    renderOptions.uxFlow === CheckoutUXFlow.SINGLE_PAYMENT_METHOD_CHECKOUT
  ) {
    paymentMethods = paymentMethods.filter(
      (paymentMethod) =>
        (renderOptions as SinglePaymentMethodCheckoutOptions).paymentMethod ===
        paymentMethod.type,
    );

    if (!(renderOptions as SinglePaymentMethodCheckoutOptions).paymentMethod) {
      throwPrimerClientError(
        PrimerClientError.fromErrorCode(ErrorCode.PAYMENT_METHOD_NOT_PROVIDED, {
          message:
            'Checkout could not be initialized. Please provide the payment method which should be rendered in the single payment method flow.',
        }),
      );
    }

    if (paymentMethods.length === 0) {
      throwPrimerClientError(
        PrimerClientError.fromErrorCode(ErrorCode.PAYMENT_METHOD_NOT_SETUP, {
          message: `Checkout could not be initialized. ${
            (renderOptions as SinglePaymentMethodCheckoutOptions).paymentMethod
          } has not been configured for this account. Please configure the connection in your Primer dashboard: https://sandbox-dashboard.primer.io`,
        }),
      );
    }
  }

  return paymentMethods;
};
