import { PaymentMethodType } from '../enums/Tokens';
import { CreditCard } from './credit-card/CreditCard';
import { IPaymentMethodContextFactory } from '../core/PaymentMethodContextFactory';
import normalizeOptions from './normalizeOptions';
import { createStyleManager, IStyleManager } from '../checkout/ui/StyleManager';
import { BasePaymentMethod } from './BasePaymentMethod';
import { getPaymentMethodModule } from '../utils/ModuleResolver';
import { ClientContext } from '../core/ClientContextFactory';

export const localPaymentMethodDefinitions: Record<
  string,
  { PaymentMethod: typeof BasePaymentMethod }
> = {
  [PaymentMethodType.PAYMENT_CARD]: {
    PaymentMethod: (CreditCard as unknown) as typeof BasePaymentMethod,
  },
};

export const PaymentMethods = {
  async create(
    context: ClientContext,
    paymentMethodContextFactory: IPaymentMethodContextFactory,
    opts: Record<string, unknown>,
    styleManager: IStyleManager = createStyleManager(),
  ): Promise<Record<string, BasePaymentMethod>> {
    const options = normalizeOptions(opts);

    const promises = context.session.paymentMethods.map(
      async (paymentMethodConfig) => {
        const { type } = paymentMethodConfig;

        const localDefinition = localPaymentMethodDefinitions[type];
        let PaymentMethod: typeof BasePaymentMethod;

        if (localDefinition) {
          PaymentMethod = localDefinition.PaymentMethod;
        } else {
          try {
            //  Get module
            const PaymentMethodModule = getPaymentMethodModule(context, type);

            // Import PaymentMethod
            PaymentMethod = ((await PaymentMethodModule.import()) as unknown) as typeof BasePaymentMethod;
          } catch (e) {
            console.error(e);
            console.warn(`Can't load ${type}`);
            return null;
          }
        }

        const { key, canVault } = PaymentMethod.specs;
        if (!key) {
          return null;
        }

        const inputOptions = options[key] || {};

        if (options.vaultOnly && !canVault) {
          // TODO: return this info server-side. This will do for now
          return null;
        }

        const paymentMethodContext = paymentMethodContextFactory.createPaymentMethodContext(
          { paymentMethodType: type },
        );

        const paymentMethod = PaymentMethod.create(
          paymentMethodContext,
          inputOptions,
          paymentMethodConfig,
          styleManager,
        ) as BasePaymentMethod;

        let loaded: boolean;
        try {
          loaded = await paymentMethod.setupAndValidate();
        } catch (e) {
          console.warn(`Can't init ${type}`, e);
          loaded = false;
        }

        if (!loaded) {
          return null;
        }

        if (options.mountImmediately) {
          await paymentMethod.mount();
        }

        return {
          [type]: paymentMethod,
        };
      },
    );

    const results = await Promise.all(promises);

    return Object.assign({}, ...results);
  },
};
