import { Translation } from '../utils/i18n/Translation';
import createPaymentMethodPopupOverlayStore from '../core/PopupService/PaymentMethodPopupOverlayStore';
import { SceneEnum } from '../enums/Checkout';
import {
  UniversalCheckoutOptions,
  SinglePaymentMethodCheckoutOptions,
  VaultManagerOptions,
} from '../types';

import { SceneStage } from './BaseStore';
import CheckoutStore, { ICheckoutState } from './CheckoutStore';
import { Translations } from '../utils/i18n/TranslationFactory';
import { noop } from '../utils/noop';

const initialState: ICheckoutState = {
  sceneStates: {
    [SceneEnum.LOADING]: {
      stage: SceneStage.Entered,
      promise: { resolve: noop, reject: noop },
    },
  },
  sceneAction: undefined,

  isLoading: false,
  isProcessing: false,
  vault: {
    selected: null,
    items: [],
  },

  locale: 'en',
  translation: Translations.getDefault() as Translation &
    Record<string, string>,
  tokens: [],
  currentToken: null,
  apms: {
    selected: null,
    items: [],
  },

  options: {
    purchaseInfo: null,
    customerDetails: null,
    businessDetails: null,
    orderDetails: null,
    orderDetailsInitiator: null,
    showSavePaymentMethod: false,
  },

  clientSession: null,

  paymentMethods: {},

  selectedPaymentMethod: null,

  checkoutModules: [],

  submitButton: {
    isDisabled: true,
    isVisible: false,
    message: '',
  },

  error: {
    message: null,
    hideOnScreenEntered: true,
  },

  smallPrint: {
    message: null,
  },

  form: {
    inputLabelsVisible: true,
  },

  scene: {
    transition: {
      type: 'SLIDE_UP',
      duration: 700,
    },
  },

  card: {
    flow: 'EMBEDDED_IN_HOME',
  },

  paymentMethodPopupOverlay: {
    isVisible: false,
  },

  isTokenizationEnabled: true,
};

///////////////////////////////////////////
// API
///////////////////////////////////////////
export const createStore = (
  options:
    | UniversalCheckoutOptions
    | VaultManagerOptions
    | SinglePaymentMethodCheckoutOptions,
) => {
  const store = new CheckoutStore(initialState, options);

  store.registerStore(
    'paymentMethodPopupOverlay',
    createPaymentMethodPopupOverlayStore(),
  );

  return store;
};
