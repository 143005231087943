interface ValueParser {
  asString(): string;
  asNumber(): number;
}

export function parseAmountValue(value: unknown): ValueParser {
  let normalized: number | null = null;

  switch (typeof value) {
    case 'number':
      normalized = value >= 0 ? value : null;
      break;
    case 'string':
      normalized = /^\d+\.\d{2}$/.test(value)
        ? parseFloat(value.replace('.', ''))
        : null;
      break;
    default:
      normalized = null;
      break;
  }

  if (normalized == null) {
    normalized = 0;
  }

  return {
    asString() {
      return ((normalized as number) / 100).toFixed(2);
    },
    asNumber() {
      return normalized as number;
    },
  };
}
