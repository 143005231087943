import { createContext } from 'preact';
import { useContext } from 'preact/hooks';
import bem from 'easy-bem';
import { ClientSessionActionService } from '../../primer-sdk-web/src/checkout/client-session/ClientSessionActionService';

import { IViewUtils } from '../../primer-sdk-web/src/checkout/ui/types';
import { PrimerCheckout } from '../../primer-sdk-web/src/types';
import { InternalFlowOptions } from '../../primer-sdk-web/src/internalTypes';
import { ClientContext } from '../../primer-sdk-web/src/core/ClientContextFactory';
import { SceneTransition } from '../../primer-sdk-web/src/checkout/SceneTransition';
import VaultPaymentTokenizationService from '../../primer-sdk-web/src/checkout-modules/vault/VaultPaymentTokenizationService';

interface ICheckoutContext {
  options: InternalFlowOptions;
  checkout: PrimerCheckout;
  viewUtils: IViewUtils;
  context?: ClientContext;
  transitions: SceneTransition;
  clientSessionActionService: ClientSessionActionService;
  vaultPaymentTokenizationService?: VaultPaymentTokenizationService;
  className: typeof bem;
}

const CheckoutContext = createContext<ICheckoutContext | null>(null);

export const useCheckoutContext = () => {
  const context = useContext(CheckoutContext) as ICheckoutContext;
  return context;
};

export const { Provider } = CheckoutContext;
