import { noop } from './noop';

export const createPromiseHandler = <T>() => {
  let promiseFunctions: {
    resolve: (data: T) => void;
    reject: (error: any) => void;
  } = {
    resolve: noop,
    reject: noop,
  };

  let resolvedValue: T | undefined;

  const promise = new Promise<T>((resolve, reject) => {
    promiseFunctions = {
      resolve: (arg: T) => {
        resolvedValue = arg;
        resolve(arg);
      },
      reject,
    };
  });

  return { promise, promiseFunctions, getResolvedValue: () => resolvedValue };
};

type CreateCallbackHandlerOptions<Handler> = {
  functionNames: (keyof Handler)[];
};

export const createCallbackHandler = <Handler>({
  functionNames,
}: CreateCallbackHandlerOptions<Handler>): {
  handler: Handler;
  promise: Promise<{
    functionName: keyof Handler;
    args: any[];
  }>;
} => {
  const { promiseFunctions, promise, getResolvedValue } = createPromiseHandler<{
    functionName: keyof Handler;
    args: any[];
  }>();

  // Create a proxy from all function names to "promiseFunctions.resolve"
  // We could technically use a Proxy for this but the debugging experience would be worse
  // With this solution, the developer can "console.log" the handler and see exactly what they can pass.
  // With a proxy, doing "console.log" would return just a Proxy...
  const handler = functionNames.reduce((acc, functionName) => {
    acc[functionName as string] = (...args) => {
      const resolvedValue = getResolvedValue();
      if (resolvedValue) {
        const message = `This completion handler has already been called with \`${resolvedValue.functionName}\`. The call to \`${functionName}\` has been ignored.`;
        console.error(message);
        return;
      }

      promiseFunctions.resolve({
        functionName,
        args,
      });
    };

    return acc;
  }, {});

  return {
    promise,
    handler: handler as Handler,
  };
};
