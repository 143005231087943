import { createCallbackHandler } from '../utils/CallbackHandler';
import CheckoutStore from '../store/CheckoutStore';
import {
  OnResumeSuccess,
  OnTokenizeSuccess,
  PaymentMethodToken,
  ResumeToken,
  SinglePaymentMethodCheckoutOptions,
  ThreeDSVerification,
  TokenizationHandlers,
  UniversalCheckoutOptions,
} from '../types';
import ThreeDSecure from '../three-d-secure/ThreeDSecure';
import { PaymentMethodType } from '../enums/Tokens';
import { ProgressEvent, ThreeDSecureStatus } from '../enums/Tokenization';
import { ClientContext } from '../core/ClientContextFactory';
import {
  createResumeSuccessCallback,
  createTokenizeSuccessCallback,
  IntentHandlers,
  SuccessCallbackErrorHandler,
} from './createSuccessCallback';

import { IViewUtils } from './ui/types';
import { ApiEvent } from '../analytics/constants/enums';
import { SuccessCallbackOptions } from '../core/ProgressNotifier';

import { ClientTokenIntent } from '../core/ClientTokenHandler';
import { ErrorCode, PrimerClientError } from '../errors';
import { Analytics } from '../analytics/Analytics';
import ThreeDSUtils from './ui/ThreeDSUtils';
import { ClientSessionActionService } from './client-session/ClientSessionActionService';

export type InternalTokenizationHandlers = Pick<
  UniversalCheckoutOptions,
  | 'onTokenizeStart'
  | 'onTokenizeError'
  | 'onResumeError'
  | 'onTokenizeShouldStart'
  | 'onTokenizeDidNotStart'
> & {
  onTokenizeSuccess: (data: PaymentMethodToken) => Promise<void>;
  onResumeSuccess: (data: ResumeToken, onError: () => void) => Promise<void>;
  onTokenizeProgress: (evt: { type: string }) => void;
};

const handleThreeDSAnalytics = (
  analytics: Analytics,
  verification: ThreeDSVerification,
) => {
  if (verification.error) {
    analytics.call({ event: ApiEvent.threeDSecureError });
  }

  if (verification.status === ThreeDSecureStatus.FAILED) {
    analytics.call({ event: ApiEvent.threeDSecureFailed });
  }

  if (verification.status === ThreeDSecureStatus.SKIPPED) {
    analytics.call({ event: ApiEvent.threeDSecureSkipped });
  }

  if (verification.status === ThreeDSecureStatus.SUCCESS) {
    analytics.call({ event: ApiEvent.threeDSecureSuccess });
  }
};

const showError = (error: PrimerClientError, store: CheckoutStore) => {
  store.setIsLoading(false);
  store.setIsProcessing(false);

  let errorMessage;
  if (!error.isFromDeveloper) {
    errorMessage = store.getTranslations()?.tokenizationError;
  } else {
    errorMessage = error.message
      ? error.message
      : store.getTranslations()?.tokenizationError;
  }
  store.setErrorMessage(errorMessage);
};

const createOnSuccessWithHandler = (
  onSuccess: OnTokenizeSuccess | OnResumeSuccess | undefined,
) => {
  return async (data) => {
    if (!onSuccess) {
      return true;
    }

    const callbackHandler = createCallbackHandler({
      functionNames: [
        'handleFailure',
        'handleSuccess',
        'continueWithNewClientToken',
      ],
    });

    onSuccess(data, callbackHandler.handler);
    const { functionName, args } = await callbackHandler.promise;

    if (functionName === 'handleFailure') {
      throw new Error(args[0]);
    } else if (functionName === 'handleSuccess') {
      return true;
    } else if (functionName === 'continueWithNewClientToken') {
      return { clientToken: args[0] };
    }

    return true;
  };
};

export const CheckoutTokenizationHandlers = {
  create(
    context: ClientContext,
    viewUtils: IViewUtils,
    options: UniversalCheckoutOptions | SinglePaymentMethodCheckoutOptions,
    clientSessionActionService: ClientSessionActionService,
    tokenizationHandlers: TokenizationHandlers,
  ): InternalTokenizationHandlers {
    let threeDS: ThreeDSecure | undefined;

    const hasCard = !!context.session.paymentMethods.find(
      (paymentMethod) => paymentMethod.type === PaymentMethodType.PAYMENT_CARD,
    );

    if (hasCard) {
      threeDS = ThreeDSUtils.setup(context, viewUtils);
    }

    const onResumeError = async (error: PrimerClientError) => {
      showError(error, viewUtils.store);

      tokenizationHandlers.onResumeError?.(error);
    };

    const onResumeSuccess = async (data: ResumeToken, onError?: () => void) => {
      const onResumeSuccessFail = (error) => {
        showError(error, viewUtils.store);
        onError?.();
      };

      await createResumeSuccessCallback(
        createOnSuccessWithHandler(tokenizationHandlers.onResumeSuccess),
        context,
        viewUtils,
        options,
        clientSessionActionService,
        { handlers: {} },
        onResumeSuccessFail,
      )(data);
    };

    return {
      onTokenizeProgress(ev) {
        switch (ev.type) {
          case ProgressEvent.TOKENIZE_STARTED:
            viewUtils.store.setIsLoading(true);
            viewUtils.store.setIsProcessing(true);
            break;

          case ProgressEvent.TOKENIZE_ERROR:
          case ProgressEvent.TOKENIZE_DID_NOT_START:
            viewUtils.store.setIsLoading(false);
            viewUtils.store.setIsProcessing(false);
            break;

          default:
            break;
        }
      },
      onTokenizeShouldStart: tokenizationHandlers.onTokenizeShouldStart,
      onTokenizeDidNotStart: tokenizationHandlers.onTokenizeDidNotStart,
      onTokenizeStart: () => {
        viewUtils.store.setErrorMessage(null);
        tokenizationHandlers.onTokenizeStart?.();
      },
      onTokenizeError: (error) => {
        const errorMessage = viewUtils.store.getTranslations()
          .tokenizationError;
        if (errorMessage) {
          viewUtils.store.setErrorMessage(errorMessage);
        }
        clientSessionActionService.unselectPaymentMethod();
        context.analytics.call({ event: ApiEvent.tokenizationError });
        tokenizationHandlers.onTokenizeError?.(error);
      },
      async onTokenizeSuccess(
        data: PaymentMethodToken,
        callOptions?: SuccessCallbackOptions,
      ) {
        // Default client token handler

        let clientTokenHandlers: IntentHandlers;
        let resumeError: SuccessCallbackErrorHandler | undefined;

        // Client token handler from callOptions
        if (callOptions?.clientTokenHandler?.clientTokenIntent) {
          const {
            clientTokenIntent,
            handler,
            onError,
          } = callOptions.clientTokenHandler;

          clientTokenHandlers = {
            newClientTokenRequired: true,
            handlers: {
              [clientTokenIntent]: async (decodedClientToken) => {
                try {
                  const returnValue = await handler({
                    decodedClientToken,
                  });

                  if (returnValue?.resumeToken) {
                    const {
                      resumeToken,
                      onError: onResumeErrorCallback,
                    } = returnValue;
                    onResumeSuccess({ resumeToken }, onResumeErrorCallback);
                  }
                } catch (e) {
                  onResumeError(e);
                }
              },
            },
          };

          resumeError = (error: PrimerClientError) => {
            try {
              onError?.();
            } finally {
              showError(error, viewUtils.store);

              if (!error.isFromDeveloper) {
                tokenizationHandlers.onResumeError?.(error);
              }
            }
          };
        } else {
          // 3DS
          clientTokenHandlers = {
            newClientTokenRequired: false,
            handlers: {
              [ClientTokenIntent.THREEDS_AUTHENTICATION]: async (
                decodedClientToken,
              ) => {
                if (
                  decodedClientToken.intent !==
                  ClientTokenIntent.THREEDS_AUTHENTICATION
                )
                  return;

                if (!threeDS) {
                  return;
                }

                viewUtils.store.setIsLoading(true);
                viewUtils.store.setIsProcessing(true);

                try {
                  const verification: ThreeDSVerification = await threeDS.verify(
                    {
                      token: decodedClientToken.tokenId,
                    },
                  );

                  handleThreeDSAnalytics(context.analytics, verification);

                  onResumeSuccess({ resumeToken: verification.resumeToken });
                } catch (e) {
                  onResumeError(
                    PrimerClientError.fromErrorCode(
                      ErrorCode.THREE_DS_AUTH_FAILED,
                      { message: 'Cannot perform threeDS' },
                    ),
                  );
                }
              },
            },
          };
          resumeError = (error) => {
            showError(error, viewUtils.store);

            if (!error.isFromDeveloper) {
              tokenizationHandlers.onResumeError?.(error);
            }
          };
        }

        const onSuccess = createTokenizeSuccessCallback(
          createOnSuccessWithHandler(tokenizationHandlers.onTokenizeSuccess),
          context,
          viewUtils,
          options,
          clientSessionActionService,
          clientTokenHandlers,
          resumeError,
        );

        onSuccess(data, callOptions);

        context.analytics.call({
          event: ApiEvent.completedCheckout,
          data: { paymentMethod: data.paymentInstrumentType },
        });
      },
      onResumeError,
      onResumeSuccess,
    };
  },
};
