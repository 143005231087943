import mixpanel from 'mixpanel-browser';
import { IAnalyticsProvider } from './AnalyticsProvider';
import { AnalyticsAction } from '../models/AnalyticsAction';
import { Environment } from '../../utils/Environment';

const MOCK_TOKEN = Environment.get<string>('MOCK_MIXPANEL_TOKEN');

export class MixpanelProvider implements IAnalyticsProvider {
  constructor(url: string) {
    const token = MOCK_TOKEN ?? 'TOKEN';
    try {
      mixpanel.init(token, { api_host: url }, '');
    } catch (e) {}
  }

  call(action: AnalyticsAction): void {
    try {
      mixpanel?.track(action.event, action);
    } catch (e) {}
  }

  time(action: AnalyticsAction): void {
    try {
      mixpanel?.time_event(action.event);
    } catch (e) {}
  }
}
