import window from '../utils/window';
import ThreeDSecureUIFramework from './ThreeDSecureChallengeUI';
import { errors } from './constants';
import { ThreeDSecureStatus } from '../enums/Tokenization';
import { parseAmountValue } from '../utils/parseAmountValue';
import { noop } from '../utils/noop';
import { hasAPIValidationError } from '../utils/hasAPIValidationError';
import { ErrorCode, PrimerClientError } from '../errors';
import {
  throwAsyncIfTeardown,
  throwIfTeardown,
  createTeardownError,
} from '../checkout/Teardown';

const DEFAULT_INIT_URL =
  'https://songbirdstag.cardinalcommerce.com/cardinalcruise/v1/songbird.js';

export default class CardinalCommerceProvider {
  constructor(services) {
    if (!services.session.threeDSecureToken) {
      this.verify = onConfigurationError;
      onConfigurationError();
    }

    this.context = services;
    this.api = services.api;
    this.scriptLoader = services.scriptLoader;
    this.token = services.session.threeDSecureToken;
    this.initUrl = services.session.threeDSecureInitUrl || DEFAULT_INIT_URL;

    this.setupPromise = undefined;
  }

  throwIfTeardown() {
    return throwIfTeardown(this.context);
  }

  async throwAsyncIfTeardown(fn) {
    return throwAsyncIfTeardown(fn, this.context);
  }

  setup(options) {
    const func = async () => {
      const loaded = await this.scriptLoader.load(this.initUrl, {
        crossorigin: 'anonymous',
      });
      if (!loaded) {
        return onError(
          PrimerClientError.fromErrorCode(ErrorCode.THREE_DS_SETUP_ERROR, {
            message: 'Failed to load 3DS scripts',
          }),
        );
      }

      this.configure({
        container: options.container,
        onChallengeStart: options.onChallengeStart,
        onChallengeEnd: options.onChallengeEnd,
      });

      const sessionId = await this.init();
      return sessionId;
    };

    this.setupPromise = func();
    return this.setupPromise;
  }

  async verify(options) {
    if (!this.setupPromise) {
      this.setup(options);
    }
    const sessionId = await this.throwAsyncIfTeardown(() => this.setupPromise);

    const order = formatOrderData(options, sessionId);

    const authResponse = await this.throwAsyncIfTeardown(() =>
      this.api.post(`/three-d-secure/${options.token}/auth`, order),
    );

    if (
      authResponse.data &&
      authResponse.data.responseCode === ThreeDSecureStatus.CHALLENGE
    ) {
      return this.challenge(options.token, authResponse.data.responseData);
    }

    return this.handleAuthResponse(authResponse);
  }

  async challenge(token, authData) {
    const authToken = await this.throwAsyncIfTeardown(() =>
      this.showChallenge(authData),
    );

    const verifyResponse = await this.throwAsyncIfTeardown(() =>
      this.api.post(`/three-d-secure/${token}/verify`, { authToken }),
    );

    return this.handleAuthResponse(verifyResponse);
  }

  handleAuthResponse(response) {
    if (response.error) {
      return handleAuthResponseError(response.error);
    }

    const { responseCode, token, resumeToken } = response.data;

    return {
      status: responseCode,
      data: token,
      resumeToken,
      error: null,
    };
  }

  showChallenge(authData) {
    const promise = new Promise((resolve) => {
      this.once('payments.validated', (event, authToken) => {
        // Remove the Cardinal IFrame after validation, otherwise it fails to call 3DS twice
        const frame = document.getElementById('Cardinal-CCA-IFrame');
        if (frame) frame.remove();

        resolve(authToken);
      });
    });

    window.Cardinal.continue(
      'cca',
      {
        AcsUrl: authData.acsChallengeUrl,
        Payload: authData.acsChallengeData,
      },
      {
        OrderDetails: {
          TransactionId: authData.transactionId,
        },
      },
    );

    return promise;
  }

  configure({ container, onChallengeStart = noop, onChallengeEnd = noop }) {
    window.Cardinal.configure({
      payment: {
        framework: 'inline',
      },
    });

    window.Cardinal.on('ui.render', () => {
      onChallengeStart();
    });

    window.Cardinal.on('ui.close', () => {
      onChallengeEnd();
    });

    window.Cardinal.on(
      'ui.inline.setup',
      (template, details, resolve, reject) => {
        if (this.context.session.isTeardown) {
          reject(createTeardownError());
          return;
        }

        if (template == null || details == null) {
          reject(new Error('Unexpected arguments'));
          return;
        }

        if (details.paymentType !== 'CCA') {
          reject(
            new Error(`Unexpected Payment Type: "${details.paymentType}"`),
          );
          return;
        }

        this.ui = new ThreeDSecureUIFramework({ container });

        switch (details.data.mode) {
          case 'static':
            this.ui.render(template, details.data.screenSize);
            break;
          case 'suppress':
            this.ui.renderSuppressed(template);
            break;
          default:
            reject(new Error(`Unexpected display mode "${details.data.mode}"`));
            return;
        }

        resolve();
      },
    );
  }

  once(eventName, callback) {
    const { Cardinal } = window;
    Cardinal.on(eventName, (...args) => {
      Cardinal.off(eventName);
      callback(...args);
    });
  }

  init() {
    return new Promise((resolve) => {
      // payments.validated will be called if there is some kind of setup error
      this.once('payments.validated', () => {
        resolve(null);
      });

      this.once('payments.setupComplete', (data) => {
        resolve(data.sessionId);
      });

      window.Cardinal.setup('init', {
        jwt: this.token,
      });
    });
  }
}

function formatOrderData(options, sessionId) {
  const data = {
    referenceId: sessionId,
  };

  if (options.order && options.order.amount) {
    const { order } = options;
    const { value, currency } = order.amount;

    Object.assign(data, {
      transactionType: 'CREDIT',
      amount: parseAmountValue(value).asNumber(),
      currencyCode: currency.toUpperCase(),
      email: order.email,
      billingAddress: order.billingAddress,
      orderId: order.orderId,
    });
  }

  if (options.testScenario) {
    data.testScenario = options.testScenario;
  }

  return data;
}

function onConfigurationError() {
  window.console.error(
    new PrimerClientError(errors.CONFIGURATION.code, {
      message: errors.CONFIGURATION.message,
    }),
  );
}

function onError(error) {
  return {
    status: ThreeDSecureStatus.SKIPPED,
    error,
    data: null,
  };
}

/**
 *
 * @param {import('../core/Api').APIErrorShape} error
 */
function handleAuthResponseError(error) {
  let message;

  if (hasAPIValidationError(error, 'testScenario')) {
    message =
      'Unknown `testScenario property. Check the docs at https://primer.io/docs/guides/testing/#testing-3d-secure for more information`';
    console.error(message);
  } else {
    message = error.message;
  }

  return onError(
    PrimerClientError.fromErrorCode(ErrorCode.THREE_DS_NEGOTIATION_ERROR, {
      message,
      diagnosticsId: error.diagnosticsId,
      errorId: error.errorId,
    }),
  );
}
