import { Environment } from './utils/Environment';
import { ClientContextFactory } from './core/ClientContextFactory';
import {
  UniversalCheckoutOptions,
  VaultManagerOptions,
  SinglePaymentMethodCheckoutOptions,
  CheckoutUXFlow,
} from './types';
import { initGlobalErrorMonitoring } from './monitoring/ErrorMonitoring';
import FlowFactory from './checkout/flows/Factory';
import { ApiEvent } from './analytics/constants/enums';
import { InternalFlowOptions } from './internalTypes';

const version = Environment.get('PRIMER_SDK_VERSION');

const createGetContext = (
  clientToken: string,
  options: InternalFlowOptions,
) => () =>
  ClientContextFactory.create({
    options: { clientToken },
    renderOptions: options,
  });

const startFlow = async (clientToken: string, options: InternalFlowOptions) => {
  initGlobalErrorMonitoring();

  const getContext = createGetContext(clientToken, options);
  const checkout = await FlowFactory.create(getContext, options);
  const context = await getContext();

  // End of loading
  // (start of loading is done by the context :/)
  context.analytics.call({
    event: ApiEvent.loadedCheckoutUi,
    data: {
      uxFlow: options.uxFlow,
    },
  });

  return checkout;
};

// This class can't be called "PrimerClient" due to limitation of the loader + dts-bundle
const Client = {
  SDK_VERSION: version,

  async showUniversalCheckout(
    clientToken: string,
    options: UniversalCheckoutOptions | SinglePaymentMethodCheckoutOptions,
  ) {
    // TODO: validate type

    return startFlow(clientToken, options);
  },

  async showVaultManager(clientToken: string, options: VaultManagerOptions) {
    // TODO: validate type

    return startFlow(clientToken, {
      ...options,
      uxFlow: CheckoutUXFlow.MANAGE_PAYMENT_METHODS,
    });
  },
};

export default Client;
