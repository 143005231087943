import window from '../../utils/window';
import { BrowserInfo } from './types';
import { getTimezoneOffset } from './utils';

export const Fingerprint = {
  create(): BrowserInfo {
    return {
      javaEnabled: window.navigator.javaEnabled(),
      language: window.navigator.language,
      colorDepth: window.screen.colorDepth,
      screenHeight: window.screen.height,
      screenWidth: window.screen.width,
      timezoneOffset: getTimezoneOffset(),
      userAgent: window.navigator.userAgent,
    };
  },
};
