import {
  ThreeDSAuthPayload,
  ThreeDSChallengeAuthentication,
  ThreeDSDeclinedAuthentication,
  ThreeDSSkippedAuthentication,
  ThreeDSSuccessAuthentication,
  WindowSize,
} from '../types';
import { locateElement } from '../../utils/dom';
import {
  createChallengeIFrameFormV1,
  createChallengeIFrameFormV2,
} from './utils';
import window from '../../utils/window';
import { ChallengeOptions } from './types';
import { LongPoll } from '../../core/LongPoll';

const CHALLENGE_WINDOW_ID = 'primer-3ds-challenge-window';

type ChallengeResult =
  | ThreeDSSuccessAuthentication
  | ThreeDSDeclinedAuthentication
  | ThreeDSSkippedAuthentication;

type ChallengePollResult = ChallengeResult | ThreeDSChallengeAuthentication;

const ONE_SECOND = 1000;

/**
 * The ACS gives the customer up to 10 mins to complete the challenge
 * In reality, ACSs tend to misbehave so we're giving them some leway
 */

// poll every 2.5 seconds
const POLL_INTERVAL = Math.floor(2.5 * ONE_SECOND);

// Up to 11 minutes
const CHALLENGE_TIMEOUT = 11 * 60 * ONE_SECOND;

export const ThreeDSChallenge = {
  async exec(
    longPoll: LongPoll,
    options: ChallengeOptions,
  ): Promise<ThreeDSAuthPayload | null> {
    const size = parseWindowSize(options.windowSize);

    const container = locateElement(options.container) ?? window.document.body;

    const isV1 = /^1/.test(options.protocolVersion);
    const { form, iframe } = isV1
      ? createChallengeIFrameFormV1(options)
      : createChallengeIFrameFormV2(options);

    container.appendChild(form);
    container.appendChild(iframe);

    options.onChallengeStart(size);

    form.submit();

    const longPollOptions = {
      url: options.statusUrl,
      timeout: CHALLENGE_TIMEOUT,
      pollInterval: POLL_INTERVAL,
      predicate(res: ThreeDSAuthPayload) {
        return res.authentication.responseCode !== 'CHALLENGE';
      },
    };

    const response = await longPoll.start<
      ThreeDSAuthPayload<ChallengePollResult>
    >(longPollOptions);

    options.onChallengeEnd();

    this.cleanup();

    if (response == null) {
      return null;
    }

    const authentication = response.authentication as ChallengeResult;

    return { ...response, authentication };
  },

  cleanup() {
    window.document.getElementById(CHALLENGE_WINDOW_ID)?.remove();
  },
};

function parseWindowSize(size: string): WindowSize {
  if (!/^\d+x\d+$/.test(size)) {
    return {
      fullscreen: true,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const [width, height] = size.split('x');

  return {
    fullscreen: false,
    width: parseFloat(width),
    height: parseFloat(height),
  };
}
