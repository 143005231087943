import EventTarget from '@ungap/event-target';

import { parseAmountValue } from '../utils/parseAmountValue';
import {
  SceneStage,
  SceneState,
  BaseStore,
  NodeLike,
  IBaseState,
  IBaseStore,
} from './BaseStore';

import {
  BusinessDetails,
  CustomerDetails,
  PaymentMethodToken,
  CardPreferredFlow,
  PurchaseInformation,
  SupportedLocale,
  VaultListItem,
  UniversalCheckoutOptions,
  VaultManagerOptions,
  SinglePaymentMethodCheckoutOptions,
  SceneTransitionOptions,
  VaultInformation,
} from '../types';
import { formatVaultedToken } from '../checkout-modules/vault/utils';
import { BasePaymentMethod } from '../payment-methods/BasePaymentMethod';
import { Vault, VaultedPaymentMethod } from '../checkout-modules/vault/Vault';
import { PaymentMethodType } from '../enums/Tokens';
import { IOptionStoreSetter, IOptionStoreListener } from './OptionStore';
import { SceneEnum } from '../enums/Checkout';
import { Translation, TranslationUnit } from '../utils/i18n/Translation';
import { IClientSessionStore } from './ClientSessionStore';
import { CreditCardStore } from '../payment-methods/credit-card/CreditCardStore';
import {
  InternalClientSession,
  ClientSessionOrder,
  ClientSession,
} from '../models/ClientSession';

export interface ICheckoutState extends IBaseState {
  isLoading: boolean;
  isProcessing: boolean;
  //
  locale: SupportedLocale;
  tokens: VaultedPaymentMethod[];
  translation: TranslationUnit;

  currentToken: PaymentMethodToken | null;

  scene: {
    onEntering?: (sceneId: string) => void;
    transition: SceneTransitionOptions | false;
  };

  apms: {
    selected: Nullable<string>;
    items: NodeLike[];
  };
  vault: {
    selected: Nullable<string>;
    items: VaultListItem[];
  };
  options: {
    purchaseInfo: PurchaseInformation | VaultInformation | null;
    customerDetails: CustomerDetails | null;
    businessDetails: BusinessDetails | null;
    orderDetails: ClientSessionOrder | null;
    orderDetailsInitiator: string | null;
    showSavePaymentMethod: boolean;
  };

  isTokenizationEnabled: boolean;

  clientSession: InternalClientSession | null;

  ///////////////////////////////////////////
  // Current scene
  ///////////////////////////////////////////
  sceneStates: Partial<Record<SceneEnum, SceneState>>;
  sceneAction?: string;

  ///////////////////////////////////////////
  // Vaulted payment methods
  ///////////////////////////////////////////
  selectedVaultedPaymentMethod?: string;

  ///////////////////////////////////////////
  // Error
  ///////////////////////////////////////////
  error: {
    message: string | null;
    hideOnScreenEntered: boolean;
  };

  ///////////////////////////////////////////
  // SmallPrint
  ///////////////////////////////////////////

  smallPrint: {
    message: string | null;
  };

  ///////////////////////////////////////////
  // Payment Methods
  ///////////////////////////////////////////
  paymentMethods: Record<string, BasePaymentMethod>;

  ///////////////////////////////////////////
  // Checkout Modules
  ///////////////////////////////////////////
  checkoutModules: any[];

  ///////////////////////////////////////////
  // Submit Button
  ///////////////////////////////////////////
  submitButton: {
    isDisabled: boolean;
    isVisible: boolean;
    message: string;
  };

  ///////////////////////////////////////////
  // Card Form
  ///////////////////////////////////////////
  form: {
    inputLabelsVisible: boolean;
  };

  ///////////////////////////////////////////
  // Cards
  ///////////////////////////////////////////
  card: {
    flow: CardPreferredFlow;
  };
}

export const getCurrentScene = (store: BaseStore<ICheckoutState>): string => {
  const { sceneStates } = store.getState();
  const currentScene = Object.entries(sceneStates).find(
    ([, sceneState]) =>
      sceneState?.stage === 'entering' || sceneState?.stage === 'entered',
  )?.[0];
  return currentScene ?? SceneEnum.LOADING;
};

export default class CheckoutStore
  extends BaseStore<ICheckoutState>
  implements IOptionStoreSetter, IOptionStoreListener, IClientSessionStore {
  private submitButtonEventListener: EventTarget;

  private vault: Vault | null;

  private options:
    | UniversalCheckoutOptions
    | VaultManagerOptions
    | SinglePaymentMethodCheckoutOptions;

  constructor(
    defaultState: ICheckoutState,
    options:
      | UniversalCheckoutOptions
      | VaultManagerOptions
      | SinglePaymentMethodCheckoutOptions,
  ) {
    super(defaultState);

    this.submitButtonEventListener = new EventTarget();
    this.vault = null;

    this.options = options;

    this.registerEventHandlers<CheckoutStore>({
      'currentSceneId': (store) => getCurrentScene(store),
      'isTokenizationEnabled': (store) =>
        store.getState().isTokenizationEnabled,

      'options.purchaseInfo': (store) => store.getState().options.orderDetails,
      'options.customerDetails': (store) =>
        store.getState().options.customerDetails,
      'options.businessDetails': (store) =>
        store.getState().options.businessDetails,
      'options.orderDetails': (store) => store.getState().options.orderDetails,
      'isLoading': (store) => store.getState().isLoading,
      'submitButton.isVisible': (store) => ({
        isVisible: store.getState().submitButton.isVisible,
        currentSceneId: getCurrentScene(store),
      }),
      'submitButton.isDisabled': (store) => ({
        isDisabled: store.getState().submitButton.isDisabled,
        isLoading: store.getState().isLoading,
        currentSceneId: getCurrentScene(store),
      }),
      'submitButton.message': (store) => ({
        message: store.getState().submitButton.message,
        currentSceneId: getCurrentScene(store),
      }),
    });
  }

  setVault(vault: Vault) {
    this.vault = vault;
  }

  setIsLoading(isLoading: boolean): void {
    this.produceState((draft) => {
      draft.isLoading = isLoading;
    });
  }

  setIsProcessing(isProcessing: boolean): void {
    this.produceState((draft) => {
      draft.isProcessing = isProcessing;
    });
  }

  setLocale(locale: SupportedLocale): void {
    this.produceState((draft) => {
      draft.locale = locale;
    });
  }

  getLocale(): SupportedLocale {
    return this.getState().locale ?? 'en-GB';
  }

  setIsTokenizationEnabled(isEnabled: boolean): void {
    this.produceState((draft) => {
      draft.isTokenizationEnabled = isEnabled;
    });
  }

  getIsTokenizationEnabled(): boolean {
    return this.getState().isTokenizationEnabled;
  }

  ///////////////////////////////////////////
  // Scene
  ///////////////////////////////////////////

  setScene(scene: string, action = 'push'): Promise<void> {
    return new Promise((resolve, reject) => {
      this.produceState((draft) => {
        draft.sceneAction = action;

        let sceneState = draft.sceneStates[scene];

        // If not first time scene appears:
        // Create scene state, with state .Init
        if (!sceneState) {
          sceneState = { stage: SceneStage.Init, promise: { resolve, reject } };
          draft.sceneStates[scene] = sceneState;
        }

        if (sceneState.stage === SceneStage.Init) {
          // If scene is init, it needs to be mounted first
          sceneState.stage = SceneStage.Mounting;
        } else if (sceneState.stage === SceneStage.Exited) {
          // If scene has exited, it can be showed dirrectly
          sceneState.stage = SceneStage.Entering;

          // Find the current scene and set it to exiting
          const currentScene = Object.entries(draft.sceneStates).find(
            ([, state]) => state?.stage === SceneStage.Entered,
          )?.[1];

          if (currentScene) {
            currentScene.stage = SceneStage.Exiting;
          }
        }
      });
    });
  }

  setSceneTransition(
    transitionOptions: SceneTransitionOptions | false | undefined,
  ): void {
    this.produceState((draft) => {
      if (transitionOptions !== undefined) {
        draft.scene.transition = transitionOptions;
      }
    });
  }

  handleSceneEntering(scene: string): void {
    const sceneOptions = (this.options as
      | UniversalCheckoutOptions
      | VaultManagerOptions
      | SinglePaymentMethodCheckoutOptions).scene;
    if (sceneOptions && sceneOptions.onEntering) {
      sceneOptions.onEntering(scene);
    }
  }

  handleSceneEntered(scene: string): void {
    if (this.getState().error.hideOnScreenEntered) {
      this.setErrorMessage(null);
    }

    this.produceState((draft) => {
      const sceneState = draft.sceneStates[scene] as SceneState;
      sceneState.stage = SceneStage.Entered;
    });

    const sceneState = this.getState().sceneStates[scene] as SceneState;
    sceneState.promise.resolve();
  }

  handleSceneExited(scene: string): void {
    this.produceState((draft) => {
      const sceneState = draft.sceneStates[scene] as SceneState;
      sceneState.stage = SceneStage.Exited;
    });
  }

  handleSceneMounted(scene: string): void {
    this.produceState((draft) => {
      const currentScene = Object.entries(draft.sceneStates).find(
        ([, sceneState]) => sceneState?.stage === SceneStage.Entered,
      )?.[1];

      const sceneState = draft.sceneStates[scene] as SceneState;
      sceneState.stage = SceneStage.Entering;

      if (currentScene) {
        currentScene.stage = SceneStage.Exiting;
      }
    });
  }

  getCurrentScene(): string {
    return getCurrentScene(this);
  }

  ///////////////////////////////////////////
  // Token
  ///////////////////////////////////////////

  setCurrentToken(token: PaymentMethodToken | null) {
    this.produceState((draft) => {
      draft.currentToken = token;
    });
  }

  ///////////////////////////////////////////
  // Submit Button
  ///////////////////////////////////////////

  triggerSubmitButtonClick() {
    if (this.getState().isLoading || this.getState().submitButton.isDisabled) {
      return;
    }

    this.submitButtonEventListener.dispatchEvent(new Event('click'));
    this.setErrorMessage(null);
  }

  addSubmitButtonClickListener(
    listener: EventListener | EventListenerObject | null,
  ) {
    this.submitButtonEventListener.addEventListener('click', listener);
  }

  removeSubmitButtonClickListener(
    listener: EventListener | EventListenerObject | null,
  ) {
    this.submitButtonEventListener.removeEventListener('click', listener);
  }

  setSubmitButtonDisabled(disabled: boolean): void {
    this.produceState((draft) => {
      draft.submitButton.isDisabled = disabled;
    });
  }

  setSubmitButtonVisible(isVisible: boolean): void {
    this.produceState((draft) => {
      draft.submitButton.isVisible = isVisible;
    });
  }

  setSubmitButtonContent(content: string): void {
    this.produceState((draft) => {
      draft.submitButton.message = content;
    });
  }

  ///////////////////////////////////////////
  // Prefered Card Flow
  ///////////////////////////////////////////

  setCardFlow(flow: CardPreferredFlow): void {
    this.produceState((draft) => {
      draft.card.flow = flow;
    });
  }

  ///////////////////////////////////////////
  // Input Labels
  ///////////////////////////////////////////

  setInputLabelsVisible(visible: boolean): void {
    this.produceState((draft) => {
      draft.form.inputLabelsVisible = visible;
    });
  }

  ///////////////////////////////////////////
  // Error Message
  ///////////////////////////////////////////

  setErrorMessage(content: string | null): void {
    this.produceState((draft) => {
      draft.error.message = content;
    });
  }

  setHideErrorMessageOnSceneEntered(hideOnSceneEntered: boolean) {
    this.produceState((draft) => {
      draft.error.hideOnScreenEntered = hideOnSceneEntered;
    });
  }

  ///////////////////////////////////////////
  // Payment Methods
  ///////////////////////////////////////////

  addAPM(apm: NodeLike): void {
    this.produceState((draft) => {
      draft.apms.items.push(apm);
    });
  }

  setPaymentMethods(paymentMethods: Record<string, BasePaymentMethod>) {
    this.setState({ paymentMethods });

    // Register substores
    Object.values(paymentMethods).forEach((paymentMethod) => {
      const paymentMethodStore = paymentMethod.getStore?.();
      if (!paymentMethodStore) {
        return;
      }

      this.registerStore(paymentMethod.type, paymentMethodStore);
    });

    const apms = Object.keys(paymentMethods)
      .filter((name) => !/^(?:card|directDebit)$/.test(name))
      .map((name) => ({ id: `primer-checkout-apm-${name}` }))
      .filter(Boolean);

    apms.forEach((apm) => {
      this.addAPM(apm);
    });
  }

  setSelectedPaymentMethod(
    paymentMethodType: PaymentMethodType | string | null,
  ) {
    this.produceState((draft) => {
      draft.selectedPaymentMethod = paymentMethodType;
    });
  }

  ///////////////////////////////////////////
  // Checkout Modules
  ///////////////////////////////////////////

  setCheckoutModules(checkoutModules: any) {
    this.produceState((draft) => {
      draft.checkoutModules = checkoutModules;
    });
  }

  getCheckoutModules() {
    return this.getState().checkoutModules;
  }

  getCheckoutModuleWithType<CheckoutModule>(
    type: string,
  ): CheckoutModule | undefined {
    return this.getState().checkoutModules.find(
      (checkoutModule) => checkoutModule.type === type,
    ) as CheckoutModule;
  }

  ///////////////////////////////////////////
  // Vault
  ///////////////////////////////////////////
  selectFirstVault(): void {
    if (this.hasVault) {
      this.produceState((draft) => {
        draft.vault.selected = draft.vault.items[0].id;
      });
    }
  }

  addVault(vault: VaultedPaymentMethod): Nullable<VaultListItem> {
    const formatted = formatVaultedToken(vault);

    if (formatted == null) {
      return null;
    }

    this.produceState((draft) => {
      draft.isLoading = true;
      draft.tokens.push(vault);
      draft.vault.items.push(formatted);
    });

    return formatted;
  }

  selectVault(id: Nullable<string>): void {
    this.produceState((draft) => {
      draft.vault.selected = id;
    });
  }

  getSelectedVaultItem(): VaultListItem | undefined {
    const vaultItems = this.getState().vault.items;
    const selectedVaultItemId = this.getState().vault.selected;
    return vaultItems.find((vaultItem) => vaultItem.id === selectedVaultItemId);
  }

  async removeVault(id: string): Promise<void> {
    await this.vault?.delete(id);

    this.produceState((draft) => {
      if (draft.vault.selected === id) {
        draft.vault.selected = null;
      }

      draft.vault.items = draft.vault.items.filter((elm) => elm.id !== id);
      draft.tokens = draft.tokens.filter((elm) => elm.id !== id);
    });
  }

  ///////////////////////////////////////////
  // Small Print
  ///////////////////////////////////////////

  setSmallPrintMessage(message: Nullable<string>): void {
    this.produceState((draft) => {
      draft.smallPrint.message = message;
    });
  }

  ///////////////////////////////////////////
  // Localisation
  ///////////////////////////////////////////

  setTranslation(translation: TranslationUnit) {
    this.produceState((draft) => {
      draft.translation = translation;
    });
  }

  ///////////////////////////////////////////
  // Options
  ///////////////////////////////////////////

  setShowSavedPaymentMethods(show: boolean) {
    this.produceState((draft) => {
      draft.options.showSavePaymentMethod = show;
    });
  }

  setPurchaseInfo(purchaseInfo: Nullable<PurchaseInformation>) {
    if (!purchaseInfo?.totalAmount) {
      return;
    }
    const { orderDetails } = this.getOrderDetails();
    const updatedOrderDetails = {
      ...orderDetails,
      lineItems: undefined,
      totalOrderAmount:
        parseAmountValue(purchaseInfo.totalAmount.value).asNumber() ?? 0,
      merchantAmount:
        parseAmountValue(purchaseInfo.totalAmount.value).asNumber() ?? 0,
      currencyCode: purchaseInfo.totalAmount.currency,
    };

    this.setOrderDetails(updatedOrderDetails, 'CLIENT');
  }

  setCustomerDetails(
    customerDetails: Nullable<CustomerDetails>,
    orderDetailsInitiator: string | null,
  ) {
    if (this.hasClientSession) {
      console.warn(
        'Please request a new client token with updated customer details',
      );
      return;
    }
    this.produceState((draft) => {
      draft.options.customerDetails = customerDetails;
      draft.options.orderDetailsInitiator = orderDetailsInitiator;
    });
  }

  setBusinessDetails(
    businessDetails: Nullable<BusinessDetails>,
    orderDetailsInitiator: string | null,
  ) {
    if (this.hasClientSession) {
      console.warn('Please request a new client token to update information');
      return;
    }
    this.produceState((draft) => {
      draft.options.businessDetails = businessDetails;
      draft.options.orderDetailsInitiator = orderDetailsInitiator;
    });
  }

  setOrderDetails(
    orderDetails: ClientSessionOrder | null,
    orderDetailsInitiator: string | null,
  ) {
    this.produceState((draft) => {
      draft.options.orderDetails = orderDetails;
      draft.options.orderDetailsInitiator = orderDetailsInitiator;
    });
  }

  // Can have multiple setters for each client session property.
  // This will do for now.
  setClientSession(clientSession: InternalClientSession) {
    this.produceState((draft) => {
      draft.clientSession = clientSession;
      draft.options.orderDetails = clientSession.order;
      draft.options.customerDetails = clientSession.customer;
    });
  }

  // TODO(rs): remove all getters once checkout fully reverted to using clientSession
  getPurchaseInfo() {
    return this.getState().options.purchaseInfo;
  }

  getCustomerDetails() {
    return this.getState().options.customerDetails;
  }

  getBusinessDetails() {
    return this.getState().options.businessDetails;
  }

  getOrderDetails() {
    return {
      orderDetails: this.getState().options.orderDetails,
      orderDetailsInitiator: this.getState().options.orderDetailsInitiator,
    };
  }

  getClientSession(): InternalClientSession | null {
    return this.getState().clientSession;
  }

  ///////////////////////////////////////////
  // Getters
  ///////////////////////////////////////////

  get hasAPMs(): boolean {
    return this.getState().apms.items.length - (this.hasCard ? 1 : 0) > 0;
  }

  get selectedAPM(): Nullable<string> {
    return this.getState().apms.selected;
  }

  get hasVault(): boolean {
    return this.getState().vault.items.length > 0;
  }

  get hasCard(): boolean {
    return (
      Object.values(this.getState().paymentMethods).findIndex(
        ({ type }) => type === PaymentMethodType.PAYMENT_CARD,
      ) !== -1
    );
  }

  get hasCreditCardScene(): boolean {
    return this.getState().card.flow === 'DEDICATED_SCENE';
  }

  get hasDirectDebit(): boolean {
    return (
      Object.values(this.getState().paymentMethods).findIndex(
        ({ type }) => type === PaymentMethodType.GO_CARDLESS,
      ) !== -1
    );
  }

  get hasSurcharge(): boolean {
    const paymentMethodOptions = this.getState().clientSession?.paymentMethod
      .options;
    return !!paymentMethodOptions?.find(
      (item) => item.surcharge && item.surcharge !== 0,
    );
  }

  get hasCardSurcharge(): boolean {
    const paymentMethodOptions = this.getState().clientSession?.paymentMethod
      ?.options;
    const paymentCardOptions = paymentMethodOptions?.find((item) => {
      return item.type === 'PAYMENT_CARD';
    })?.networks;
    if (!paymentCardOptions || Object.keys(paymentCardOptions).length === 0) {
      return false;
    }
    return !!paymentCardOptions?.find((cardNetworkOption) => {
      return cardNetworkOption.surcharge && cardNetworkOption.surcharge !== 0;
    });
  }

  get selectedVault(): Nullable<string> {
    return this.getState().vault.selected;
  }

  get savePaymentMethodVisible(): boolean {
    return this.getState().options.showSavePaymentMethod;
  }

  get hasClientSession(): boolean {
    const { clientSession } = this.getState();
    if (clientSession) {
      return Object.keys(clientSession).length > 0;
    }
    return false;
  }

  get hasSelectedPaymentMethod(): PaymentMethodType | string | null {
    return this.getState().selectedPaymentMethod;
  }

  get currentCardNetwork(): string | null {
    return (
      this.getPaymentMethodStoreWithType<CreditCardStore>(
        PaymentMethodType.PAYMENT_CARD,
      )?.currentCardNetwork ?? null
    );
  }

  getSelectedVaultToken(): Nullable<VaultedPaymentMethod> {
    return (
      this.getState().tokens.find(
        (elm) => elm.id === this.getState().vault.selected,
      ) || null
    );
  }

  getAllAPMs(): NodeLike[] {
    return this.getState().apms.items;
  }

  getAllVaulted(): VaultListItem[] {
    return this.getState().vault.items;
  }

  getTranslations(): Translation & Record<string, string> {
    return this.getState().translation;
  }

  getPaymentMethodWithType<T extends BasePaymentMethod>(
    paymentMethodType: string,
  ): T | undefined {
    const paymentMethod = Object.values(this.getState().paymentMethods).find(
      ({ type }) => type === paymentMethodType,
    );
    if (!paymentMethod) {
      return undefined;
    }

    return paymentMethod as T;
  }

  getPaymentMethodStoreWithType<T extends IBaseStore>(
    paymentMethodType: string,
  ): T | undefined {
    const paymentMethod = this.getPaymentMethodWithType(paymentMethodType);
    return paymentMethod?.getStore() as T;
  }

  getPaymentMethods() {
    return this.getState().paymentMethods;
  }

  hasVaultedToken(token: PaymentMethodToken): boolean {
    return !!this.getState().tokens.find(
      (elm) => elm.analyticsId === token.analyticsId,
    );
  }
}
