import { FunctionalComponent, h } from 'preact';
import styled from 'styled-components';
import { createWithStyle } from '../utils';
import InputContainer from '../InputContainer/InputContainer';

const withInputStyle = createWithStyle((style) => style?.input);

const HostedInputContainer = styled.div`
  flex: 1;
`;

const StyledInputContainer = styled(InputContainer)`
  ${(p) =>
    p.hasPaddingLeft &&
    withInputStyle((style) => ({
      paddingLeft: style?.base?.paddingHorizontal,
    }))}

  ${(p) =>
    p.hasPaddingRight &&
    withInputStyle((style) => ({
      paddingRight: style?.base?.paddingHorizontal,
    }))}

 
    & ${HostedInputContainer} {
    ${(p) =>
      // A bit hacky: we can't disable the paddingHorizontal of the hosted input
      // but we just need 10px between the icon and the input field
      p.hasPaddingLeft &&
      withInputStyle((style) => ({
        marginLeft: -(style?.base?.paddingHorizontal ?? 0) + 10,
      }))}

    ${(p) =>
      // A bit hacky: we can't disable the paddingHorizontal of the hosted input
      // but we just need 10px between the icon and the input field
      p.hasPaddingRight &&
      withInputStyle((style) => ({
        marginRight: -(style?.base?.paddingHorizontal ?? 0) + 10,
      }))}
  }
`;

const Container = styled.div`
  display: flex;
  height: 100%;
`;

type Props = {
  inputId?: string;
  focused?: boolean;
  hasErrors?: boolean;
  beforeInput?: any;
  afterInput?: any;
};

const HostedInput: FunctionalComponent<Props> = ({
  inputId,
  focused = false,
  hasErrors = false,
  beforeInput,
  afterInput,
}) => {
  return (
    <StyledInputContainer
      focused={focused}
      hasErrors={hasErrors}
      hasPaddingLeft={!!beforeInput}
      hasPaddingRight={!!afterInput}
    >
      <Container>
        {beforeInput}
        <HostedInputContainer
          className='PrimerCheckout__formInput PrimerCheckout__formInput--hosted'
          id={inputId}
          data-testId='HostedInput/Field'
        ></HostedInputContainer>
        {afterInput}
      </Container>
    </StyledInputContainer>
  );
};

export default HostedInput;
