import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { useCheckoutStore } from '@primer-io/shared-library/contexts';
import {
  Input,
  InputGroup,
  ErrorLabel,
  SelectManager,
} from '@primer-io/shared-library/components';
import { IInputGroup } from '../types';
import getErrorMessages from '../utils/errors/getErrorMessages';
import { useFormRendererContext } from '../contexts/FormRendererContext';

interface Props {
  inputGroups: IInputGroup[];
  onInputChange: (name: string, value: any) => void;
  onInputFocus: (name?: string) => void;
  onInputBlur: (name?: string) => void;
}

const Root = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }

  & > *:nth-last-child(2) {
    margin-bottom: 0px;
  }
`;

const FormInputGroups: FunctionalComponent<Props> = ({
  inputGroups,
  onInputChange,
  onInputFocus,
  onInputBlur,
}) => {
  const store = useCheckoutStore();
  const labels = store.getTranslations();

  const { disabled } = useFormRendererContext();

  const errorMessages = getErrorMessages(inputGroups);

  const error = errorMessages?.find(
    (message: { errorMessage: string; visible: boolean }) => message?.visible,
  );

  return (
    <Root data-testId='FormInputGroups'>
      {inputGroups?.map((inputGroup) => (
        <InputGroup
          key={inputGroup.id}
          data-testId={`FormInputGroups/InputGroup/${inputGroup.id}`}
          horizontal={inputGroup.orientation === 'horizontal'}
          hasHalfWidthInput={
            !!inputGroup.inputs.find((input) => input.halfWidth)
          }
        >
          {inputGroup.inputs?.map(
            ({
              placeholderKey,
              name,
              id,
              type,
              errorMessages: inputErrorMessages,
              errorVisible,
              maxLength,
              minLength,
              ...other
            }) => {
              if (type.includes('Select')) {
                return (
                  <SelectManager
                    type={type}
                    onChange={(inputValue) => onInputChange(name, inputValue)}
                    hasErrors={inputErrorMessages?.length > 0 && errorVisible}
                    inputId={id}
                    disabled={disabled}
                  />
                );
              }
              return (
                <Input
                  key={id}
                  data-testId={`FormInputGroups/InputGroup/${
                    inputGroup.id ?? id
                  }/Input/${id}`}
                  {...other}
                  inputId={id}
                  type={type}
                  maxLength={maxLength}
                  minLength={minLength}
                  disabled={disabled}
                  hasErrors={inputErrorMessages?.length > 0 && errorVisible}
                  placeholder={labels?.[placeholderKey]}
                  onChange={(inputValue) => onInputChange(name, inputValue)}
                  onFocus={() => onInputFocus(name)}
                  onBlur={() => onInputBlur(name)}
                />
              );
            },
          )}
        </InputGroup>
      ))}
      <ErrorLabel message={error?.errorMessage} />
    </Root>
  );
};

export default FormInputGroups;
