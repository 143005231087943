import { getAttribute, massToggle } from './DomUtilities';
import {
  UniversalCheckoutOptions,
  FormState,
  SinglePaymentMethodCheckoutOptions,
  PaymentMethodType,
} from '../types';
import { ClassName, ElementID } from '../enums/Checkout';
import { IViewUtils } from './ui/types';
import CheckoutStore from '../store/CheckoutStore';
import toCamelCase from '../utils/toCamelCase';
import { ClientSessionActionService } from './client-session/ClientSessionActionService';
import { cardTypeChangeHandler } from './cardTypeChangeHandler';

export const CheckoutPaymentMethodConfig = {
  create(
    store: CheckoutStore,
    viewUtils: IViewUtils,
    options: UniversalCheckoutOptions | SinglePaymentMethodCheckoutOptions,
    clientSessionActionService: ClientSessionActionService,
  ): Record<string, unknown> {
    const {
      allowedCardNetworks,
      locale,
      paypal = {},
      applePay = {},
      googlePay = {},
      klarna = {},
      giftCard = {},
    } = options;

    const { paymentMethod } = options as SinglePaymentMethodCheckoutOptions;
    const { allowedPaymentMethods } = options as UniversalCheckoutOptions;

    const config = {
      paymentMethod,
      allowedPaymentMethods,
      allowedCardNetworks,
      locale,
      mountImmediately: false,
      logWarnings: false,
      clientSessionActionService,
      paypal: {
        container: `#primer-checkout-apm-${toCamelCase(
          PaymentMethodType.PAYPAL,
        )}`,
        buttonColor: 'gold',
        ...paypal,
      },
      googlePay: {
        container: `#primer-checkout-apm-${toCamelCase(
          PaymentMethodType.GOOGLE_PAY,
        )}`,
        buttonColor: 'black',
        ...googlePay,
      },
      applePay: {
        container: `#primer-checkout-apm-${toCamelCase(
          PaymentMethodType.APPLE_PAY,
        )}`,
        buttonStyle: 'white-outline',
        ...applePay,
      },
      klarna,
      giftCard,
    };

    // Gocardless
    Object.assign(config, {
      directDebit: {
        customerCountryCode: options.directDebit?.customerCountryCode,
        customerName: inputValueGetter(ElementID.DD_CUSTOMER_NAME_INPUT),
        customerEmail: inputValueGetter(ElementID.DD_CUSTOMER_EMAIL_INPUT),
        customerAddressLine1: inputValueGetter(
          ElementID.DD_CUSTOMER_ADDRESS_LINE1,
        ),
        customerAddressLine2: inputValueGetter(
          ElementID.DD_CUSTOMER_ADDRESS_LINE2,
        ),
        customerCity: inputValueGetter(ElementID.DD_CUSTOMER_ADDRESS_CITY),
        customerPostalCode: inputValueGetter(
          ElementID.DD_CUSTOMER_ADDRESS_POSTAL_CODE,
        ),
        iban: inputValueGetter(ElementID.DD_IBAN_INPUT),
      },
    });

    // Card
    Object.assign(config, {
      card: {
        vault: false,
        cardholderName: () =>
          getAttribute(ElementID.CARDHOLDER_NAME_INPUT, 'value'),
        onCardMetadata: cardTypeChangeHandler(clientSessionActionService),

        css: viewUtils.styleManager.getHostedFieldStyle(),
        stylesheets: options?.style?.stylesheets,
        allowedCardNetworks: options.allowedCardNetworks,
        onChange(formState: FormState) {
          if (formState.dirty) {
            if (store.getSelectedVaultToken()) {
              clientSessionActionService.unselectPaymentMethod(false);
              store.selectVault(null);
              massToggle(
                ClassName.SAVED_PAYMENT_METHOD,
                ClassName.SELECTED,
                () => false,
              );
            }
          }
        },
        fields: {
          cardNumber: {
            container: '#primer-checkout-card-number-input',
            placement: 'prepend',
            placeholder:
              options.card?.cardNumber?.placeholder ?? '1234 1234 1234 1234',
            ariaLabel: store.getTranslations().cardNumber,
          },
          expiryDate: {
            container: '#primer-checkout-card-expiry-input',
            placeholder:
              options.card?.expiryDate?.placeholder ??
              store.getTranslations().cardExpiryPlaceholder,
            ariaLabel: store.getTranslations().cardExpiry,
          },
          cvv: {
            container: '#primer-checkout-card-cvv-input',
            placeholder: options.card?.cvv?.placeholder ?? '',
            ariaLabel: store.getTranslations().cardCVV,
          },
        },
      },
    });

    return config;
  },
};

function inputValueGetter(elementId: ElementID) {
  return () => getAttribute(elementId, 'value');
}
