import {
  IFormInputGroupsDescription,
  IFormFieldDescription,
  IFormSchema,
} from '../types';

export function buildFormSchema(
  formFieldDescriptions: IFormFieldDescription[],
): IFormSchema {
  const formFields = formFieldDescriptions.map((field) =>
    buildFormField(field),
  );
  const formSchemaBuiltFromDescription = {
    fields: formFields,
  };

  if (formSchemaBuiltFromDescription.fields.length === 0) {
    return formSchemaBuiltFromDescription;
  }

  return formSchemaBuiltFromDescription;
}

export function filterFormSchema(
  formSchema: IFormSchema,
  fields: Record<string, boolean>,
): IFormSchema {
  const fieldsToAdd = Object.keys(fields).filter((key) => fields[key]);
  formSchema.fields = formSchema.fields.flatMap((field) => {
    field.inputGroups = field.inputGroups
      .flatMap((inputGroup) => {
        inputGroup.inputs = inputGroup.inputs.filter((input) =>
          fieldsToAdd.includes(input.name),
        );
        return inputGroup;
      })
      .filter((inputGroup) => inputGroup.inputs.length > 0);

    return field;
  });

  return formSchema;
}

export function buildAndFilterFormSchema(
  formFieldDescriptions: IFormFieldDescription[],
  fields: Record<string, boolean>,
): IFormSchema {
  const formSchemaBuiltFromDescription = buildFormSchema(formFieldDescriptions);

  return filterFormSchema(formSchemaBuiltFromDescription, fields);
}

function buildFormField(formFieldDescription: IFormFieldDescription) {
  const inputGroups = buildFormInputGroups(formFieldDescription);
  const singleInputGroup = inputGroups?.length === 1;
  const singleInput = inputGroups[0]?.inputs.length === 1;

  // If only one input in form description, use its name as label
  let labelKey;
  if (singleInput && singleInputGroup) {
    labelKey = `${inputGroups[0]?.inputs[0]?.name}Label`;
  } else {
    labelKey = `${formFieldDescription.formLabel}Label`;
  }

  return {
    labelKey,
    inputGroups,
  };
}

function buildFormInputGroups(formFieldDescription: IFormFieldDescription) {
  return formFieldDescription?.inputGroups
    .map((inputGroup) => {
      const inputs = buildInputs(inputGroup);

      const orientation = [
        inputs.length > 1,
        inputGroup[0].halfWidth === true,
      ].includes(true)
        ? 'horizontal'
        : 'vertical';

      return {
        orientation,
        inputs,
      };
    })
    .filter(Boolean);
}

function buildInputs(inputGroups: IFormInputGroupsDescription) {
  return inputGroups.map((input) => {
    const validation = input.validation ?? {
      validationRules: [{ type: 'REQUIRED' }],
    };

    return {
      id: input.name,
      name: input.name,
      type: input.type ?? 'text',
      placeholderKey: `${input.name}Placeholder`,
      errorMessageKey: `${input.name}Error`,
      value: input.value ?? '',
      validation: input.validation ?? validation,
      halfWidth: input.halfWidth,
      flex: input.flex,
      maxLength: input.maxLength,
      minLength: input.minLength,
    };
  });
}
