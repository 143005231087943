import { Api } from './Api';
import { ClientConfiguration } from './ClientContextFactory';
import { ClientTokenHandler } from './ClientTokenHandler';

export default class ClientConfigurationHandler {
  private api: Api;

  private clientTokenHandler: ClientTokenHandler;

  constructor(api: Api, clientTokenHandler: ClientTokenHandler) {
    this.api = api;
    this.clientTokenHandler = clientTokenHandler;
  }

  public async getClientConfiguration(clientToken: string) {
    this.clientTokenHandler.setClientToken(clientToken);

    const decodedClientToken = this.clientTokenHandler.getCurrentDecodedClientToken();
    if (!decodedClientToken) {
      throw new TypeError('Cannot parse client token');
    }

    const { data, error } = await this.api.get<ClientConfiguration>(
      decodedClientToken.configurationUrl,
      { apiVersion: '2021-10-19' },
    );

    if (error) {
      throw new Error(`Failed to initialize client: ${error}`);
    }

    if (!data) {
      throw new Error('Failed to initialize client');
    }

    return data;
  }
}
