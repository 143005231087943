import { FunctionalComponent, h } from 'preact';
import { ChevronLeft } from 'preact-feather';
import styled from 'styled-components';
import { BaseButton } from '../Button';

const Root = styled(BaseButton)`
  width: auto;
  z-index: 1;
  padding: 8px;
  margin: -8px;

  ${(p) => ({ color: p.theme.style?.backButton?.color })}
`;

type Props = {
  disabled?: boolean;
  onClick: () => void;
};

const BackButton: FunctionalComponent<Props> = ({ disabled, onClick }) => {
  return (
    <Root
      disabled={disabled}
      type='button'
      data-testid='Scene.backButton'
      id='primer-checkout-go-back'
      className='PrimerCheckout__backButton'
      onClick={onClick}
    >
      <ChevronLeft className='icon' />
    </Root>
  );
};

export default BackButton;
