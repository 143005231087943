import {
  useCheckoutContext,
  useSelector,
  useStoreSelector,
  useStore,
} from '@primer-io/shared-library/contexts';
import { Fragment, FunctionalComponent } from 'preact';
import { createPortal } from 'preact/compat';
import { useEffect, useLayoutEffect, useRef, useState } from 'preact/hooks';
import { createFocusTrap, FocusTrap } from 'focus-trap';

import styled from 'styled-components';
import { Icons } from '../../assets/icons/Icons';
import { AnimatePresence } from '../../utils/AnimatePresence';
import { usePresence } from '../../utils/AnimatePresence/use-presence';
import { noop } from '../../utils/noop';
import { canFocusOnPopup } from './PopupService';
import { PaymentMethodPopupOverlayStore } from './PaymentMethodPopupOverlayStore';

const createOverlayContainer = () => {
  const overlay = window.document.createElement('div');
  overlay.className = 'PrimerCheckout';
  overlay.style.position = 'fixed';
  overlay.style.zIndex = '100000';
  overlay.style.top = '0';
  overlay.style.left = '0';
  overlay.style.width = '100vw';
  overlay.style.height = '100vh';

  return overlay;
};

type ContentProps = {
  paymentMethodName?: string;
  logoSrc?: string;
  background?: string;
  logoAltLabel?: string;
  canFocus?: boolean;
  onCloseClick?: () => void;
  onFocusClick?: () => void;
};

const PopupHeader = styled.div`
  padding: 24px;
  height: 75px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #9f9f9f45;
`;

const PopupBody = styled.div`
  padding: 24px;
`;

export const Content: FunctionalComponent<ContentProps> = ({
  paymentMethodName,
  logoSrc,
  background,
  logoAltLabel,
  canFocus,
  onCloseClick,
  onFocusClick,
}) => {
  const labels = useSelector((s) => s.translation);
  const { className } = useCheckoutContext();
  const [isVisible, setIsVisible] = useState(false);
  const [isPresent, safeToRemove] = usePresence();

  useEffect(() => {
    setIsVisible(isPresent);

    if (!isPresent) {
      setTimeout(() => safeToRemove?.(), 500);
    }
  }, [isPresent]);

  return (
    <div className={className('popupOverlay', { visible: isVisible })}>
      <div className={className('popupOverlayContent', { visible: isVisible })}>
        <PopupHeader
          style={{ backgroundColor: background }}
          data-testId='PaymentMethodPopupOverlay.header'
        >
          <button
            className={className('popupOverlayCloseButton', {
              visible: isVisible,
            })}
            data-testId='PaymentMethodPopupOverlay.closeButton'
            aria-label={labels?.paymentMethodPopupOverlayClose}
            onClick={onCloseClick}
          >
            {Icons.x}
          </button>

          {logoSrc && logoAltLabel && (
            <img
              className={className('popupOverlayContent__logo')}
              src={logoSrc}
              alt={logoAltLabel}
              data-testId={'PaymentMethodPopupOverlay.logoImg'}
            />
          )}
        </PopupHeader>
        <PopupBody data-testId='PaymentMethodPopupOverlay.body'>
          <div className={className('popupOverlayContent__description')}>
            {canFocus &&
              labels._('paymentMethodPopupOverlayDescription', {
                paymentMethodName: paymentMethodName ?? '',
              })}
            {!canFocus &&
              labels._('paymentMethodPopupOverlayNonFocusDescription', {
                paymentMethodName: paymentMethodName ?? '',
              })}
          </div>
          {canFocus && (
            <button
              className={className('popupOverlayContent__focusButton')}
              data-testId='PaymentMethodPopupOverlay.focusButton'
              onClick={onFocusClick}
            >
              {labels?.paymentMethodPopupOverlayBringBackThePaymentPage}
            </button>
          )}
        </PopupBody>
      </div>
    </div>
  );
};

type ContainerProps = {
  isVisible: boolean;
} & ContentProps;

const Container: FunctionalComponent<ContainerProps> = ({
  isVisible,
  onCloseClick,
  ...otherProps
}) => {
  const focusTrap = useRef<FocusTrap | null>(null);

  const overlayContainerRef = useRef<HTMLDivElement>();

  if (!overlayContainerRef.current) {
    const overlay = createOverlayContainer();
    window.document.body.appendChild(overlay);
    overlayContainerRef.current = overlay;
  }

  overlayContainerRef.current.style.pointerEvents = isVisible ? 'all' : 'none';

  useLayoutEffect(() => {
    if (isVisible) {
      focusTrap.current = createFocusTrap(overlayContainerRef.current, {
        onDeactivate: onCloseClick,
        returnFocusOnDeactivate: true,
        clickOutsideDeactivates: false,
        allowOutsideClick: false,
      });
      focusTrap.current.activate();
    } else {
      focusTrap.current?.deactivate({ onDeactivate: noop });
      focusTrap.current = null;
    }
  }, [isVisible]);

  return (
    <>
      {createPortal(
        <div>
          <AnimatePresence>
            {[
              isVisible && (
                <Content
                  key={'key'} /* Fake key mandatory of AnimatePresence */
                  onCloseClick={onCloseClick}
                  {...otherProps}
                />
              ),
            ]}
          </AnimatePresence>
        </div>,
        overlayContainerRef.current,
      )}
    </>
  );
};

const PaymentMethodPopupOverlay = () => {
  const store = useStore<PaymentMethodPopupOverlayStore>(
    'paymentMethodPopupOverlay',
  );
  const props = useStoreSelector(store, (state) => state);
  const canFocus = canFocusOnPopup();

  return <Container {...props} canFocus={canFocus} />;
};

export default PaymentMethodPopupOverlay;
