import { useEffect } from 'preact/hooks';
import {
  useCheckoutContext,
  useCheckoutStore,
} from '@primer-io/shared-library/contexts';
import IconTextButton from '@primer-io/shared-library/components/IconTextButton';
import { BasePaymentMethod } from './BasePaymentMethod';
import toCamelCase from '../utils/toCamelCase';

export type CreatePaymentMethodButtonOptions = {
  paymentMethod: BasePaymentMethod;
  paymentMethodType: string;
  backgroundColor: string;
  iconSrc: any;
  iconAlt: string;
  iconHeight?: string;
  rootId?: string;
  buttonId?: string;
  text?: string;
  textColor?: string;
  linkedPaymentMethodScene?: string;
  onClick?: (paymentMethod) => void;
  onMounted?: () => void;
};

export type PaymentMethodButtonOptions = {
  paymentMethodType: string;
  backgroundColor: string;
  iconSrc: any;
  iconAlt: string;
  iconHeight?: string;
  rootId?: string;
  buttonId?: string;
  linkedPaymentMethodScene?: string;
  textColor?: string;
  onClick?: (paymentMethod) => void;
  text?: string;
};

const BasePaymentMethodButton = ({
  paymentMethod,
  paymentMethodType,
  backgroundColor,
  iconSrc,
  iconAlt,
  iconHeight,
  rootId,
  buttonId,
  text,
  textColor,
  linkedPaymentMethodScene,
  onClick,
  onMounted,
}: CreatePaymentMethodButtonOptions) => {
  const store = useCheckoutStore();

  const { context, viewUtils } = useCheckoutContext();

  const apmButtonStyle = viewUtils.styleManager.getApmButtonStyle();

  const translationKey = text ? toCamelCase(text) : '';

  const buttonText = store.getTranslations()[translationKey] ?? text;

  const buttonBackgroundColor = apmButtonStyle?.background ?? backgroundColor;

  const buttonTextColor = apmButtonStyle?.primaryText?.color ?? textColor;

  const icon =
    iconSrc[apmButtonStyle?.logoColor?.toLowerCase() ?? 'colored'] ??
    iconSrc.colored;

  useEffect(() => {
    onMounted?.();
  }, []);

  const handleClick = () => {
    // Analytics
    context?.analytics.setPaymentIntent(paymentMethodType);
    context?.analytics.call({
      event: `CLICKED_${paymentMethodType}_BUTTON`,
    });
    if (linkedPaymentMethodScene) {
      store.setScene(linkedPaymentMethodScene);
      return;
    }
    if (onClick) {
      onClick(paymentMethod);
    }
  };

  return (
    <IconTextButton
      iconAlt={iconAlt}
      rootId={rootId}
      icon={icon}
      buttonId={buttonId}
      onClick={handleClick}
      text={buttonText}
      backgroundColor={buttonBackgroundColor}
      iconHeight={iconHeight}
      textColor={buttonTextColor}
    />
  );
};

export default BasePaymentMethodButton;
