import styled from 'styled-components';
import Scene from '@primer-sdk-web/scenes/Scene';
import {
  useCheckoutStore,
  useSceneLoaderContext,
} from '@primer-io/shared-library/contexts';
import {
  useSceneEntering,
  useSceneMounting,
} from '@primer-sdk-web/utils/hooks';

import { FunctionalComponent } from 'preact';
import Spinner from '../Spinner';
import { SecondaryButton } from '../Button';
import { Title3 } from '../Text';

interface Props {
  hasFailed: boolean;
  scene: string;
  sceneRootId: string;
  iconSrc: string;
  iconAlt: string;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  padding-bottom: 32px;
`;

const StyledTitle3 = styled(Title3)`
  text-align: center;
`;

const LoadingScene: FunctionalComponent<Props> = ({
  hasFailed,
  scene,
  sceneRootId,
  iconSrc,
  iconAlt,
}) => {
  const store = useCheckoutStore();

  const labels = store.getTranslations();
  const { setIsMounted } = useSceneLoaderContext();

  useSceneMounting(() => {
    setIsMounted();
  }, scene);

  useSceneEntering(() => {
    store.setSubmitButtonVisible(false);
    store.setSubmitButtonDisabled(true);
    store.setIsLoading(false);
    store.setIsProcessing(false);
  }, scene);

  return (
    <Scene
      backButton={false}
      sceneRootId={sceneRootId}
      scene={scene}
      dimmable={true}
      iconSrc={iconSrc}
      iconAlt={iconAlt}
    >
      <Root className='PrimerCheckout__sceneElement'>
        {!hasFailed && (
          <>
            <Spinner />
            <StyledTitle3 data-testId='Loading/Text'>
              {labels._('loading')}
            </StyledTitle3>
          </>
        )}
        {hasFailed && (
          <SecondaryButton
            onClick={() => {
              store.setScene('UniversalCheckout/Home', 'pop');
              store.setErrorMessage(null);
            }}
          >
            {labels._('chooseAnotherPaymentMethod')}
          </SecondaryButton>
        )}
      </Root>
    </Scene>
  );
};

export default LoadingScene;
