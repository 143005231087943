import { IBaseStore } from '../store/BaseStore';
import { IStyleManager } from '../checkout/ui/StyleManager';
import { PaymentMethodType } from '../enums/Tokens';
import { Validation } from '../types';

// FIXME: Circular dependency
// import { PaymentMethodConfig } from '../core/ClientContextFactory';
// import { IPaymentMethodContext } from '../core/PaymentMethodContext';

export type PaymentMethodSpecs = {
  key: string;
  buttonManagedByPaymentMethod: boolean;
  canVault: boolean;
  hasExportedButtonOptions: boolean;
  homeScene?: string;
};

export class BasePaymentMethod {
  static specs: PaymentMethodSpecs = {
    key: '__none__',
    canVault: false,
    buttonManagedByPaymentMethod: false,
    hasExportedButtonOptions: false,
  };

  readonly type: PaymentMethodType;

  public displayName: string;

  public container: Element | null;

  public remoteConfig: any;

  protected constructor(type: PaymentMethodType, name: string) {
    this.type = type;
    this.displayName = name;
  }

  static create = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _context: any,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _options: Record<string, any>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _remoteConfig: any,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _styleManager?: IStyleManager,
  ): BasePaymentMethod => {
    throw new Error('Cannot instantiate BasePaymentMethod');
  };

  get specs(): PaymentMethodSpecs {
    return (this.constructor as typeof BasePaymentMethod).specs;
  }

  setupAndValidate(): Promise<boolean> {
    return Promise.resolve(true);
  }

  mount(): Promise<boolean> {
    return Promise.resolve(true);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tokenize(options?: any): Promise<void> {
    return Promise.resolve();
  }

  validate(): Promise<Validation> {
    return Promise.resolve({ valid: true, validationErrors: [] });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDisabled(disabled: boolean): Promise<void> {
    return Promise.resolve();
  }

  getStore(): IBaseStore | undefined {
    return undefined;
  }
}
