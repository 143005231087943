import { CreditCardStore } from '../payment-methods/credit-card/CreditCardStore';
import { ElementID } from '../enums/Checkout';
import { CreditCard } from '../payment-methods/credit-card/CreditCard';
import CheckoutStore from '../store/CheckoutStore';
import {
  UniversalCheckoutOptions,
  InputValidationError,
  PaymentMethodType,
  SinglePaymentMethodCheckoutOptions,
  VaultManagerOptions,
} from '../types';
import { getAttribute } from './DomUtilities';
import { CardInformationStore } from '../checkout-modules/card-information/CardInformationStore';

interface TokenizationInput {
  valid: boolean;
  validationErrors: InputValidationError[];
}

export class CardTokenizationService {
  private store: CheckoutStore;

  private options:
    | UniversalCheckoutOptions
    | VaultManagerOptions
    | SinglePaymentMethodCheckoutOptions;

  private isTokenizing: boolean;

  constructor(
    store: CheckoutStore,
    options:
      | UniversalCheckoutOptions
      | VaultManagerOptions
      | SinglePaymentMethodCheckoutOptions,
  ) {
    this.store = store;
    this.options = options;
    this.isTokenizing = false;
  }

  public async validateCardForm(): Promise<TokenizationInput> {
    const moduleCardholderNameVisible =
      this.store.getCheckoutModuleWithType<CardInformationStore>(
        'CARD_INFORMATION',
      )?.isShowingCardholderName ?? true;

    const optionsCardholderNameVisible = this.options.card?.cardholderName
      ?.visible;
    const optionsCardholderNameRequired = this.options.card?.cardholderName
      ?.required;

    let cardholderNameRequired;
    if (moduleCardholderNameVisible !== undefined) {
      cardholderNameRequired = moduleCardholderNameVisible;
    } else if (optionsCardholderNameVisible === false) {
      cardholderNameRequired = false;
    } else {
      cardholderNameRequired = optionsCardholderNameRequired ?? true;
    }

    let valid = false;
    const validationErrors: InputValidationError[] = [];

    const card = this.store.getPaymentMethodWithType<CreditCard>(
      PaymentMethodType.PAYMENT_CARD,
    ) as CreditCard;
    const creditCardStore = this.store.getPaymentMethodStoreWithType<CreditCardStore>(
      PaymentMethodType.PAYMENT_CARD,
    ) as CreditCardStore;

    const cardValidation = await card.validate();
    let uxValid = true;

    const value = getAttribute(
      ElementID.CARDHOLDER_NAME_INPUT,
      'value',
    )?.trim();

    if (value && value.match(/\d+/g)) {
      const error = this.store.state.getState().translation
        .cardNameContainsNumbers;

      creditCardStore.setCardholderNameFieldError(error);
      validationErrors.push({
        name: 'cardholderName',
        error: 'cardNameContainsNumbers',
        message: error,
      });

      uxValid = false;
    } else if (cardholderNameRequired) {
      const error = value
        ? null
        : this.store.state.getState().translation.cardNameRequired;

      creditCardStore.setCardholderNameFieldError(error);

      if (error) {
        validationErrors.push({
          name: 'cardholderName',
          error: 'cardNameRequired',
          message: error,
        });
      }
      uxValid = !!value;
    }

    valid = cardValidation.valid && uxValid;
    validationErrors.push(...cardValidation.validationErrors);

    return {
      valid,
      validationErrors,
    };
  }

  public async tokenizeCard(): Promise<void> {
    if (this.isTokenizing) {
      return;
    }
    this.isTokenizing = true;

    const { valid } = await this.validateCardForm();
    const card = this.store.getPaymentMethodWithType(
      PaymentMethodType.PAYMENT_CARD,
    ) as CreditCard;

    if (!valid) {
      this.isTokenizing = false;
      return;
    }

    await card.tokenize();
    this.isTokenizing = false;
  }
}
