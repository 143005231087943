import {
  useCheckoutContext,
  useCheckoutStore,
  useSelector,
} from '@primer-io/shared-library/contexts';
import { CreditCard } from 'preact-feather';
import IconTextButton from '@primer-io/shared-library/components/IconTextButton';
import { SceneEnum } from '../../enums/Checkout';

const CreditCardButton = () => {
  const store = useCheckoutStore();

  const { viewUtils } = useCheckoutContext();

  const labels = useSelector((s) => s.translation);

  const apmButtonStyle = viewUtils.styleManager.getApmButtonStyle();

  const handleClick = () => {
    store.setScene(SceneEnum.CREDIT_CARD_FORM);
  };

  const defaultBackgroundColor = '#ffffff';

  const buttonBackgroundColor =
    apmButtonStyle?.background ?? defaultBackgroundColor;

  return (
    <IconTextButton
      featherIcon={CreditCard}
      text={labels?.creditOrDebitCard}
      buttonId={'primer-checkout-credit-card-button'}
      onClick={handleClick}
      backgroundColor={buttonBackgroundColor}
    />
  );
};

export default CreditCardButton;
