import { IInput } from '@primer-io/shared-library/form';
import CallQueue from '../../utils/CallQueue';
import { ClientSessionActionService } from '../../checkout/client-session/ClientSessionActionService';
import { IBaseState, BaseStore } from '../../store/BaseStore';
import { ICheckoutModuleServices } from '../types';

type Fields = {
  firstName: boolean;
  lastName: boolean;
  addressLine1: boolean;
  addressLine2: boolean;
  city: boolean;
  postalCode: boolean;
  state: boolean;
  countryCode: boolean;
};

interface BillingAddressFields {
  billingAddressFields: Fields;
}

interface BillingAddressState extends IBaseState, BillingAddressFields {
  isSendingBillingAddress: boolean;
}

const billingAddressDefaultState: BillingAddressState = {
  billingAddressFields: {
    firstName: false,
    lastName: false,
    addressLine1: false,
    addressLine2: false,
    city: false,
    postalCode: false,
    state: false,
    countryCode: false,
  },

  isSendingBillingAddress: false,
};

export const BillingAddressStoreSelector = {
  getFields: (s: BillingAddressState): BillingAddressFields => ({
    billingAddressFields: s.billingAddressFields,
  }),
  getIsSendingBillingAddress: (s: BillingAddressState) =>
    s.isSendingBillingAddress,
};

export class BillingAddressStore extends BaseStore<BillingAddressState> {
  static type = 'BILLING_ADDRESS';

  get type() {
    return BillingAddressStore.type;
  }

  private clientSessionActionService: ClientSessionActionService;

  private callQueue: CallQueue;

  constructor(
    defaultState: BillingAddressState,
    { clientSessionActionService }: ICheckoutModuleServices,
  ) {
    super(defaultState);
    this.clientSessionActionService = clientSessionActionService;

    this.initCallQueue();
  }

  private initCallQueue() {
    // Make sure only one call is made at a time
    this.callQueue = new CallQueue();
    this.callQueue.handleCall = (data) => {
      Object.entries(data).forEach(([key, value]) => {
        data[key] = !value ? null : value;
      });
      return this.clientSessionActionService.setBillingAddress(data);
    };

    // Only grab the latest data
    this.callQueue.handleMerge = (_previousData, data) => data;
  }

  setFields(fields: Fields) {
    this.produceState((draft) => {
      Object.entries(fields).forEach(([key, value]) => {
        draft.billingAddressFields[key] = value;
      });
    });
  }

  async setPostalCodeValue(postalCode: string) {
    this.setIsSendingBillingAddress(true);
    await this.callQueue.call({ postalCode });
    this.setIsSendingBillingAddress(this.callQueue.isCalling);
  }

  async setBillingAddressValue(billingAddressSubmitData) {
    const billingAddress = this.createBillingAddressUpdateInput(
      billingAddressSubmitData,
    );

    if (Object.values(billingAddress).length === 0) {
      return;
    }
    this.setIsSendingBillingAddress(true);
    await this.callQueue.call(billingAddress);
    this.setIsSendingBillingAddress(this.callQueue.isCalling);
  }

  get fields() {
    return BillingAddressStoreSelector.getFields(this.getState())
      .billingAddressFields;
  }

  private setIsSendingBillingAddress(isSendingBillingAddress: boolean) {
    this.produceState((draft) => {
      draft.isSendingBillingAddress = isSendingBillingAddress;
    });
  }

  private createBillingAddressUpdateInput(submitData): Record<string, IInput> {
    const inputs: IInput[] = Object.values(submitData);
    return inputs.reduce((acc: any, input: IInput) => {
      acc[input.name] = input.value;
      return acc;
    }, {});
  }
}

const createBillingAddressStore = (services: ICheckoutModuleServices) =>
  new BillingAddressStore(billingAddressDefaultState, services);
export default createBillingAddressStore;
