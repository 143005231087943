import cardValidator from 'card-validator';
import creditCardType from 'credit-card-type';

interface CardDescriptor {
  type: Nullable<string>;
  code: {
    size: number;
  };
  lengths: number[];
  gaps: number[];
}

interface CardInfo {
  isValid: boolean;
  isPotentiallyValid: boolean;
  card: CardDescriptor;
}

export function getCardInfo(value: string): CardInfo {
  const validation = cardValidator.number(value);
  return {
    isValid: validation.isValid || false,
    isPotentiallyValid: validation.isPotentiallyValid || false,
    card: {
      type: validation.card?.type || null,
      code: validation.card?.code || { size: 3 },
      lengths: validation.card?.lengths || [16],
      gaps: validation.card?.gaps || [4, 8, 12],
    },
  };
}

export function getPossibleCardTypes(value: string): string[] {
  return creditCardType(value)
    .map((e) => e.type)
    .filter(Boolean);
}
