import { useLayoutEffect, useRef, useState } from 'preact/hooks';
import {
  useCheckoutContext,
  useSelector,
} from '@primer-io/shared-library/contexts';
import {
  animationCurve,
  getBlockStyle,
  getTextStyle,
} from '@primer-io/shared-library/components';
import styled from 'styled-components';

import { Icons } from '../assets/icons/Icons';
import { ElementID } from '../enums/Checkout';
import { useIsFirstRender } from '../utils/hooks';

const Root = styled.div`
  transition: all 300ms ${animationCurve};

  &.PrimerCheckout--enter {
    opacity: 0;
    transform: translateY(-20px);
  }

  &.PrimerCheckout--entering,
  &.PrimerCheckout--entered {
    opacity: 1;
    transform: translateY(0px);
  }

  &.PrimerCheckout--exit {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

const Message = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  padding: 12px;

  & > .icon {
    height: 24px;
    width: 24px;
    margin-right: 12px;
  }

  & > span {
    flex: 1;
  }

  ${(p) => getTextStyle(p.theme.style?.errorMessage)}
  ${(p) => getBlockStyle(p.theme.style?.errorMessage)}
`;

const DURATION = 300;

const ErrorMessage = () => {
  const { viewUtils, options } = useCheckoutContext();
  const message = useSelector((s) => s.error.message);
  const [content, setContent] = useState(message);
  const isVisible = useRef(false);
  const isFirstRender = useIsFirstRender();

  const toggleVisibility = (show: boolean, animated = true): void => {
    isVisible.current = false;

    viewUtils.toggleVisibilityAnimated(ElementID.ERROR_MESSAGE, show, {
      duration: animated ? DURATION : 0,
      animateHeight: true,
      autoHeight: true,
    });
  };

  useLayoutEffect(() => {
    if (!message) {
      toggleVisibility(false, !isFirstRender);
    } else {
      if (options.errorMessage?.disabled === true) {
        return;
      }

      toggleVisibility(true, !isFirstRender);
      setContent(message);
    }
  }, [message]);

  return (
    <Root id='primer-checkout-error-message'>
      <Message className='PrimerCheckout__errorMessage'>
        {Icons.error}
        <span>{content}</span>
      </Message>
    </Root>
  );
};

export default ErrorMessage;
