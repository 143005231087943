import { AnalyticsAction } from '../models/AnalyticsAction';
import { IAnalyticsProvider } from './AnalyticsProvider';

export class MockAnalyticsProvider implements IAnalyticsProvider {
  private timers = {};

  call(action: AnalyticsAction): boolean {
    const timer = this.timers[action.event];
    if (timer) {
      action.data ??= {};
      action.data.$duration = Date.now() - timer;
    }

    console.debug(
      `🦄.call \`${action.event}\``,
      action.data,
      action.data?.sessionId,
    );
    return true;
  }

  time({ data, event }: AnalyticsAction): boolean {
    this.timers[event] = Date.now();
    console.debug(`🦄.time \`${event}\``, data?.sessionId);
    return true;
  }
}
