import { debounce } from 'throttle-debounce';

import CheckoutStore from '../../store/CheckoutStore';
import { PaymentInstrumentType, PaymentMethodType } from '../../types';
import CallQueue from '../../utils/CallQueue';
import { OnClientSessionDataChangeHandler } from './ClientSessionActionHandler';
import { ClientSessionAction } from './types';

export class ClientSessionActionService {
  private store: CheckoutStore;

  private onClientSessionDataChangeHandler: OnClientSessionDataChangeHandler;

  private callQueue: CallQueue<Array<ClientSessionAction>>;

  constructor(
    store: CheckoutStore,
    onClientSessionDataChangeHandler: OnClientSessionDataChangeHandler,
  ) {
    this.store = store;
    this.onClientSessionDataChangeHandler = onClientSessionDataChangeHandler;

    this.initCallQueue();
  }

  private initCallQueue() {
    // Make sure only one call is made at a time
    this.callQueue = new CallQueue();
    this.callQueue.handleCall = (data) => this.performActions(data);
    this.callQueue.handleMerge = (previousData, data) => [
      ...previousData,
      ...data,
    ];
  }

  ///////////////////////////////////////////
  // SELECT/UNSELECT PAYMENT METHOD
  ///////////////////////////////////////////

  public async selectPaymentMethod(
    type: PaymentMethodType | string,
    shouldSetLoading?: boolean,
  ): Promise<void> {
    if (!this.store.hasClientSession) {
      return;
    }

    const paymentMethodType = this.mapPaymentInstrumentToPaymentMethod(type);

    this.setIsLoading(true, shouldSetLoading);
    const result = await this.performAction({
      type: 'SELECT_PAYMENT_METHOD',
      params: { paymentMethodType },
    });

    if (result) {
      this.store.setSelectedPaymentMethod(type);
    }

    this.setIsLoading(false, shouldSetLoading);
  }

  public async unselectPaymentMethod(
    shouldSetLoading?: boolean,
  ): Promise<void> {
    if (!this.store.hasClientSession || !this.store.hasSelectedPaymentMethod) {
      return;
    }

    this.setIsLoading(true, shouldSetLoading);

    const result = await this.performAction({
      type: 'UNSELECT_PAYMENT_METHOD',
    });

    if (result) {
      this.store.setSelectedPaymentMethod(null);
    }

    this.setIsLoading(false, shouldSetLoading);
  }

  public async selectVaultedCardNetwork(
    networkType: string | null,
    shouldSetLoading?: boolean,
  ): Promise<void> {
    if (!this.store.hasClientSession || !this.store.hasCardSurcharge) {
      return;
    }

    const binData = this.buildCardBinData(networkType?.toUpperCase());

    this.setIsLoading(true, shouldSetLoading);
    const result = await this.performAction({
      type: 'SELECT_PAYMENT_METHOD',
      params: {
        paymentMethodType: PaymentMethodType.PAYMENT_CARD,
        binData,
      },
    });

    if (result) {
      this.store.setSelectedPaymentMethod(PaymentMethodType.PAYMENT_CARD);
    }

    this.setIsLoading(false, shouldSetLoading);
  }

  public async selectCardNetwork(networkType: string | null): Promise<void> {
    if (!this.store.hasClientSession || !this.store.hasCardSurcharge) {
      return;
    }

    if (this.store.currentCardNetwork && !networkType) {
      this.debouncedCardNetworkSelection(this.store, 'UNSELECT_PAYMENT_METHOD');
    }

    if (networkType && this.store.currentCardNetwork !== networkType) {
      const binData = this.buildCardBinData(networkType.toUpperCase());
      this.debouncedCardNetworkSelection(
        this.store,
        'SELECT_PAYMENT_METHOD',
        binData,
      );
    }
  }

  public async setCustomerEmailAddress(emailAddress: string): Promise<void> {
    if (!this.store.hasClientSession) {
      return;
    }

    await this.performAction({
      type: 'SET_EMAIL_ADDRESS',
      params: {
        emailAddress,
      },
    });
  }

  private debouncedCardNetworkSelection = debounce(
    1000,
    false,
    async (store, type: string, binData?) => {
      store.setSubmitButtonDisabled(true);
      if (type === 'UNSELECT_PAYMENT_METHOD') {
        await this.performAction({
          type: 'UNSELECT_PAYMENT_METHOD',
        });

        this.store.setSelectedPaymentMethod(null);

        store.setSubmitButtonDisabled(false);
        return;
      }

      await this.performAction({
        type: 'SELECT_PAYMENT_METHOD',
        params: {
          paymentMethodType: 'PAYMENT_CARD',
          binData,
        },
      });

      this.store.setSelectedPaymentMethod(PaymentMethodType.PAYMENT_CARD);

      store.setSubmitButtonDisabled(false);
    },
  );

  private async performAction(action: ClientSessionAction) {
    return this.callQueue.call<boolean>([action]);
  }

  private async performActions(actions: Array<ClientSessionAction>) {
    return this.onClientSessionDataChangeHandler.onClientSessionActions?.(
      actions,
    );
  }

  private setIsLoading(loadingState: boolean, loadingIsActive?: boolean) {
    if (loadingIsActive || loadingIsActive === undefined) {
      this.store.setIsLoading(loadingState);
    }
  }

  private mapPaymentInstrumentToPaymentMethod(
    paymentMethodType: PaymentMethodType | PaymentInstrumentType | string,
  ) {
    if (paymentMethodType === PaymentInstrumentType.PAYPAL_VAULTED) {
      return PaymentMethodType.PAYPAL;
    }
    this.store.selectVault(null);
    return paymentMethodType;
  }

  // This will use actual binData in the future
  private buildCardBinData(network) {
    return {
      network,
      issuer_name: null,
      product_code: network,
      product_name: network,
      product_usage_type: 'UNKNOWN',
      account_number_type: 'UNKNOWN',
      issuer_country_code: null,
      account_funding_type: 'UNKNOWN',
      issuer_currency_code: null,
      regional_restriction: 'UNKNOWN',
      prepaid_reloadable_indicator: 'NOT_APPLICABLE',
    };
  }

  ///////////////////////////////////////////
  // Set billing address
  ///////////////////////////////////////////

  public async setBillingAddress(billingAddress: {
    postalCode: string;
  }): Promise<void> {
    if (!this.store.hasClientSession) {
      return;
    }

    await this.performAction({
      type: 'SET_BILLING_ADDRESS',
      params: { billingAddress },
    });
  }
}
