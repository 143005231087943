import { FunctionalComponent } from 'preact';
import styled, { css } from 'styled-components';

const VerticalCss = css`
  display: flex;
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const HorizontalCss = css`
  display: flex;
  flex-direction: row;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  & > div:only-child {
    max-width: ${(p) => (p.hasHalfWidthInput ? 'calc(50% - 5px)' : '100%')};
  }
`;

const StyledInputGroup = styled.div`
  ${(p) => (p.horizontal ? HorizontalCss : VerticalCss)}
`;

type Props = {
  hasHalfWidthInput: boolean;
  horizontal?: boolean;
};

const InputGroup: FunctionalComponent<Props> = ({
  hasHalfWidthInput,
  horizontal = false,
  children,
  ...otherProps
}) => {
  return (
    <StyledInputGroup
      horizontal={horizontal}
      hasHalfWidthInput={hasHalfWidthInput}
      {...otherProps}
    >
      {children}
    </StyledInputGroup>
  );
};

export default InputGroup;
