import { InternalFlowOptions } from '../../internalTypes';
import { ClientContext } from '../../core/ClientContextFactory';
import { CheckoutUXFlow } from '../../enums/Checkout';
import { UniversalCheckoutOptions, VaultManagerOptions } from '../../types';
import { UniversalCheckout } from './UniversalCheckout';
import { VaultManager } from './VaultManager';

const FlowFactory = {
  create: async (
    getContext: () => Promise<ClientContext>,
    options: InternalFlowOptions,
  ) => {
    if (options.uxFlow === CheckoutUXFlow.MANAGE_PAYMENT_METHODS) {
      return VaultManager.create(getContext, options as VaultManagerOptions);
    }

    return UniversalCheckout.create(
      getContext,
      options as UniversalCheckoutOptions,
    );
  },
};

export default FlowFactory;
