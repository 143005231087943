import { resolveElement } from '../utils/resolveElement';
import { toggleClass } from '../utils/toggleClass';
import { ClassName, ElementID, SceneEnum } from '../enums/Checkout';
import { delay } from '../utils/delay';
import { getFirstFocusableChild } from './focus';
import { Environment } from '../utils/Environment';
import { getClassNames } from '../utils/getClassNames';

// Remove "PrimerCheckout__SceneTransition--XXX" from class
const removeSceneTransitionActionClass = (scene: SceneEnum) => {
  const element = resolveElement(scene);

  if (element == null) {
    return;
  }

  const tokens = getClassNames(element);
  element.className = tokens
    .filter((name) => name.indexOf('PrimerCheckout__SceneTransition--') === -1)
    .join(' ');
};

export class SceneTransition {
  onEnter(scene: SceneEnum, duration?: number, type?: string): Promise<void> {
    return this.transition(scene, true, duration, type);
  }

  onExit(scene: SceneEnum, duration?: number, type?: string): Promise<void> {
    return this.transition(scene, false, duration, type);
  }

  private async transition(
    scene: SceneEnum,
    isEntering: boolean,
    duration?: number,
    type?: string,
  ): Promise<void> {
    const root = document.getElementById(ElementID.ROOT_CONTENT) as HTMLElement;
    const node = resolveElement(scene);

    if (node == null) {
      return;
    }

    if (isEntering) {
      root.getBoundingClientRect();
      root.style.height = `${root.offsetHeight}px`;
    }

    // Clear previous and add current
    toggleClass(scene, isEntering ? ClassName.EXIT : ClassName.ENTER, false);
    toggleClass(
      scene,
      isEntering ? ClassName.EXITING : ClassName.ENTERING,
      false,
    );
    toggleClass(
      scene,
      isEntering ? ClassName.EXITED : ClassName.ENTERED,
      false,
    );
    removeSceneTransitionActionClass(scene);

    toggleClass(scene, isEntering ? ClassName.ENTER : ClassName.EXIT, true);
    toggleClass(scene, `PrimerCheckout__SceneTransition--${type}`, true);

    node.getBoundingClientRect();

    // On entering
    toggleClass(
      scene,
      isEntering ? ClassName.ENTERING : ClassName.EXITING,
      true,
    );

    node.getBoundingClientRect();

    if (isEntering) {
      root.getBoundingClientRect();
      root.style.height = `${node.offsetHeight}px`;
    }

    if (isEntering) {
      if (!Environment.get('PRIMER_BUILD_INTEGRATION_BUILDER', false)) {
        getFirstFocusableChild(scene)?.focus();
      }
    }

    if (duration) {
      await delay(duration);
    }

    // On entered
    toggleClass(scene, isEntering ? ClassName.ENTERED : ClassName.EXITED, true);
    root.getBoundingClientRect();

    if (isEntering) {
      await delay(16); // Fix some jankyness: wait ~1 frame before setting auto
      root.style.height = 'auto';
    }
  }
}
