import { render, h } from 'preact';
import bem from 'easy-bem';
import {
  CheckoutProvider,
  StoreProvider,
  StyleProvider,
} from '@primer-io/shared-library/contexts';

import { internalClientSessionToClientSession } from '../../models/ClientSession';
import VaultPaymentTokenizationService from '../../checkout-modules/vault/VaultPaymentTokenizationService';
import { createPaymentMethodContextFactory } from '../../core/PaymentMethodContextFactory';
import { locateElement } from '../../utils/dom';
import { PaymentMethods } from '../../payment-methods/PaymentMethods';
import { CheckoutPaymentMethodConfig } from '../CheckoutPaymentMethodConfig';
import { CheckoutTokenizationHandlers } from '../CheckoutTokenizationHandlers';
import { BasePaymentMethod } from '../../payment-methods/BasePaymentMethod';
import {
  CardDetails,
  UniversalCheckoutOptions,
  PrimerCheckout,
  SinglePaymentMethodCheckoutOptions,
  SupportedLocale,
  TokenizationHandlers,
  CheckoutUXFlow,
} from '../../types';
import { Vault } from '../../checkout-modules/vault/Vault';
import { setValue } from '../DomUtilities';
import { ElementID } from '../../enums/Checkout';
import { Translations } from '../../utils/i18n/TranslationFactory';
import { IFrameEventType } from '../../core/IFrameEventType';
import { IFrameMessagePayload } from '../../core/IFrameMessage';
import { ClientContext } from '../../core/ClientContextFactory';
import normalizeOptions from '../../payment-methods/normalizeOptions';

import { createCheckoutViewUtils } from '../ui/ViewUtils';

import CheckoutRoot from '../../scenes/CheckoutRoot';

import { SceneTransition } from '../SceneTransition';
import { Environment } from '../../utils/Environment';
import { createStore } from '../../store/CheckoutStoreFactory';
import { getSystemLocale } from '../../utils/getSystemLocale';
import { createSetTokenizationEnabled } from '../OptionUpdater';
import { CheckoutModules } from '../../checkout-modules/CheckoutModules';
import { teardownUI } from '../Teardown';
import { CallbackHandlers } from '../CallbackHandlers';
import { CardTokenizationService } from '../CardTokenizationService';
import ClientSessionHandler from '../../core/ClientSessionHandler';
import { ClientSessionActionHandler } from '../client-session/ClientSessionActionHandler';
import {
  DeprecationWarningType,
  logWarning,
  WarningType,
} from '../../utils/consoleWarn';
import { ClientSessionActionService } from '../client-session/ClientSessionActionService';

import {
  ErrorCode,
  PrimerClientError,
  throwPrimerClientError,
} from '../../errors';
import GlobalStyle from '../../components/GlobalStyle';
import { startCheckoutFlow } from '../CheckoutFlowStarter';
import { CheckoutPaymentHandlers } from '../CheckoutPaymentHandlers';

const renderRoot = ({
  options,
  checkout,
  viewUtils,
  context,
  transitions,
  clientSessionActionService,
  vaultPaymentTokenizationService,
  store,
  root,
}: {
  options;
  checkout;
  viewUtils;
  context?;
  transitions;
  clientSessionActionService?;
  vaultPaymentTokenizationService?;
  onDataChangeHandler?;
  store;
  root;
}) =>
  render(
    <CheckoutProvider
      value={{
        options,
        checkout,
        viewUtils,
        context,
        transitions,
        clientSessionActionService,
        vaultPaymentTokenizationService,
        className: bem('PrimerCheckout'),
      }}
    >
      <StoreProvider value={store}>
        <StyleProvider value={{ style: viewUtils.styleManager.getStyle() }}>
          <GlobalStyle />
          <CheckoutRoot />
        </StyleProvider>
      </StoreProvider>
    </CheckoutProvider>,
    root,
  );

export class UniversalCheckout {
  static async create(
    getContext: () => Promise<ClientContext>,
    options: UniversalCheckoutOptions | SinglePaymentMethodCheckoutOptions,
  ): Promise<PrimerCheckout> {
    ///////////////////////////////////////////
    // Prepare root
    ///////////////////////////////////////////
    const parent = locateElement(options.container);

    if (parent == null) {
      return onConfigError(
        `Attempted to mount checkout UI at "${options.container}" but the element could not be found`,
      );
    }

    const root = document.createElement('div');
    parent.insertBefore(root, null);

    ///////////////////////////////////////////
    // Context
    ///////////////////////////////////////////
    const context = await getContext();

    ///////////////////////////////////////////
    // Render
    ///////////////////////////////////////////
    const store = createStore(options);
    store.setLocale(options.locale ?? (getSystemLocale() as SupportedLocale));
    store.setSceneTransition(options.scene?.transition);

    const viewUtils = createCheckoutViewUtils(store, options);
    const transitions = new SceneTransition();

    const tokenizationService = new CardTokenizationService(store, options);

    function submit() {
      return store.triggerSubmitButtonClick();
    }

    function tokenize() {
      logWarning(DeprecationWarningType.TOKENIZE);
      return submit();
    }

    async function validate() {
      logWarning(DeprecationWarningType.VALIDATE);
      const {
        valid,
        validationErrors,
      } = await tokenizationService.validateCardForm();
      return { valid, validationErrors };
    }

    async function setClientToken(clientToken: string): Promise<boolean> {
      if (!clientToken) {
        logWarning(WarningType.SET_CLIENT_TOKEN_WITHOUT_TOKEN);
        return false;
      }

      const sessionUpdated = await clientSessionHandler.updateClientConfigurationWithClientToken(
        clientToken,
      );
      if (!sessionUpdated) {
        console.warn(
          'The checkout was unable to update the client session with the provided client-token. Please ensure the token is not malformed and contains the necessary information.',
        );
        return false;
      }

      return true;
    }
    const checkout = {
      validate,
      submit,
      tokenize,
      setClientToken,
    };

    renderRoot({ options, checkout, viewUtils, transitions, store, root });

    ///////////////////////////////////////////
    // State
    ///////////////////////////////////////////
    const translation = await Translations.get(options.locale);

    // Localisation
    store.setTranslation(translation);

    // Form
    store.setInputLabelsVisible(options.form?.inputLabelsVisible ?? true);

    // Options
    store.setShowSavedPaymentMethods(false);

    if (
      !context.session?.clientSession.order.totalOrderAmount &&
      !context.session?.clientSession.order.merchantAmount
    ) {
      logWarning(WarningType.MISSING_AMOUNT_IN_CLIENT_SESSION);
    }
    if (!context.session?.clientSession.order.currencyCode) {
      logWarning(WarningType.MISSING_CURRENCY_IN_CLIENT_SESSION);
    }

    store.setClientSession(context.session.clientSession);

    options?.onClientSessionUpdate?.(
      internalClientSessionToClientSession(context.session.clientSession),
    );

    ///////////////////////////////////////////
    // Callback Handlers
    ///////////////////////////////////////////

    CallbackHandlers.create(store, options);

    ///////////////////////////////////////////
    // Vault & Payment Methods
    ///////////////////////////////////////////

    const processCheckoutModules = async () => {
      const checkoutModules = context.session.checkoutModules
        .map((checkoutModule) =>
          CheckoutModules.create(checkoutModule, {
            context,
            store,
            options,
            clientSessionActionService,
          }),
        )
        .filter(Boolean);

      store.setCheckoutModules(checkoutModules);
    };

    const processVault = async () => {
      // No vault in SINGLE_PAYMENT_METHOD_CHECKOUTs
      if (options.uxFlow === CheckoutUXFlow.SINGLE_PAYMENT_METHOD_CHECKOUT) {
        return;
      }

      const vault = new Vault(context);
      store.setVault(vault);

      // Vault is visible by default
      const vaultVisible = options?.vault?.visible ?? true;

      // Hiding the vault = not fetching the vault data
      if (vaultVisible === undefined || vaultVisible === true) {
        const vaulted = await vault.fetch();

        vaulted.forEach((pm) => {
          store.addVault(pm);
        });
        store.selectFirstVault();

        const selectedVaultItem = store.getSelectedVaultItem();

        if (selectedVaultItem) {
          if (selectedVaultItem.type === 'PAYMENT_CARD') {
            clientSessionActionService.selectVaultedCardNetwork(
              (selectedVaultItem.details as CardDetails).network,
            );
          } else {
            clientSessionActionService.selectPaymentMethod(
              selectedVaultItem.type,
            );
          }
        }
      }
    };

    const clientSessionHandler = new ClientSessionHandler(
      context.clientConfigurationHandler,
      store,
    );

    const clientSessionActionHandler = ClientSessionActionHandler.create(
      context,
      clientSessionHandler,
      store,
      options,
    );

    const clientSessionActionService = new ClientSessionActionService(
      store,
      clientSessionActionHandler,
    );

    // Payment Handlers to Tokenization Handlers
    let checkoutPaymentHandlers: TokenizationHandlers;

    if (!options.paymentHandling || options.paymentHandling === 'AUTO') {
      checkoutPaymentHandlers = CheckoutPaymentHandlers.create(
        context,
        options,
      );
    } else if (options.paymentHandling === 'MANUAL') {
      checkoutPaymentHandlers = {
        onTokenizeSuccess: options.onTokenizeSuccess,
        onTokenizeDidNotStart: options.onTokenizeDidNotStart,
        onTokenizeError: options.onTokenizeError,
        onResumeSuccess: options.onResumeSuccess,
        onResumeError: options.onResumeError,
        onTokenizeShouldStart: options.onTokenizeShouldStart,
        onTokenizeStart: options.onTokenizeStart,
      };
    } else {
      throw PrimerClientError.fromErrorCode(ErrorCode.INITIALIZATION_ERROR, {
        message: `Universal Checkout does not support "${options.paymentHandling}". Defaulting to the default value of "AUTO".`,
      });
    }

    // Augment tokenization handlers for Universal Checkout
    const checkoutTokenizationHandlers = CheckoutTokenizationHandlers.create(
      context,
      viewUtils,
      options,
      clientSessionActionService,
      checkoutPaymentHandlers,
    );

    // Payment method config
    const checkoutPaymentMethodConfig = CheckoutPaymentMethodConfig.create(
      store,
      viewUtils,
      options,
      clientSessionActionService,
    );

    const paymentMethodContextConfig = normalizeOptions({
      ...checkoutPaymentMethodConfig,
      ...checkoutTokenizationHandlers,
    });

    const paymentMethodContextFactory = createPaymentMethodContextFactory(
      context,
      paymentMethodContextConfig,
      store,
      store,
    );

    const vaultPaymentTokenizationService = new VaultPaymentTokenizationService(
      paymentMethodContextFactory,
    );

    let paymentMethods: Record<string, BasePaymentMethod> = {};
    const processPaymentMethods = async () => {
      paymentMethods = await PaymentMethods.create(
        context,
        paymentMethodContextFactory,
        paymentMethodContextConfig,
        viewUtils.styleManager,
      );

      store.setPaymentMethods(paymentMethods);
    };

    await Promise.all([
      processVault(),
      processPaymentMethods(),
      processCheckoutModules(),
    ]);

    if (Object.keys(store.getPaymentMethods()).length === 0) {
      throwPrimerClientError(
        PrimerClientError.fromErrorCode(ErrorCode.NO_PAYMENT_METHODS, {
          message: `No payment methods loaded. Cannot initialize Universal Checkout. \nMake sure that all payment methods are properly configured.`,
        }),
      );
    }

    store.setCardFlow(options.card?.preferredFlow ?? 'EMBEDDED_IN_HOME');

    context.messageBus.on(
      IFrameEventType.CARDHOLDER_NAME,
      (e: IFrameMessagePayload<string>) => {
        setValue(ElementID.CARDHOLDER_NAME_INPUT, e.payload);
      },
    );

    clientSessionHandler.setOnClientConfigurationUpdated(
      (clientConfiguration) => {
        // Forward new client session

        options?.onClientSessionUpdate?.(
          internalClientSessionToClientSession(store.getClientSession()),
        );

        // Remove Checkout Modules
        const initialCheckoutModules = store.getCheckoutModules();
        const filteredCheckoutModules = initialCheckoutModules.filter(
          (checkoutModule) =>
            clientConfiguration.checkoutModules.find(
              (checkoutModuleConfig) =>
                checkoutModuleConfig.type === checkoutModule.type,
            ),
        );
        store.setCheckoutModules(filteredCheckoutModules);

        // Add Checkout Modules
        const newCheckoutModules = clientConfiguration.checkoutModules
          .map((checkoutModuleConfig) => {
            const hasCheckoutModule = !!store.getCheckoutModuleWithType(
              checkoutModuleConfig.type,
            );
            if (hasCheckoutModule) {
              return undefined;
            }

            return CheckoutModules.create(checkoutModuleConfig, {
              context,
              store,
              options,
              clientSessionActionService,
            });
          })
          .filter(Boolean);

        store.setCheckoutModules([
          ...store.getCheckoutModules(),
          ...newCheckoutModules,
        ]);

        // Update Checkout Modules
        clientConfiguration.checkoutModules.forEach((checkoutModuleConfig) => {
          const checkoutModule = store.getCheckoutModuleWithType(
            checkoutModuleConfig.type,
          );
          if (!checkoutModule) {
            return;
          }

          CheckoutModules.update(checkoutModule, checkoutModuleConfig);
        });
      },
    );

    if (Environment.get('PRIMER_BUILD_INTEGRATION_BUILDER', false)) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
      const { CardProxy } = require('../CardProxy');

      const cardProxy = new CardProxy(
        context,
        paymentMethodContextConfig,
        paymentMethods.card,
      );
      // @ts-expect-error for integration builder
      checkout.card = cardProxy;
    }

    startCheckoutFlow({
      context,
      store,
      tokenizationHandlers: checkoutTokenizationHandlers,
      options,
    });

    renderRoot({
      options,
      checkout,
      viewUtils,
      transitions,
      store,
      root,
      context,
      clientSessionActionService,
      vaultPaymentTokenizationService,
    });

    function teardown() {
      if (context.session.isTeardown) {
        throw new Error('Checkout has already been torn down');
      }

      context.session.isTeardown = true;

      teardownUI(root);
    }

    const setTokenizationEnabled = createSetTokenizationEnabled(store);

    return {
      ...checkout,
      setPaymentCreationEnabled: setTokenizationEnabled,
      setTokenizationEnabled,
      teardown,
    };
  }
}

function onConfigError(message: string): PrimerCheckout {
  console.error(message);
  throw PrimerClientError.fromErrorCode(ErrorCode.INITIALIZATION_ERROR, {
    message,
  });
}
