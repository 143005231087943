import Set from '@ungap/set';
import { PaymentFlow } from '../types';
import { base64 } from '../utils/base64';

export enum ClientTokenIntent {
  CHECKOUT = 'CHECKOUT',
  THREEDS_AUTHENTICATION = '3DS_AUTHENTICATION',
  REDIRECTION_END = 'REDIRECTION_END',
}

export type CommonClientToken = {
  configurationUrl: string;
  analyticsUrl?: string;
  accessToken: string;
  paymentFlow: PaymentFlow;
};

type IntentClientToken =
  | {
      intent: ClientTokenIntent.CHECKOUT | undefined;
    }
  | {
      intent: ClientTokenIntent.THREEDS_AUTHENTICATION;
      tokenId: string;
      threeDSProvider: string;
      threeDSToken: string;
      threeDSInitUrl: string;
    }
  | {
      intent: ClientTokenIntent.REDIRECTION_END;
      paymentId: string;
      resumeToken: string;
    }
  | ({
      intent: string;
    } & Record<string, unknown>);

export type DecodedClientToken = CommonClientToken & IntentClientToken;

type NewClientTokenListener = (decodedClientToken: DecodedClientToken) => void;

export interface IClientTokenHandler {
  setClientToken(clientToken: string);
  getCurrentClientToken(): string | undefined;
  getCurrentDecodedClientToken(): DecodedClientToken | undefined;
  addNewClientTokenListener(listener: NewClientTokenListener);
  removeNewClientTokenListener(listener: NewClientTokenListener);
}

function decodeClientToken(clientToken: string): DecodedClientToken {
  const tokens = clientToken.split('.');
  const encoded = tokens.length === 1 ? tokens[0] : tokens[1];

  let decoded = null;

  try {
    decoded = JSON.parse(base64.decode(encoded));
  } catch (e) {
    /* empty */
  }

  if (decoded == null) {
    throw new TypeError('The provided `credentials.clientToken` is malformed!');
  }

  return decoded as DecodedClientToken;
}

export class ClientTokenHandler implements IClientTokenHandler {
  private newClientTokenListeners: Set<NewClientTokenListener>;

  private currentClientToken: string | undefined;

  private currentDecodedClientToken: DecodedClientToken | undefined;

  constructor() {
    this.newClientTokenListeners = new Set<NewClientTokenListener>();
  }

  getCurrentClientToken() {
    return this.currentClientToken;
  }

  getCurrentDecodedClientToken() {
    return this.currentDecodedClientToken;
  }

  setClientToken(clientToken: string) {
    this.currentClientToken = clientToken;
    const decodedClientToken = decodeClientToken(clientToken);
    this.currentDecodedClientToken = decodedClientToken;

    this.newClientTokenListeners.forEach((listener) =>
      listener(decodedClientToken),
    );
  }

  addNewClientTokenListener(listener: NewClientTokenListener) {
    this.newClientTokenListeners.add(listener);
  }

  removeNewClientTokenListener(listener: NewClientTokenListener) {
    this.newClientTokenListeners.delete(listener);
  }
}

export const createClientTokenHandler = () => new ClientTokenHandler();
