import { createFocusTrap, FocusTrap } from 'focus-trap';
import { useLayoutEffect, useRef, useState } from 'preact/hooks';
import {
  useCheckoutContext,
  useSelector,
} from '@primer-io/shared-library/contexts';
import styled from 'styled-components';
import {
  animationCurve,
  BaseButton,
  createWithStyle,
} from '@primer-io/shared-library/components';

import { OffClickHandler } from '../../../../checkout/OffClickHandler';
import { ElementID } from '../../../../enums/Checkout';
import { useFirstRender, usePrevious } from '../../../../utils/hooks';

import VaultMenuItem from './VaultMenuItem';

const withVaultMenuStyle = createWithStyle((style) => style?.vaultMenu);

const EditButton = styled(BaseButton)`
  ${withVaultMenuStyle((style) => ({
    backgroundColor: `${style?.editButton?.background} !important`,
  }))}

  border-radius: 100%;

  width: 28px;
  height: 28px;

  transform: scale(1);
  transform-origin: bottom center;
  transition: opacity ${animationCurve} 300ms, transform ${animationCurve} 300ms;

  &.PrimerCheckout--exit {
    opacity: 0;
  }

  &:hover {
    opacity: 0.7;
  }

  &.PrimerCheckout__editButton--toggle {
    transform: scale(1.2);
  }

  &.PrimerCheckout__editButton--hidden {
    opacity: 0;
    transform: translateX(20px);
    pointer-events: none;
  }

  & .PrimerCheckout__editButton__icon {
    width: 16px;
    height: 16px;
  }
`;

const EditButtonIcon = styled.svg`
  ${withVaultMenuStyle((style) => ({
    fill: style?.editButton?.color,
  }))}
`;

const VaultMenu = () => {
  const { viewUtils, className } = useCheckoutContext();
  const vault = useSelector((s) => s.vault);
  const labels = useSelector((s) => s.translation);

  const [isShowingMenu, setIsShowingMenu] = useState(false);
  const [confirmingItemId, setConfirmingItemId] = useState<string | null>(null);

  const focusTrap = useRef<FocusTrap | null>(null);
  const offClickHandler = useRef<OffClickHandler | null>(null);

  const previousIsShowingMenu = usePrevious(isShowingMenu);

  useFirstRender(() => {
    viewUtils.toggleVisibilityAnimated(ElementID.ACTIONS_MENU, false, {
      classPrefix: 'PrimerCheckout__dropDownMenu',
      duration: 0,
    });
  });

  useLayoutEffect(() => {
    if (previousIsShowingMenu === isShowingMenu) {
      return;
    }

    if (isShowingMenu) {
      viewUtils.toggleVisibilityAnimated(ElementID.ACTIONS_MENU, true, {
        classPrefix: 'PrimerCheckout__dropDownMenu',
        duration: 300,
      });

      offClickHandler.current = new OffClickHandler([
        ElementID.ACTIONS_MENU,
        ElementID.ACTIONS,
      ]);

      offClickHandler.current.listen(() => {
        setIsShowingMenu(false);
      });

      // Accessibility
      const menu = document.getElementById(
        ElementID.ACTIONS_MENU,
      ) as HTMLElement;

      focusTrap.current = createFocusTrap(menu, {
        onDeactivate: () => {
          setIsShowingMenu(false);
        },
        returnFocusOnDeactivate: true,
        clickOutsideDeactivates: false,
        allowOutsideClick: true,
      });
      focusTrap.current.activate();
    } else {
      viewUtils.toggleVisibilityAnimated(ElementID.ACTIONS_MENU, false, {
        classPrefix: 'PrimerCheckout__dropDownMenu',
        duration: 300,
      });

      offClickHandler.current?.clear();
      offClickHandler.current = null;

      focusTrap.current?.deactivate({ onDeactivate: undefined });
      focusTrap.current = null;
    }
  }, [isShowingMenu]);

  ///////////////////////////////////////////
  // Cb
  ///////////////////////////////////////////
  const handleVaultClick = () => {
    setIsShowingMenu(!isShowingMenu);
  };

  ///////////////////////////////////////////
  // Render
  ///////////////////////////////////////////
  return (
    <>
      <EditButton
        type='button'
        id='primer-checkout-actions'
        className={className('editButton', { toggle: isShowingMenu })}
        aria-label={labels?.editPaymentMethods}
        onClick={handleVaultClick}
      >
        <EditButtonIcon
          viewBox='0 0 14 15'
          xmlns='http://www.w3.org/2000/svg'
          className='PrimerCheckout__editButton__icon'
          id='primer-edit-button-icon'
          fill='none'
        >
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M10.1061 0.0975647C10.5644 0.0975647 11.0037 0.280557 11.3246 0.603957L13.4952 2.77457C13.8184 3.09774 13.9999 3.53606 13.9999 3.9931C13.9999 4.45013 13.8184 4.88845 13.4952 5.21162L5.57022 13.1341C5.08128 13.6981 4.3882 14.0446 3.5942 14.0991H0V13.3991L0.00227334 10.4486C0.0619064 9.71049 0.405064 9.02414 0.928541 8.5629L8.88671 0.604801C9.20941 0.280144 9.64828 0.0975647 10.1061 0.0975647ZM3.54482 12.7013C3.91877 12.6748 4.26687 12.5007 4.54638 12.181L9.83969 6.88771L7.21164 4.25956L1.88731 9.58261C1.60371 9.83344 1.42826 10.1844 1.40004 10.5054V12.6999L3.54482 12.7013ZM8.2017 3.26974L10.8296 5.89777L12.5053 4.22207C12.566 4.16145 12.6 4.07922 12.6 3.99349C12.6 3.90776 12.566 3.82553 12.5053 3.76491L10.3328 1.59235C10.2728 1.53193 10.1912 1.49795 10.1061 1.49795C10.021 1.49795 9.93944 1.53193 9.87949 1.59235L8.2017 3.26974Z'
          />
        </EditButtonIcon>
      </EditButton>

      <div className='PrimerCheckout__dropDownMenuContainer'>
        <dialog
          id='primer-checkout-actions-menu'
          className='PrimerCheckout__dropDownMenu'
          open={isShowingMenu}
          hidden={!isShowingMenu}
          aria-hidden={!isShowingMenu}
        >
          {vault.items.map((vaultedItem) => (
            <VaultMenuItem
              key={vaultedItem.id}
              vaultedItem={vaultedItem}
              isConfirming={vaultedItem.id === confirmingItemId}
              isDisabled={
                !!confirmingItemId && vaultedItem.id !== confirmingItemId
              }
              onDeleteClick={() => setConfirmingItemId(vaultedItem.id)}
              onCancelClick={() => setConfirmingItemId(null)}
              onConfirmClick={() => {
                setConfirmingItemId(null);
                setIsShowingMenu(false);
              }}
            />
          ))}
        </dialog>
      </div>
    </>
  );
};

export default VaultMenu;
