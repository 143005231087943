import { FunctionalComponent } from 'preact';
import { useLayoutEffect, useRef, useState } from 'preact/hooks';

import { useCheckoutContext } from '@primer-io/shared-library/contexts';
import { PaymentMethodType } from '../enums/Tokens';
import { BasePaymentMethod } from '../payment-methods/BasePaymentMethod';
import { useModule, useDisableComponent } from '../utils/hooks';
import { getPaymentMethodUrl } from '../utils/ModuleResolver';
import { SceneEnum } from '../enums/Checkout';
import { useWaitForComponent } from '../utils/SceneLoader/WaitForComponents';
import toCamelCase from '../utils/toCamelCase';
import BasePaymentMethodButton, {
  PaymentMethodButtonOptions,
} from '../payment-methods/Button';

type Props = {
  id: string;
  paymentMethod: BasePaymentMethod;
  onMounted?: () => void;
};

const ManagedPaymentMethodButton: FunctionalComponent<Props> = ({
  id,
  paymentMethod,
  onMounted,
}) => {
  const [mountError, setMountError] = useState(false);
  const rootRef = useRef<HTMLDivElement>();

  const { disableControls } = useDisableComponent({
    scene: SceneEnum.CHOOSE_PAYMENT_METHOD,
  });
  const disablePaymentMethod = (disabled) => {
    paymentMethod.setDisabled(disabled);
  };

  useLayoutEffect(() => {
    (async () => {
      try {
        const result = await paymentMethod.mount();
        if (result === false) {
          setMountError(true);
        }
      } catch (e) {
        setMountError(true);
      }
      onMounted?.();
      disablePaymentMethod(disableControls);
    })();
  }, []);

  useLayoutEffect(() => {
    disablePaymentMethod(disableControls);
  }, [disableControls, paymentMethod]);

  if (mountError) {
    return null;
  }

  return (
    <div
      ref={rootRef}
      id={`primer-checkout-apm-${toCamelCase(id)}`}
      className='PrimerCheckout__apmButton'
      tabIndex={-1}
    ></div>
  );
};

const ModuleMethodButton: FunctionalComponent<Props> = ({
  id,
  paymentMethod,
  onMounted,
}) => {
  const { context } = useCheckoutContext();
  const url = context
    ? getPaymentMethodUrl(context, id as PaymentMethodType)
    : undefined;

  const { Module } = useModule<
    FunctionalComponent<{
      paymentMethod: BasePaymentMethod;
      onMounted?: () => void;
    }>
  >({
    remotePath: url ?? '',
    path: 'PaymentMethodButton',
    scope: toCamelCase(paymentMethod.type),
  });

  if (Module) {
    if (!paymentMethod.specs.hasExportedButtonOptions) {
      return <Module paymentMethod={paymentMethod} onMounted={onMounted} />;
    }
    const buttonOptions = (Module as unknown) as PaymentMethodButtonOptions;
    return (
      <BasePaymentMethodButton
        {...buttonOptions}
        buttonId={`primer-checkout-apm-${toCamelCase(paymentMethod.type)}`}
        paymentMethod={paymentMethod}
        onMounted={onMounted}
      />
    );
  }

  return <div>Loading</div>;
};

const PaymentMethodButton: FunctionalComponent<Props> = ({
  id,
  paymentMethod,
}) => {
  const { wait, ready } = useWaitForComponent();

  useLayoutEffect(() => {
    wait(id);
  }, []);

  const handleMounted = () => {
    ready(id);
  };

  if (!paymentMethod.specs.buttonManagedByPaymentMethod) {
    return (
      <ModuleMethodButton
        id={id}
        paymentMethod={paymentMethod}
        onMounted={handleMounted}
      />
    );
  }

  return (
    <ManagedPaymentMethodButton
      id={id}
      paymentMethod={paymentMethod}
      onMounted={handleMounted}
    />
  );
};

export default PaymentMethodButton;
