import CheckoutStore from '../store/CheckoutStore';
import ClientConfigurationHandler from './ClientConfigurationHandler';
import { ClientConfiguration } from './ClientContextFactory';

export default class ClientSessionHandler {
  private clientConfigurationHandler: ClientConfigurationHandler;

  private store: CheckoutStore;

  private onClientConfigurationUpdated?: (
    clientConfiguration: ClientConfiguration,
  ) => void;

  constructor(
    clientConfigurationHandler: ClientConfigurationHandler,
    store: CheckoutStore,
  ) {
    this.clientConfigurationHandler = clientConfigurationHandler;
    this.store = store;
  }

  setOnClientConfigurationUpdated(
    onClientConfigurationUpdated: (
      clientConfiguration: ClientConfiguration,
    ) => void,
  ) {
    this.onClientConfigurationUpdated = onClientConfigurationUpdated;
  }

  public async updateClientConfigurationWithClientToken(
    clientToken: string,
  ): Promise<boolean> {
    try {
      const clientConfiguration = await this.clientConfigurationHandler.getClientConfiguration(
        clientToken,
      );
      if (!clientConfiguration.clientSession) {
        console.warn(
          'The provided client-token contains no information relating to the order. Please ensure that the correct information is provided when creating a client session. For more information regarding the creation of a client session, take a look at our API reference: https://primer.io/docs/api/#tag/Client-Session',
        );
        return false;
      }

      // Update client session
      this.setClientConfiguration(clientConfiguration);

      return true;
    } catch (e) {
      return false;
    }
  }

  public setClientConfiguration(clientConfiguration: ClientConfiguration) {
    this.store.setClientSession(clientConfiguration.clientSession);
    this.onClientConfigurationUpdated?.(clientConfiguration);
  }
}
