import { FunctionalComponent } from 'preact';
import {
  EffectCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'preact/hooks';
import {
  useSelector,
  useCheckoutContext,
} from '@primer-io/shared-library/contexts';
import { SceneEnum, SceneId } from '../enums/Checkout';
import { SceneStage } from '../store/BaseStore';
import { ModuleKeyDescriptor } from './ModuleFederation/types';
import { noop } from './noop';
import {
  PaymentMethodOption,
  CardNetworkOption,
} from '../models/ClientSession';
import { CardDetails, VaultListItem } from '../types';
import { snakeCaseToLowerCase } from './snakeCaseToLowerCase';
import { BasePaymentMethod } from '../payment-methods/BasePaymentMethod';
import { mapInstrumentTypeToPaymentMethodType } from './mapInstrumentTypeToPaymentMethodType';

export const usePrevious = (value) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useIsFirstRender = () => {
  const ref = useRef(true);
  useLayoutEffect(() => {
    ref.current = false;
  });
  return ref.current;
};

export const useFirstRender = (cb: () => void) => {
  const isFirstRender = useIsFirstRender();

  useLayoutEffect(() => {
    if (!isFirstRender) {
      return;
    }
    cb();
  }, [isFirstRender]);
};

export const useCurrentScene = () => {
  return useSelector((s) => {
    const currentScene = Object.entries(s.sceneStates).find(
      ([, sceneState]) => sceneState?.stage === SceneStage.Entered,
    )?.[0];
    return currentScene;
  });
};

export const useIsCurrentScene = (scene: SceneId) => {
  const currentScene = useCurrentScene();
  return currentScene === scene;
};

export const useSceneState = (scene: SceneId) => {
  return useSelector((s) => {
    const currentScene = Object.entries(s.sceneStates).find(
      ([key]) => key === scene,
    )?.[1];
    return currentScene;
  });
};

export const useScene = (
  cb: EffectCallback,
  scene: SceneId,
  deps: any[] = [],
) => {
  const currentScene = useCurrentScene();

  useLayoutEffect(() => {
    if (scene !== currentScene) return noop;

    return cb();
  }, [scene, currentScene, ...deps]);
};

export const useFirstScene = (cb: EffectCallback, scene: SceneId) => {
  const currentScene = useCurrentScene();
  const isFirstTime = useRef(true);

  useLayoutEffect(() => {
    if (!isFirstTime.current) return noop;
    if (scene !== currentScene) return noop;

    isFirstTime.current = false;

    return cb();
  }, [scene, currentScene]);
};

export const useFirstSceneEntering = (cb: EffectCallback, scene: SceneId) => {
  const sceneState = useSceneState(scene);
  const isFirst = useRef(false);

  useLayoutEffect(() => {
    if (isFirst.current || sceneState?.stage !== SceneStage.Entering) {
      return noop;
    }

    isFirst.current = true;
    return cb();
  }, [sceneState]);
};

export const useSceneInStage = (
  cb: EffectCallback,
  scene: SceneId,
  stage: SceneStage,
) => {
  const sceneState = useSceneState(scene);

  useLayoutEffect(() => {
    if (sceneState?.stage !== stage) {
      return noop;
    }

    return cb();
  }, [sceneState?.stage]);
};

export const useIsSceneInStage = (scene: SceneId, stage: SceneStage) => {
  const sceneState = useSceneState(scene);

  return sceneState?.stage === stage;
};

export const useIsSceneEntering = (scene: SceneId) =>
  useIsSceneInStage(scene, SceneStage.Entering);

export const useSceneEntering = (cb: EffectCallback, scene: SceneId) =>
  useSceneInStage(cb, scene, SceneStage.Entering);

export const useSceneEntered = (cb: EffectCallback, scene: SceneId) =>
  useSceneInStage(cb, scene, SceneStage.Entered);

export const useSceneExiting = (cb: EffectCallback, scene: SceneId) =>
  useSceneInStage(cb, scene, SceneStage.Exiting);

export const useSceneExited = (cb: EffectCallback, scene: SceneId) =>
  useSceneInStage(cb, scene, SceneStage.Exited);

export const useSceneMounting = (cb: EffectCallback, scene: SceneId) =>
  useSceneInStage(cb, scene, SceneStage.Mounting);

export const useModule = <T = FunctionalComponent>(
  key: ModuleKeyDescriptor,
) => {
  const { context } = useCheckoutContext();

  const [isLoading, setIsLoading] = useState(false);
  const [Module, setModule] = useState<{ comp: T | null }>({ comp: null });

  useLayoutEffect(() => {
    (async () => {
      const module = context?.moduleFactory.getModule(key);
      if (module) {
        setIsLoading(true);
        const LoadedModule = (await module.import()) as T;
        setModule({ comp: LoadedModule });
        setIsLoading(false);
      }
    })();
  }, []);

  return {
    Module: Module.comp,
    isLoading,
  };
};

export const useDisableComponent = ({
  scene,
}: {
  scene: SceneEnum | string;
}) => {
  const isLoading = useSelector((s) => s.isLoading);

  const sceneState = useSceneState(scene as SceneEnum);
  const stage = sceneState?.stage;

  const disableControls = isLoading;

  return {
    disableControls,
    disableNavigation:
      isLoading ||
      stage === SceneStage.Entering ||
      stage === SceneStage.Exiting,
  };
};

///////////////////////////////////////////
// Surcharging
///////////////////////////////////////////

export const useSurchargedVaultedPaymentMethods = (
  paymentMethodOptions: PaymentMethodOption[],
  vaultedPaymentMethods: VaultListItem[],
) => {
  const paymentMethodTypes = paymentMethodOptions
    .filter(
      (item: PaymentMethodOption) => item.surcharge && item.surcharge !== 0,
    )
    .map((item) => item.type);

  // Map out card networks with surcharge
  const cardOptions: CardNetworkOption[] =
    paymentMethodOptions
      .filter((item: PaymentMethodOption) => item.type === 'PAYMENT_CARD')
      .map((item: PaymentMethodOption) => item.networks)[0] ?? [];

  // Remove networks without surcharge
  const filteredCardOptions = cardOptions.filter(
    (network) => network.surcharge && network.surcharge !== 0,
  );

  const networkTypes = filteredCardOptions?.map((item) => item.type) ?? [];

  // Array of payment method types which includes surcharge
  const surchargedPaymentMethods = new Set([
    ...paymentMethodTypes,
    ...networkTypes,
  ]);

  // Updated payment method options
  // Includes network with surcharge on the same level
  paymentMethodOptions = [
    ...paymentMethodOptions,
    ...filteredCardOptions,
  ].filter((item) => item.type !== 'PAYMENT_CARD');

  const paymentMethodsWithSurchargeAmount: any[] = [];
  paymentMethodOptions.forEach((item: PaymentMethodOption) => {
    const { surcharge } = item;
    // Payment methods / networks to apply surcharge to
    const filteredVaultedPaymentMethods = vaultedPaymentMethods.filter(
      (vaultItem) => {
        const paymentMethodType =
          (vaultItem.details as CardDetails).network ??
          mapInstrumentTypeToPaymentMethodType(vaultItem.type);

        return (
          snakeCaseToLowerCase(paymentMethodType) ===
            snakeCaseToLowerCase(item.type) &&
          surchargedPaymentMethods.has(paymentMethodType.toUpperCase())
        );
      },
    );

    filteredVaultedPaymentMethods.forEach((vaultedPaymentMethod) => {
      if (vaultedPaymentMethod) {
        paymentMethodsWithSurchargeAmount.push({
          surchargeAmount: surcharge,
          vaultedPaymentMethod,
        });
      }
    });
  });

  const paymentMethodsWithoutSurcharge: VaultListItem[] = vaultedPaymentMethods.filter(
    (vaultItem: VaultListItem) => {
      const paymentMethodType =
        (vaultItem.details as CardDetails).network ??
        mapInstrumentTypeToPaymentMethodType(vaultItem.type);
      return !surchargedPaymentMethods.has(paymentMethodType.toUpperCase());
    },
  );

  return { paymentMethodsWithSurchargeAmount, paymentMethodsWithoutSurcharge };
};

export const useSurchargedPaymentMethods = (
  paymentMethodOptions: PaymentMethodOption[],
  paymentMethods: [string, BasePaymentMethod][],
) => {
  const surchargedPaymentMethods = new Set(
    paymentMethodOptions
      .filter(
        (item: PaymentMethodOption) => item.surcharge && item.surcharge !== 0,
      )
      .map((item) => item.type),
  );

  const paymentMethodsWithSurcharge = paymentMethodOptions.map(
    (item: PaymentMethodOption) => {
      const { surcharge } = item;
      const paymentMethod = paymentMethods.filter(([name]) => {
        return (
          snakeCaseToLowerCase(name) === snakeCaseToLowerCase(item.type) &&
          surchargedPaymentMethods.has(name)
        );
      });
      return {
        paymentMethod,
        surchargeAmount: surcharge,
      };
    },
  );

  const paymentMethodsWithoutSurcharge = paymentMethods.filter(([name]) => {
    return !surchargedPaymentMethods.has(name);
  });

  return { paymentMethodsWithSurcharge, paymentMethodsWithoutSurcharge };
};
