import {
  UniversalCheckoutOptions,
  SinglePaymentMethodCheckoutOptions,
} from '../types';

import { ClientContext } from '../core/ClientContextFactory';
import { DecodedClientToken } from '../core/ClientTokenHandler';
import { CheckoutUXFlow, SceneEnum } from '../enums/Checkout';
import CheckoutStore from '../store/CheckoutStore';
import { InternalTokenizationHandlers } from './CheckoutTokenizationHandlers';
import {
  ErrorCode,
  PrimerClientError,
  throwPrimerClientError,
} from '../errors';

type StartCheckoutContext = {
  context: ClientContext;
  store: CheckoutStore;
  tokenizationHandlers: InternalTokenizationHandlers;
  options: UniversalCheckoutOptions | SinglePaymentMethodCheckoutOptions;
};

export const FLOW_STARTERS: Record<
  string,
  (context: StartCheckoutContext) => void
> = {
  CHECKOUT: ({ store, options }) => {
    if (options.uxFlow === CheckoutUXFlow.SINGLE_PAYMENT_METHOD_CHECKOUT) {
      const paymentMethod = store.getPaymentMethodWithType(
        options.paymentMethod,
      );
      if (!paymentMethod) {
        throw PrimerClientError.fromErrorCode(
          ErrorCode.PAYMENT_METHOD_NOT_SETUP,
          {
            message: `Can't load ${options.paymentMethod}.`,
          },
        );
      }

      const paymentMethodHomeScene = paymentMethod.specs.homeScene;

      if (!paymentMethodHomeScene) {
        throwPrimerClientError(
          PrimerClientError.fromErrorCode(
            ErrorCode.PAYMENT_METHOD_NOT_COMPATIBLE,
            {
              message: `${options.paymentHandling} is not supported by single payment method flow. Could not initialize checkout.`,
            },
          ),
        );
      }

      store.setScene(paymentMethodHomeScene as string);
    } else {
      store.setScene(SceneEnum.CHOOSE_PAYMENT_METHOD);
    }

    store.setIsLoading(false);
  },

  REDIRECTION_END: ({ store, tokenizationHandlers, context }) => {
    const decodedClientToken = context.clientTokenHandler.getCurrentDecodedClientToken() as {
      resumeToken: string;
      paymentId: string;
    };

    tokenizationHandlers.onResumeSuccess(
      {
        resumeToken: decodedClientToken.resumeToken,
        paymentId: decodedClientToken.paymentId,
      },
      () => {
        // We don't want the error message to be hidden
        // when the scene is displayed
        store.setHideErrorMessageOnSceneEntered(false);
        store.setScene(SceneEnum.CHOOSE_PAYMENT_METHOD).then(() => {
          store.setHideErrorMessageOnSceneEntered(true);
        });

        store.setIsLoading(false);
      },
    );
  },
};

export const startCheckoutFlow = (
  startCheckoutContext: StartCheckoutContext,
) => {
  const decodedClientToken = startCheckoutContext.context.clientTokenHandler.getCurrentDecodedClientToken() as DecodedClientToken;

  let flowStarter;
  if (!decodedClientToken.intent) {
    flowStarter = FLOW_STARTERS.CHECKOUT;
  } else {
    flowStarter = FLOW_STARTERS[decodedClientToken.intent];
  }

  if (!flowStarter) {
    throwPrimerClientError(
      PrimerClientError.fromErrorCode(ErrorCode.INITIALIZATION_ERROR, {
        message:
          'Universal Checkout cannot be started with this type of client token.',
      }),
    );
  }

  flowStarter(startCheckoutContext);
};
