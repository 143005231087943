import CheckoutStore from '../store/CheckoutStore';
import {
  UniversalCheckoutOptions,
  SinglePaymentMethodCheckoutOptions,
  VaultManagerOptions,
} from '../types';
import { SubmitButtonCallbackHandler } from './SubmitButtonCallbackHandler';

interface SubmitButtonCallbacks {
  onVisible?: (isVisible: boolean, context: { currentSceneId: string }) => void;
  onContentChange?: (
    content: string,
    context: { currentSceneId: string },
  ) => void;
  onDisable?: (
    isDisabled: boolean,
    context: { currentSceneId: string },
  ) => void;
  onLoading?: (isLoading: boolean, context: { currentSceneId: string }) => void;
}

export const CallbackHandlers = {
  create(
    store: CheckoutStore,
    options:
      | UniversalCheckoutOptions
      | VaultManagerOptions
      | SinglePaymentMethodCheckoutOptions,
  ) {
    const createSubmitButtonCallbackHandler = async () => {
      const callbacks: SubmitButtonCallbacks = {
        onDisable: options.submitButton?.onDisable,
        onLoading: options.submitButton?.onLoading,
        onVisible: options.submitButton?.onVisible,
        onContentChange: options.submitButton?.onContentChange,
      };
      SubmitButtonCallbackHandler.create(store, callbacks);
    };

    // initialize all callback handlers relating to UI
    const createUiCallbackHandlers = () => {
      createSubmitButtonCallbackHandler();
    };

    return createUiCallbackHandlers();
  },
};
