import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { useCheckoutStore } from '@primer-io/shared-library/contexts';
import Label from '../../components/Label';
import FormInputGroups from './FormInputGroups';
import { IInputGroup } from '../types';

interface Props {
  formGroupLabel?: string;
  inputGroups?: IInputGroup[];
  onInputChange: (name: string, value: any) => void;
  onInputFocus: (name: string) => void;
  onInputBlur: (name: string) => void;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0;
`;

const StyledLabel = styled(Label)`
  margin-bottom: 8px;
  margin-left: 1px;
`;

const FormGroup: FunctionalComponent<Props> = ({
  formGroupLabel,
  inputGroups,
  onInputChange,
  onInputFocus,
  onInputBlur,
}) => {
  const store = useCheckoutStore();
  const labels = store.getTranslations();
  const label = labels?.[formGroupLabel ?? ''];

  return (
    <Root>
      <StyledLabel
        text={label}
        testId={`FormInputGroups/Label/${formGroupLabel}`}
      />
      {inputGroups && inputGroups?.length > 0 && (
        <FormInputGroups
          inputGroups={inputGroups}
          onInputChange={onInputChange}
          onInputFocus={onInputFocus}
          onInputBlur={onInputBlur}
        />
      )}
    </Root>
  );
};

export default FormGroup;
