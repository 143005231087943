import deepmerge from 'deepmerge';
import { Environment } from '../utils/Environment';
import { generateAndSaveSessionId } from '../utils/sessionStorage';
import { AnalyticsAction } from './models/AnalyticsAction';
import {
  IAnalyticsContext,
  IAnalyticsProvider,
} from './providers/AnalyticsProvider';
import { MixpanelProvider } from './providers/MixpanelProvider';
import { MockAnalyticsProvider } from './providers/MockAnalyticsProvider';

const sdkVersion = Environment.get<string>('PRIMER_SDK_VERSION');

export class Analytics {
  provider: IAnalyticsProvider;

  private sessionId: string;

  private context: IAnalyticsContext;

  private sdkEnvironment: string;

  private paymentIntent: string;

  constructor(provider: IAnalyticsProvider) {
    this.provider = provider;
  }

  /** track event and send to Primer. */
  call(action: AnalyticsAction): boolean | void {
    return this.provider.call(this.getAnalyticsAction(action));
  }

  /** start timing event that might happen in the future. */
  time(action: AnalyticsAction): boolean | void {
    return this.provider.time(this.getAnalyticsAction(action));
  }

  /** updates analytics about the context the checkout is in. */
  setContext(context: IAnalyticsContext): void {
    this.context = context;
  }

  setSdkEnvironment(env: string): void {
    this.sdkEnvironment = env;
  }

  setPaymentIntent(intent: string): void {
    this.paymentIntent = intent;
  }

  setSessionId(id: string): void {
    this.sessionId = id;
  }

  getAnalyticsAction(action: AnalyticsAction): AnalyticsAction {
    return deepmerge(action, {
      data: {
        ...this.context,
        sessionId: this.sessionId,
        sdkVersion,
        sdkEnvironment: this.sdkEnvironment,
        paymentIntent: this.paymentIntent,
      },
    });
  }
}

export function createAnalytics(url?: string): Analytics {
  const provider = url
    ? new MixpanelProvider(url)
    : new MockAnalyticsProvider();

  const sessionId = generateAndSaveSessionId();

  const analytics = new Analytics(provider);
  analytics.setSessionId(sessionId);

  return analytics;
}
