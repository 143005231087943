import { Alpha3CurrencyCode, SupportedLocale } from '../types';

export default function formatAmount(
  locale: SupportedLocale,
  amount: number,
  currencyCode: Alpha3CurrencyCode | string,
) {
  const formattedLocal = formatLocale(locale);
  return new Intl.NumberFormat(formattedLocal, {
    style: 'currency',
    currency: currencyCode,
  }).format(amount / 100);
}

function formatLocale(locale) {
  if (locale && locale.includes('_')) {
    return locale.split('_').join('-');
  }
  return locale;
}
