import { CheckoutStyle } from '../../../styles';

const primaryTextColor = '#242a2f';
const placeholderColor = 'rgba(30, 42, 47, 0.54)';
const primaryColor = '#3447f4';
const borderColor = '#e0e0e0';
const errorColor = '#e32f41';
const disabledColor = '#a7aaac';
const hoverBgColor = '#eaf1ff';
const fgColorRed = '#ea4335';

export const defaultCheckoutStyle: CheckoutStyle = {
  loadingScreen: {
    color: primaryColor,
  },

  input: {
    base: {
      height: 36,
      color: primaryTextColor,
      background: '#f8f8f8',
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderWidth: 1,
      borderRadius: '3px',
      fontFamily: 'sans-serif',
      fontWeight: '400',
      fontSize: '16px',
      paddingHorizontal: 12,

      placeholder: {
        color: placeholderColor,
      },
      focus: {
        borderColor: '#d3d4d5',
        borderWidth: 1,
        background: 'white',
      },
    },
    error: {
      borderColor: errorColor,
      background: 'white',

      focus: {
        borderColor: errorColor,
        background: 'white',
      },
    },
  },

  inputLabel: {
    fontSize: '0.8rem',
    fontWeight: '400',
    lineHeight: '0.8rem',
    color: primaryTextColor,
  },

  inputErrorText: {
    fontSize: '0.8rem',

    color: errorColor,
    fontWeight: '400',
    textAlign: 'left',
  },

  submitButton: {
    base: {
      background: '#242a2f',
      borderRadius: 5,
      boxShadow: 'none',

      color: 'white',
      fontSize: '1.1rem',
    },

    disabled: {
      background: disabledColor,
    },

    loading: {
      background: disabledColor,
      boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
    },
  },

  showMorePaymentMethodsButton: {
    base: {
      fontSize: '16px',
      color: primaryColor,
    },
    disabled: {
      color: disabledColor,
    },
  },

  backButton: {
    color: primaryTextColor,
  },

  savedPaymentMethodButton: {
    base: {
      background: 'white',
      borderRadius: 2,
      boxShadow: '0px 0px rgba(0, 0, 0, 0)',
      borderColor,
      borderWidth: 1,
      borderStyle: 'solid',

      hover: {
        background: hoverBgColor,
      },

      focus: {
        background: hoverBgColor,
      },
    },

    selected: {
      color: primaryColor,
      background: 'white',
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.14)',
      borderColor: primaryColor,

      hover: {
        background: 'white',
      },

      focus: {
        background: hoverBgColor,
      },
    },
  },

  formSpacings: {
    betweenInputs: '28px',
    betweenLabelAndInput: '8px',
  },

  vaultMenu: {
    editButton: {
      background: '#f2f2f2',
      color: '#8C8C8C',
    },
    item: {
      actionButton: {
        color: fgColorRed,
      },
    },
  },

  errorMessage: {
    background: '#fce8e6',
    color: fgColorRed,
    borderRadius: 2,
  },

  smallPrint: {
    color: '#8C8C8C',
    fontSize: '0.8rem',
    fontWeight: '400',
  },

  separator: {
    color: borderColor,
  },

  paymentMethodButton: {
    height: 40,
    borderRadius: 2,
    borderColor,
  },

  processingIndicator: {
    color: '#aaaaaa',
  },
};
