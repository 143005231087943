import { defaultFieldMetadata } from '../../utils/field-metadata';
import { funcify } from '../../utils/funcify';
import { HTMLElementEventType } from '../../enums/HTMLElementEventType';
import { noop } from '../../utils/noop';
import { nextTick } from '../../utils/nextTick';
import { locateElement } from '../../utils/dom';
import { addEventListener } from '../../utils/addEventListener';
import { iosInputBlurFix } from '../../utils/iosInputBlurFix';
import { BasePaymentMethod, PaymentMethodSpecs } from '../BasePaymentMethod';
import { IFrameEventType } from '../../core/IFrameEventType';
import { ErrorCode, PrimerClientError } from '../../errors';
import { errorFromAPIResponse } from './errorFromAPIResponse';
import { setElementDisabled } from '../../utils/disableElement';
import { IPaymentMethodContext } from '../../core/PaymentMethodContext';
import { APIResponse } from '../../core/Api';
import {
  InputMetadata,
  InputValidationError,
  PaymentMethodToken,
  Validation,
} from '../../types';
import { IFrameMessagePayload } from '../../core/IFrameMessage';
import { CardMetadata } from '../../hosted-scripts/CardMetadata';
import {
  CardFieldRef,
  CreditCardFieldName,
  CreditCardFieldOptions,
  CreditCardOptions,
  CreditCardOptionsIn,
  FormMeta,
} from './types';
import { PaymentMethodType } from '../../enums/Tokens';
import { ApiEvent } from '../../analytics/constants/enums';
import createCreditCardStore, { CreditCardStore } from './CreditCardStore';
import { SavePaymentMethodStore } from '../../checkout-modules/save-payment-method';

export class CreditCard extends BasePaymentMethod {
  static specs: PaymentMethodSpecs = {
    key: 'card',
    canVault: true,
    buttonManagedByPaymentMethod: false,
    hasExportedButtonOptions: false,
  };

  public static create = (
    context: IPaymentMethodContext,
    options: CreditCardOptionsIn,
  ) => new CreditCard(context, options);

  private context: IPaymentMethodContext;

  private options: CreditCardOptions;

  private fields: Record<CreditCardFieldName, Nullable<CardFieldRef>>;

  private isSubmitted: boolean;

  private store: CreditCardStore;

  private savePaymentMethod: SavePaymentMethodStore | undefined;

  constructor(context: IPaymentMethodContext, opts: CreditCardOptionsIn) {
    super(PaymentMethodType.PAYMENT_CARD, 'Card');
    const options = normalizeOptions(opts);

    this.context = context;
    this.options = options;
    this.fields = {
      cardNumber: null,
      cvv: null,
      expiryDate: null,
    };

    this.isSubmitted = false;
    this.store = createCreditCardStore();
  }

  public getStore() {
    return this.store;
  }

  async createField(
    name: CreditCardFieldName,
    config: CreditCardFieldOptions,
  ): Promise<void> {
    if (config == null) {
      return;
    }

    const normalized = name.toLowerCase();

    const field: CardFieldRef = {
      name,
      meta: { ...defaultFieldMetadata },
      onChange: (data) => {
        const { meta } = data;
        config.onChange?.(data);

        const hasError =
          Boolean(meta.submitted && meta.error) ||
          meta.errorCode === 'unsupportedCardType';

        if (name === 'cardNumber') {
          this.getStore().setNumberFieldError(hasError ? meta.error : null);
          this.getStore().setNumberFieldFocused(meta.active);
        } else if (name === 'cvv') {
          this.getStore().setCvvFieldError(hasError ? meta.error : null);
          this.getStore().setCvvFieldFocused(meta.active);
        } else if (name === 'expiryDate') {
          this.getStore().setExpiryDateFieldError(hasError ? meta.error : null);
          this.getStore().setExpiryDateFieldFocused(meta.active);
        }
      },
      frame: null,
    };

    this.fields[name] = field;

    const createIFrameOptions = {
      filename: 'hosted-input.html',
      container: config.container,
      placement: config.placement || 'append',
      meta: {
        id: `primer-${normalized}-field`,
        name,
        placeholder: config.placeholder,
        css: this.options.css,
        stylesheets: this.options.stylesheets,
        ariaLabel: config.ariaLabel,
        allowedCardNetworks: this.context.allowedCardNetworks,
      },
    };

    await new Promise<void>((resolve) => {
      field.frame = this.context.iframes.create({
        ...createIFrameOptions,
        onReady: resolve,
      });
    });
  }

  async mount(): Promise<boolean> {
    const { submitButton, fields } = this.options;
    const button = locateElement(submitButton);

    if (button) {
      addEventListener(
        button as HTMLElement,
        HTMLElementEventType.CLICK,
        async () => {
          this.tokenize();
        },
      );
    }

    this.context.messageBus.on(
      IFrameEventType.INPUT_METADATA,
      (e: IFrameMessagePayload<InputMetadata>) => {
        const { source } = e.meta;
        const field = this.fields[source];

        field.meta = e.payload;

        this.updateFields([field]);
      },
    );

    this.context.messageBus.on(
      IFrameEventType.CARD_METADATA,
      (e: IFrameMessagePayload<CardMetadata>) => {
        this.options.onCardMetadata(e.payload);

        this.getStore().setMetadata(e.payload);
      },
    );

    this.context.messageBus.on(IFrameEventType.IOS_BLUR_FIX, (e) => {
      const { source } = e.meta;
      const field = this.fields[source];

      if (field == null) {
        return;
      }

      iosInputBlurFix(field.frame);
    });

    await Promise.all([
      nextTick(() => this.createField('cardNumber', fields.cardNumber)),
      nextTick(() => this.createField('cvv', fields.cvv)),
      nextTick(() => this.createField('expiryDate', fields.expiryDate)),
    ]);

    return true;
  }

  reset() {
    this.isSubmitted = false;

    // Reset hosted fields
    Object.keys(this.fields).forEach((name) => {
      this.context.messageBus.publish(name, {
        type: IFrameEventType.RESET_FIELD,
      });
    });

    // Reset name input
    const nameInput = document.getElementById(
      'primer-checkout-cardholder-name-input',
    ) as HTMLInputElement;
    if (nameInput) {
      nameInput.value = '';
    }
  }

  async tokenize() {
    this.context.progress.start();

    this.context.analytics.call({ event: ApiEvent.tokenizeCalled });

    if (!this.context.store.getIsTokenizationEnabled()) {
      this.context.progress.didNotStart('TOKENIZATION_DISABLED');
      return;
    }

    const shouldStart = await this.context.progress.shouldStart();
    if (shouldStart === false) {
      this.context.progress.didNotStart('TOKENIZATION_SHOULD_NOT_START');
      return;
    }

    this.isSubmitted = true;

    const validation = await this.internalValidate();

    this.updateFields();

    if (this.options.disabled()) {
      this.context.progress.error('Disabled');
      return;
    }

    if (!validation.valid) {
      this.context.progress.error(
        PrimerClientError.fromErrorCode(ErrorCode.TOKENIZATION_ERROR, {
          message: 'Form is invalid',
          data: validation,
        }),
      );
      return;
    }

    const vault = this.options.vault();
    const cardholderName = this.options.cardholderName();

    const savePaymentMethod = this.context.store.getCheckoutModuleWithType(
      'SAVE_PAYMENT_METHOD',
    ) as SavePaymentMethodStore;

    const userDescription = savePaymentMethod?.userDescription;

    const paymentInstrument: Record<string, string> = {
      number: '$.cardNumber',
      cvv: '$.cvv',
      expirationMonth: '$.expiryDate.month',
      expirationYear: '$.expiryDate.year',
    };

    if (cardholderName) {
      paymentInstrument.cardholderName = cardholderName;
    }

    const body: Record<string, unknown> = {
      paymentInstrument,
      userDescription,
    };

    if (vault) {
      body.tokenType = 'MULTI_USE';
      body.paymentFlow = 'VAULT';
    }

    try {
      const response = await this.context.tokenizePaymentMethod(body);

      this.handleTokenizationResponse(response);
      this.updateFields();
    } catch (e) {
      this.context.progress.error(
        PrimerClientError.fromErrorCode(ErrorCode.TOKENIZATION_ERROR, {
          message: 'Call to /payment-instruments failed',
        }),
      );
    }
  }

  handleTokenizationResponse(response: APIResponse<PaymentMethodToken>): void {
    const { data, error } = response;

    if (error || !data) {
      const err = errorFromAPIResponse(error);

      this.context.progress.error(err);

      if (err.code === ErrorCode.CARD_NUMBER_ERROR) {
        this.context.messageBus.publish('cardNumber', {
          type: IFrameEventType.UPDATE_METADATA,
          payload: {
            error: 'invalid',
            valid: false,
          },
        });
      }

      return;
    }

    this.context.progress.success(data);
  }

  async validate(): Promise<Validation> {
    const validationResult = await this.internalValidate();

    if (validationResult.valid) {
      this.context.analytics.call({
        event: ApiEvent.creditCardValidationSuccess,
      });
    } else {
      const data = validationResult.validationErrors.reduce((acc, cur) => {
        acc[cur.name] = cur.error.toString();
        return acc;
      }, {});

      this.context.analytics.call({
        event: ApiEvent.creditCardValidationError,
        data,
      });
    }

    return validationResult;
  }

  internalValidate(): Promise<Validation> {
    this.isSubmitted = true;

    this.updateFields();

    const validationErrors = this.getValidationErrors();

    return Promise.resolve({
      valid: validationErrors.length === 0,
      validationErrors,
    });
  }

  async setDisabled(disabled: boolean): Promise<void> {
    this.eachField((field) => {
      setElementDisabled(field.frame, disabled);
      this.context.messageBus.publish(field.name, {
        type: IFrameEventType.SET_DISABLED,
        payload: {
          disabled,
        },
      });
    });

    return Promise.resolve();
  }

  resetFormSubmission() {
    this.isSubmitted = false;
    this.updateFields();
  }

  private eachField(iterator: (field: CardFieldRef) => void): void {
    Object.values(this.fields).forEach((field) => {
      if (field !== null) {
        iterator(field);
      }
    });
  }

  private getValidationErrors(): InputValidationError[] {
    const errors: InputValidationError[] = [];

    this.eachField((field) => {
      if (field.meta.error) {
        errors.push({
          name: field.name,
          error: field.meta.error,
          message: this.context.translations[field.meta.error],
        });
      }
    });

    return errors;
  }

  private updateFields(fields?: CardFieldRef[]) {
    const targets = fields || Object.values(this.fields);
    const formState = this.deriveFormState();

    this.options.onChange(formState);

    targets.forEach((field) => {
      if (!field) {
        return;
      }

      if (!field.meta.submitted && this.isSubmitted) {
        this.context.messageBus.publish(field.name, {
          type: IFrameEventType.SET_SUBMITTED,
          payload: true,
        });
      }

      const meta = { ...field.meta, submitted: this.isSubmitted };

      meta.errorCode = meta.error;
      meta.error = meta.error ? this.context.translations[meta.error] : null;

      field.onChange({ meta });
    });
  }

  private deriveFormState(): FormMeta {
    const allFields = Object.values(this.fields);

    const state = allFields.reduce((acc, elm) => {
      if (elm == null) {
        return acc;
      }

      return {
        dirty: some(acc as InputMetadata, elm.meta, 'dirty'),
        touched: some(acc as InputMetadata, elm.meta, 'touched'),
        active: some(acc as InputMetadata, elm.meta, 'active'),
        valid: every(acc as InputMetadata, elm.meta, 'valid'),
      };
    }, {});

    const derivedState = state as Pick<
      InputMetadata,
      'dirty' | 'touched' | 'active' | 'valid'
    >;

    return { ...derivedState, submitted: this.isSubmitted };
  }
}

/**
 *
 * @param {Primer.CreditCardConfig} opts
 */
function normalizeOptions(opts: CreditCardOptionsIn): CreditCardOptions {
  return {
    ...opts,
    cardholderName: funcify(opts.cardholderName),
    vault: funcify(opts.vault ?? false),
    onChange: opts.onChange || noop,
    onCardMetadata: opts.onCardMetadata || noop,
    disabled: opts.disabled || funcify(false),
  };
}

function some(
  a: InputMetadata,
  b: InputMetadata,
  name: 'dirty' | 'touched' | 'active' | 'valid',
): boolean {
  return a[name] || b[name];
}

function every(
  a: InputMetadata,
  b: InputMetadata,
  name: 'dirty' | 'touched' | 'active' | 'valid',
): boolean {
  return a[name] && b[name];
}
