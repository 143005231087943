import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import {
  useCheckoutContext,
  useCheckoutStore,
  useSelector,
} from '@primer-io/shared-library/contexts';
import { useRef } from 'preact/hooks';
import { BackButton } from '@primer-io/shared-library/components';
import Dim from '../components/Dim';
import { CheckoutUXFlow } from '../enums/Checkout';
import { getFirstFocusableChild } from '../checkout/focus';
import { useScene, useSceneEntering } from '../utils/hooks';

const Icon = styled.img`
  position: absolute;

  left: 50%;

  width: 60px;
  height: 24px;
  object-fit: contain;

  transform: translateX(-50%);
`;

const Header = styled.div`
  position: relative;
`;

type SceneProps = {
  previousScene?: string;
  backButton: boolean;
  dimmable: boolean;
  scene: string;
  sceneRootId: string;

  iconSrc?: string;
  iconAlt?: string;
};

const Scene: FunctionalComponent<SceneProps> = ({
  children,
  previousScene,
  backButton,
  dimmable,
  scene,
  sceneRootId,

  iconSrc,
  iconAlt,
}) => {
  const store = useCheckoutStore();
  const { options, clientSessionActionService } = useCheckoutContext();
  const sceneRef = useRef<HTMLDivElement>();

  const isLoading = useSelector((s) => s.isLoading);
  const isProcessing = useSelector((s) => s.isProcessing);

  useSceneEntering(() => {
    store.selectVault(null);
  }, scene);

  useScene(() => {
    setTimeout(() => {
      const el = getFirstFocusableChild(sceneRef.current);
      el?.focus();
    }, 100);
  }, scene);

  const handleBackClick = () => {
    if (!previousScene) return;

    store.setScene(previousScene, 'pop');
    if (
      previousScene === 'UniversalCheckout/Home' &&
      store.hasSelectedPaymentMethod
    ) {
      clientSessionActionService.unselectPaymentMethod();
    }
  };

  const renderScene = () => {
    return (
      <div id='primer-checkout-payment-method-scene'>
        <Header className='PrimerCheckout__formHeader'>
          {options.uxFlow !== CheckoutUXFlow.SINGLE_PAYMENT_METHOD_CHECKOUT &&
            backButton && (
              <BackButton
                disabled={isLoading || isProcessing}
                onClick={handleBackClick}
              />
            )}

          {iconSrc && <Icon src={iconSrc} alt={iconAlt} />}

          <div style={{ flex: 1 }} />
        </Header>
        {children}
      </div>
    );
  };

  return (
    <div
      ref={sceneRef}
      id={sceneRootId}
      className='PrimerCheckout__scene PrimerCheckout--exited'
    >
      {dimmable ? <Dim dim={isLoading}>{renderScene()}</Dim> : renderScene()}
    </div>
  );
};

export default Scene;
