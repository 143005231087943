import { base64 } from '../../utils/base64';
import { parseAmountValue } from '../../utils/parseAmountValue';
import window from '../../utils/window';
import { AuthenticationInput, BrowserInfo, ChallengeOptions } from './types';

const CHALLENGE_IFRAME_ID = 'primer-3ds-challenge';

interface IFrameAndForm {
  iframe: HTMLIFrameElement;
  form: HTMLFormElement;
}

export function getTimezoneOffset(): number {
  return new Date().getTimezoneOffset();
}

export function createChallengeIFrameFormV1(
  options: ChallengeOptions,
): IFrameAndForm {
  return createChallengeForm(options.acsChallengeUrl, createInputsV1(options));
}

export function createChallengeIFrameFormV2(
  options: ChallengeOptions,
): IFrameAndForm {
  return createChallengeForm(options.acsChallengeUrl, createInputsV2(options));
}

function createChallengeForm(url: string, inputs: HTMLInputElement[]) {
  const iframe = window.document.createElement('iframe');
  iframe.id = CHALLENGE_IFRAME_ID;
  iframe.name = CHALLENGE_IFRAME_ID;

  iframe.width = '100%';
  iframe.height = '100%';
  iframe.setAttribute('width', '100%');
  iframe.setAttribute('height', '100%');
  iframe.setAttribute('frameBorder', '0');
  iframe.setAttribute('border', '0');

  const form = window.document.createElement('form');

  form.style.width = '1px';
  form.style.height = '1px';
  form.style.position = 'absolute';
  form.style.left = '-10000px';

  form.action = url;
  form.target = CHALLENGE_IFRAME_ID;
  form.method = 'post';

  inputs.forEach((input) => form.appendChild(input));

  return { iframe, form };
}

function createInputsV1(options: ChallengeOptions): HTMLInputElement[] {
  const pareq = window.document.createElement('input');
  const termUrl = window.document.createElement('input');

  pareq.type = 'hidden';
  pareq.name = 'PaReq';
  pareq.id = 'PaReq';
  pareq.value = options.acsChallengeData ?? '';

  termUrl.type = 'hidden';
  termUrl.name = 'TermUrl';
  termUrl.id = 'TermUrl';
  termUrl.value = options.notificationUrl ?? '';

  return [pareq, termUrl];
}

function createInputsV2(options: ChallengeOptions): HTMLInputElement[] {
  const creq = window.document.createElement('input');
  const data = window.document.createElement('input');

  creq.type = 'hidden';
  creq.name = 'creq';
  creq.id = 'creq';
  creq.value = base64.encode(
    JSON.stringify({
      threeDSServerTransID: options.transactionId,
      acsTransID: options.acsTransactionId,
      messageVersion: options.protocolVersion,
      messageType: 'CReq',
      challengeWindowSize: '01',
    }),
    { urlSafe: true },
  );

  data.type = 'hidden';
  data.name = 'threeDSSessionData';
  data.id = 'threeDSSessionData';

  return [creq, data];
}

export function formatAuthenticationData(
  input: AuthenticationInput,
  fingerprint: BrowserInfo,
) {
  if (!input.order) {
    return {
      testScenario: input.testScenario,
      device: fingerprint,
    };
  }

  const { firstName, lastName } = input.order?.billingAddress ?? {};
  const { currency, value = 0 } = input.order?.amount ?? {};

  const name = `${firstName ?? ''} ${lastName ?? ''}`.trim();

  return {
    testScenario: input.testScenario,
    orderId: input.order.orderId,
    amount: parseAmountValue(value).asNumber(),
    currencyCode: currency,
    customer: {
      name,
      email: input.order.email,
    },
    billingAddress: input.order.billingAddress,
    device: fingerprint,
  };
}
