import {
  CheckoutModuleConfig,
  ClientContext,
} from '../core/ClientContextFactory';
import CheckoutStore from '../store/CheckoutStore';
import {
  UniversalCheckoutOptions,
  SinglePaymentMethodCheckoutOptions,
} from '../types';

import createCardInformationStore, {
  CardInformationStore,
} from './card-information';
import createBillingAddressStore, {
  BillingAddressStore,
} from './billing-address';
import { ICheckoutModuleServices } from './types';
import createSavePaymentMethodStore, {
  SavePaymentMethodStore,
} from './save-payment-method';

interface ICreateCheckoutModuleServices extends ICheckoutModuleServices {
  context: ClientContext;
  store: CheckoutStore;
  options: UniversalCheckoutOptions | SinglePaymentMethodCheckoutOptions;
}

const CHECKOUT_MODULE_CREATORS = {
  [CardInformationStore.type]: () => createCardInformationStore(),
  [BillingAddressStore.type]: (_config, services) =>
    createBillingAddressStore(services),
  [SavePaymentMethodStore.type]: () => createSavePaymentMethodStore(),
};

const CHECKOUT_MODULE_UPDATERS = {
  [CardInformationStore.type]: (
    checkoutModule: CardInformationStore,
    config: CheckoutModuleConfig,
  ) => {
    checkoutModule.showCardholderName(config.options?.cardHolderName);
  },

  [BillingAddressStore.type]: (
    checkoutModule: BillingAddressStore,
    config: CheckoutModuleConfig,
  ) => {
    checkoutModule.setFields(config.options ?? {});
  },

  [SavePaymentMethodStore.type]: (
    checkoutModule: SavePaymentMethodStore,
    config: CheckoutModuleConfig,
  ) => {
    checkoutModule.showUserDescription(config.options?.userDescription);
  },
};

const updateCheckoutModule = (
  checkoutModule: any,
  config: CheckoutModuleConfig,
) => CHECKOUT_MODULE_UPDATERS[config?.type]?.(checkoutModule, config);

const createCheckoutModule = (
  config: CheckoutModuleConfig,
  services: ICreateCheckoutModuleServices,
) => {
  const checkoutModule = CHECKOUT_MODULE_CREATORS[config?.type]?.(
    config,
    services,
  );
  checkoutModule && updateCheckoutModule(checkoutModule, config);

  return checkoutModule;
};

export const CheckoutModules = {
  create: createCheckoutModule,
  update: updateCheckoutModule,
};
