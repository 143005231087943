export enum PaymentInstrumentType {
  PRIMER_PAYPAL = 'PRIMER_PAYPAL',
  NETS = 'NETS',
  STRIPE_GIROPAY = 'STRIPE_GIROPAY',
  MOLLIE_GIROPAY = 'MOLLIE_GIROPAY',
  MOLLIE_EPS = 'MOLLIE_EPS',
  PAY_NL_EPS = 'PAY_NL_EPS',
  PAY_NL_P24 = 'PAY_NL_P24',
  MOLLIE_P24 = 'MOLLIE_P24',
  MOLLIE_SOFORT = 'MOLLIE_SOFORT',
  COINBASE = 'COINBASE',
  OPENNODE = 'OPENNODE',
  MOLLIE_GIFT_CARD = 'MOLLIE_GIFTCARD',
  XFERS_PAYNOW = 'XFERS_PAYNOW',
  CARD = 'PAYMENT_CARD',
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
  PAYPAL = 'PAYPAL_ORDER',
  PAYPAL_VAULTED = 'PAYPAL_BILLING_AGREEMENT',
  GO_CARDLESS = 'GOCARDLESS',
  PAY_NL_IDEAL = 'PAY_NL_IDEAL',
  PAY_NL_SOFORT_BANKING = 'PAY_NL_SOFORT_BANKING',
  PAY_NL_BANCONTACT = 'PAY_NL_BANCONTACT',
  PAY_NL_PAYPAL = 'PAY_NL_PAYPAL',
  PAY_NL_CREDIT_TRANSFER = 'PAY_NL_CREDIT_TRANSFER',
  PAY_NL_DIRECT_DEBIT = 'PAY_NL_DIRECT_DEBIT',
  PAY_NL_GIROPAY = 'PAY_NL_GIROPAY',
  PAY_NL_PAYCONIQ = 'PAY_NL_PAYCONIQ',
  HOOLAH = 'HOOLAH',
  ADYEN_BLIK = 'ADYEN_BLIK',
  ADYEN_VIPPS = 'ADYEN_VIPPS',
  ADYEN_GIROPAY = 'ADYEN_GIROPAY',
  ADYEN_SOFORT = 'ADYEN_SOFORT',
  ADYEN_TRUSTLY = 'ADYEN_TRUSTLY',
  ADYEN_ALIPAY = 'ADYEN_ALIPAY',
  ADYEN_TWINT = 'ADYEN_TWINT',
  ADYEN_MOBILEPAY = 'ADYEN_MOBILEPAY',
  MOLLIE_BANCONTACT = 'MOLLIE_BANCONTACT',
  MOLLIE_IDEAL = 'MOLLIE_IDEAL',
  BUCKAROO_GIROPAY = 'BUCKAROO_GIROPAY',
  BUCKAROO_EPS = 'BUCKAROO_EPS',
  BUCKAROO_SOFORT = 'BUCKAROO_SOFORT',
  BUCKAROO_BANCONTACT = 'BUCKAROO_BANCONTACT',
  BUCKAROO_IDEAL = 'BUCKAROO_IDEAL',
  ATOME = 'ATOME',
}

export enum PaymentMethodType {
  PRIMER_PAYPAL = 'PRIMER_PAYPAL',
  NETS = 'NETS',
  STRIPE_GIROPAY = 'STRIPE_GIROPAY',
  MOLLIE_GIROPAY = 'MOLLIE_GIROPAY',
  MOLLIE_EPS = 'MOLLIE_EPS',
  PAY_NL_EPS = 'PAY_NL_EPS',
  PAY_NL_P24 = 'PAY_NL_P24',
  MOLLIE_P24 = 'MOLLIE_P24',
  MOLLIE_SOFORT = 'MOLLIE_SOFORT',
  COINBASE = 'COINBASE',
  OPENNODE = 'OPENNODE',
  MOLLIE_GIFT_CARD = 'MOLLIE_GIFTCARD',
  XFERS_PAYNOW = 'XFERS_PAYNOW',
  PAYMENT_CARD = 'PAYMENT_CARD',
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
  PAYPAL = 'PAYPAL',
  GO_CARDLESS = 'GOCARDLESS',
  KLARNA = 'KLARNA',
  PAY_NL_IDEAL = 'PAY_NL_IDEAL',
  PAY_NL_SOFORT_BANKING = 'PAY_NL_SOFORT_BANKING',
  PAY_NL_BANCONTACT = 'PAY_NL_BANCONTACT',
  PAY_NL_PAYPAL = 'PAY_NL_PAYPAL',
  PAY_NL_CREDIT_TRANSFER = 'PAY_NL_CREDIT_TRANSFER',
  PAY_NL_DIRECT_DEBIT = 'PAY_NL_DIRECT_DEBIT',
  PAY_NL_GIROPAY = 'PAY_NL_GIROPAY',
  PAY_NL_PAYCONIQ = 'PAY_NL_PAYCONIQ',
  HOOLAH = 'HOOLAH',
  ADYEN_BLIK = 'ADYEN_BLIK',
  ADYEN_MOBILEPAY = 'ADYEN_MOBILEPAY',
  ADYEN_VIPPS = 'ADYEN_VIPPS',
  ADYEN_GIROPAY = 'ADYEN_GIROPAY',
  ADYEN_SOFORT = 'ADYEN_SOFORT',
  ADYEN_TRUSTLY = 'ADYEN_TRUSTLY',
  ADYEN_ALIPAY = 'ADYEN_ALIPAY',
  ADYEN_TWINT = 'ADYEN_TWINT',
  ADYEN_BANK_TRANSFER = 'ADYEN_BANK_TRANSFER',
  MOLLIE_BANCONTACT = 'MOLLIE_BANCONTACT',
  MOLLIE_IDEAL = 'MOLLIE_IDEAL',
  BUCKAROO_GIROPAY = 'BUCKAROO_GIROPAY',
  BUCKAROO_EPS = 'BUCKAROO_EPS',
  BUCKAROO_SOFORT = 'BUCKAROO_SOFORT',
  BUCKAROO_BANCONTACT = 'BUCKAROO_BANCONTACT',
  BUCKAROO_IDEAL = 'BUCKAROO_IDEAL',
  ATOME = 'ATOME',
}

export enum TokenType {
  SINGLE_USE = 'SINGLE_USE',
  MULTI_USE = 'MULTI_USE',
}

const PAYMENT_INSTRUMENT_TYPE_TO_PAYMENT_METHOD_TYPE = {
  [PaymentInstrumentType.PAYPAL_VAULTED]: PaymentMethodType.PAYPAL,
};

export const paymentInstrumentTypeToPaymentMethodType = (
  paymentInstrumentType: PaymentInstrumentType,
): PaymentMethodType | undefined => {
  if (
    (Object.values(PaymentMethodType) as string[]).includes(
      paymentInstrumentType as string,
    )
  ) {
    return (paymentInstrumentType as unknown) as PaymentMethodType;
  }

  if (PAYMENT_INSTRUMENT_TYPE_TO_PAYMENT_METHOD_TYPE[paymentInstrumentType]) {
    return PAYMENT_INSTRUMENT_TYPE_TO_PAYMENT_METHOD_TYPE[
      paymentInstrumentType
    ];
  }

  return undefined;
};
