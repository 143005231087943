import { base64 } from '../../utils/base64';
import {
  ThreeDSAuthPayload,
  ThreeDSChallengeAuthentication,
  ThreeDSDeclinedAuthentication,
  ThreeDSMethodAuthentication,
  ThreeDSSkippedAuthentication,
  ThreeDSSuccessAuthentication,
} from '../types';
import { LongPoll } from '../../core/LongPoll';

const METHOD_BOX_ID = 'primer-3ds-method-box';
const IFRAME_ID = 'primer-3ds-method-iframe';
const FORM_ID = 'primer-3ds-method-form';
const INPUT_ID = 'threeDSMethodData';

/**
 * The guidance for method timeouts is to give up after 10 seconds.
 * Sometimes the ACS will attempt after this soft limit so we give them a bit of leway here
 */

// Poll every 1 second
const POLL_INTERVAL = 1000;

// Up to 13 seconds
const METHOD_TIMEOUT = 13 * 1000;

interface Invoke3DSMethodOptions {
  transactionId: string;
  statusUrl: string;
  acsMethodUrl: string;
  notificationUrl: string;
  accessToken: string;
}

type MethodAuthResult =
  | ThreeDSChallengeAuthentication
  | ThreeDSSkippedAuthentication
  | ThreeDSSuccessAuthentication
  | ThreeDSDeclinedAuthentication;

type MethodPollResult = MethodAuthResult | ThreeDSMethodAuthentication;

export const ThreeDSMethod = {
  async exec(
    longPoll: LongPoll,
    options: Invoke3DSMethodOptions,
  ): Promise<ThreeDSAuthPayload | null> {
    const box = document.createElement('div');
    box.id = METHOD_BOX_ID;
    box.style.position = 'absolute';
    box.style.width = '2px';
    box.style.height = '2px';
    box.style.left = '-10000px';

    const iframe = document.createElement('iframe');

    iframe.style.display = 'none';
    iframe.id = IFRAME_ID;
    iframe.name = IFRAME_ID;

    box.appendChild(iframe);

    const form = document.createElement('form');
    const input = document.createElement('input');

    input.type = 'hidden';
    input.id = INPUT_ID;
    input.name = INPUT_ID;
    input.value = base64
      .encode(
        JSON.stringify({
          threeDSServerTransID: options.transactionId,
          threeDSMethodNotificationURL: options.notificationUrl,
        }),
      )
      .replace(/=+$/g, '');

    form.appendChild(input);

    form.id = FORM_ID;
    form.name = FORM_ID;
    form.action = options.acsMethodUrl;
    form.target = IFRAME_ID;
    form.method = 'post';

    box.appendChild(form);

    document.body.appendChild(box);

    form.submit();

    const result = await longPoll.start<ThreeDSAuthPayload<MethodPollResult>>({
      url: options.statusUrl,
      timeout: METHOD_TIMEOUT,
      pollInterval: POLL_INTERVAL,
      predicate: (res: ThreeDSAuthPayload) => {
        return res.authentication.responseCode !== 'METHOD';
      },
    });

    this.cleanup();

    if (result == null) {
      return null;
    }

    // Not sure how to type this, it's a bit tricky
    const authentication = result.authentication as MethodAuthResult;

    return { ...result, authentication };
  },
  cleanup() {
    document.getElementById(METHOD_BOX_ID)?.remove();
  },
};
