import { getAttribute, massToggle } from './DomUtilities';
import {
  FormState,
  ThreeDSVerification,
  ThreeDSVerificationOptions,
  ThreeDSVerificationResult,
  VaultManagerOptions,
} from '../types';
import { ClassName, ElementID } from '../enums/Checkout';
import { cardTypeChangeHandler } from './cardTypeChangeHandler';
import { ProgressEvent, ThreeDSecureStatus } from '../enums/Tokenization';
import { PaymentInstrumentType } from '../enums/Tokens';
import { ClientContext } from '../core/ClientContextFactory';
import { createVaultTokenizeSuccessCallback } from './createSuccessCallback';
import { ErrorCode, PrimerClientError } from '../errors';
import { IViewUtils } from './ui/types';
import { mergeTokenWithSynthetic3DSError } from './mergeTokenWithSynthetic3DSError';
import CheckoutStore from '../store/CheckoutStore';
import { ApiEvent } from '../analytics/constants/enums';
import ThreeDSUtils from './ui/ThreeDSUtils';

export const VaultManagerPaymentMethodConfig = {
  create(
    context: ClientContext,
    store: CheckoutStore,
    viewUtils: IViewUtils,
    options: VaultManagerOptions,
  ): Record<string, unknown> {
    const { locale, card = {}, paypal = {} } = options;
    const threeDS = ThreeDSUtils.setup(context, viewUtils);

    return {
      ...options,
      locale,
      mountImmediately: false,
      logWarnings: false,

      card: {
        onCardMetadata: cardTypeChangeHandler(),
        vault: true,
        cardholderName: () =>
          getAttribute(ElementID.CARDHOLDER_NAME_INPUT, 'value'),
        css: viewUtils.styleManager.getHostedFieldStyle(),
        stylesheets: options?.style?.stylesheets,
        allowedCardNetworks: options.allowedCardNetworks,
        onChange(formState: FormState) {
          if (formState.active) {
            store.selectVault(null);
            massToggle(
              ClassName.SAVED_PAYMENT_METHOD,
              ClassName.SELECTED,
              () => false,
            );
          }
        },
        fields: {
          cardNumber: {
            container: '#primer-checkout-card-number-input',
            placement: 'prepend',
            placeholder:
              options.card?.cardNumber?.placeholder ?? '1234 1234 1234 1234',
            ariaLabel: store.getTranslations()?.cardNumber,
          },
          expiryDate: {
            container: '#primer-checkout-card-expiry-input',
            placeholder:
              options.card?.expiryDate?.placeholder ??
              store.getTranslations()?.cardExpiryPlaceholder,
            ariaLabel: store.getTranslations()?.cardExpiry,
          },
          cvv: {
            container: '#primer-checkout-card-cvv-input',
            placeholder: options.card?.cvv?.placeholder ?? '',
            ariaLabel: store.getTranslations()?.cardCVV,
          },
        },
      },
      paypal: {
        container: '#primer-checkout-apm-paypal',
        buttonColor: 'gold',
        ...paypal,
      },
      onTokenizeProgress(ev) {
        switch (ev.type) {
          case ProgressEvent.TOKENIZE_STARTED:
            viewUtils.store.setIsLoading(true);
            viewUtils.store.setIsProcessing(true);
            break;
          case ProgressEvent.TOKENIZE_ERROR:
            viewUtils.store.setIsLoading(false);
            viewUtils.store.setIsProcessing(false);
            break;
          default:
            break;
        }
      },
      onTokenizeStart: options.onTokenizeStart,
      onTokenizeError: options.onTokenizeError,
      async onTokenizeSuccess(data) {
        const onSuccess = createVaultTokenizeSuccessCallback(
          options.onTokenizeSuccess,
          viewUtils,
        );

        if (store.hasVaultedToken(data)) {
          viewUtils.store.setIsLoading(false);
          options.onTokenizeError?.(
            PrimerClientError.fromErrorCode(
              ErrorCode.DUPLICATE_PAYMENT_METHOD_ERROR,
              {
                message: 'This payment method has already been saved.',
              },
            ),
          );
          return;
        }

        if (data.paymentInstrumentType !== PaymentInstrumentType.CARD) {
          onSuccess(data);
          return;
        }

        if (
          !options.threeDSecure?.order ||
          !context.session.threeDSecureEnabled
        ) {
          onSuccess(data);
          return;
        }

        const verifyOptions: Partial<ThreeDSVerificationOptions> =
          options.threeDSecure;

        const verification: ThreeDSVerification = await threeDS.verify({
          token: data.token,
          ...verifyOptions,
        });

        if (verification.error) {
          context.analytics.call({ event: ApiEvent.threeDSecureError });
          onSuccess(mergeTokenWithSynthetic3DSError(data, verification.error));
          return;
        }

        if (verification.status === ThreeDSecureStatus.FAILED) {
          viewUtils.store.setIsLoading(false);
          context.analytics.call({ event: ApiEvent.threeDSecureFailed });

          options.onTokenizeError?.(
            PrimerClientError.fromErrorCode(ErrorCode.THREE_DS_AUTH_FAILED, {
              message: get3DSFailReason(verification),
            }),
          );
          return;
        }

        if (verification.status === ThreeDSecureStatus.SKIPPED) {
          context.analytics.call({ event: ApiEvent.threeDSecureSkipped });
        }

        if (verification.status === ThreeDSecureStatus.SUCCESS) {
          context.analytics.call({ event: ApiEvent.threeDSecureSuccess });
        }

        onSuccess(verification.data || data);
      },
    };
  },
};

function get3DSFailReason(
  result: null | ThreeDSVerificationResult<ThreeDSecureStatus.FAILED>,
): string {
  const reasonText =
    result &&
    result.data &&
    result.data.threeDSecureAuthentication &&
    result.data.threeDSecureAuthentication.reasonText;

  return reasonText || 'Declined by issuer';
}
