import { PrimerClientError } from '../../errors';
import { ClientContext } from '../../core/ClientContextFactory';
import {
  ErrorCode,
  SinglePaymentMethodCheckoutOptions,
  UniversalCheckoutOptions,
} from '../../types';
import ClientSessionHandler from '../../core/ClientSessionHandler';
import CheckoutStore from '../../store/CheckoutStore';
import { ClientSessionAction } from './types';

export type OnClientSessionDataChangeHandler = {
  onClientSessionActions: (
    clientSessionActions: ClientSessionAction[],
  ) => Promise<boolean>;
};

export const ClientSessionActionHandler = {
  create(
    context: ClientContext,
    clientSessionHandler: ClientSessionHandler,
    store: CheckoutStore,
    options: UniversalCheckoutOptions | SinglePaymentMethodCheckoutOptions,
  ): OnClientSessionDataChangeHandler {
    return {
      async onClientSessionActions(clientSessionActions) {
        options.onBeforeClientSessionUpdate?.();

        const { data, error } = await context.api.post<any, any>(
          '/client-session/actions',
          {
            actions: clientSessionActions,
          },
        );

        if (error || !data) {
          //TODO: Put this logic in ClientSessionActionService to make the DataChangeHandler dumber
          store.setIsLoading(false);
          store.setSubmitButtonDisabled(false);
          //

          console.error(
            'Could not update the client session',
            clientSessionActions,
            error,
          );
          options.onCheckoutFail?.(
            PrimerClientError.fromErrorCode(
              ErrorCode.CLIENT_SESSION_UPDATE_ERROR,
              {
                message: 'Failed to update the client session',
                data: { clientSessionActions, error },
              },
            ),
            {},
            undefined,
          );
          return false;
        }

        clientSessionHandler.setClientConfiguration(data);
        return true;
      },
    };
  },
};
