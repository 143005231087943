import {
  CheckoutStyle,
  InternalStyle,
  PaymentMethodButtonStyle,
} from '../../styles';
import { CssStyleManager } from './style/CssStyle';

export interface IStyleManager {
  setStyle(style?: CheckoutStyle, internalStyle?: InternalStyle): void;
  getStyle(): CheckoutStyle | undefined;
  getHostedFieldStyle(): string | null;
  getApmButtonStyle(): PaymentMethodButtonStyle | null;
}

export function createStyleManager(): IStyleManager {
  return new CssStyleManager();
}
