import { uuid } from './uuid';

const SESSION_ID = 'sessionId';

export function generateAndSaveSessionId(): string {
  const currentSessionId = getSessionId();
  if (currentSessionId) {
    return currentSessionId;
  }
  const newSessionId = uuid();
  sessionStorage.setItem(SESSION_ID, newSessionId);

  return newSessionId;
}

export function getSessionId(): string {
  return sessionStorage.getItem(SESSION_ID) as string;
}
