import { createCallbackHandler } from '../utils/CallbackHandler';
import { PrimerClientError } from '../errors';
import { ClientContext } from '../core/ClientContextFactory';
import {
  ErrorCode,
  OnCheckoutFailHandler,
  onBeforePaymentCreateHandler,
  OnResumeSuccessHandler,
  OnTokenizeSuccessHandler,
  Payment,
  PaymentHandlers,
  PaymentMethodToken,
  ResumeToken,
  TokenizationHandlers,
} from '../types';
import { Api, APIResponse } from '../core/Api';

interface InternalPayment extends Payment {
  date: string;
  status: string;
  requiredAction?: {
    clientToken?: string;
  };
}

const internalPaymentToPayment = ({
  id,
  orderId,
}: InternalPayment): Payment => ({
  id,
  orderId,
});

const createPayment = (api: Api, paymentMethodTokenData: PaymentMethodToken) =>
  api.post<unknown, InternalPayment>('/payments', {
    paymentMethodToken: paymentMethodTokenData.token,
  });

const resumePayment = (
  api: Api,
  paymentId: string,
  resumeTokenData: ResumeToken,
) =>
  api.post<unknown, InternalPayment>(`/payments/${paymentId}/resume`, {
    resumeToken: resumeTokenData.resumeToken,
  });

const createCheckoutFailCallbackHandler = () => {
  const callbackHandler = createCallbackHandler<OnCheckoutFailHandler>({
    functionNames: ['showErrorMessage'],
  });

  return {
    promise: callbackHandler.promise,
    handler: callbackHandler.handler,
  };
};

const createBeforePaymentCreateCallbackHandler = () => {
  const {
    handler,
    promise,
  } = createCallbackHandler<onBeforePaymentCreateHandler>({
    functionNames: ['continuePaymentCreation', 'abortPaymentCreation'],
  });

  return {
    promise: promise.then(({ functionName }) => {
      if (functionName === 'continuePaymentCreation') {
        return true;
      }

      if (functionName === 'abortPaymentCreation') {
        return false;
      }

      return true;
    }) as Promise<boolean>,
    handler,
  };
};

export const handlePaymentResponse = async (
  response: APIResponse<InternalPayment>,
  paymentHandlers: PaymentHandlers,
  responseType: 'CREATE' | 'RESUME',
  handler: OnTokenizeSuccessHandler | OnResumeSuccessHandler,
) => {
  // Network / API Error
  if (response.error || !response.data) {
    const callbackHandler = createCheckoutFailCallbackHandler();

    if (paymentHandlers.onCheckoutFail) {
      const message =
        responseType === 'CREATE'
          ? `An error occured while creating the payment. API Error: ${JSON.stringify(
              response.error,
            )}`
          : `An error occured while resuming the payment. API Error: ${JSON.stringify(
              response.error,
            )}`;

      console.error(message);
      paymentHandlers.onCheckoutFail?.(
        PrimerClientError.fromErrorCode(ErrorCode.PAYMENT_FAILED, {
          message,
        }),
        {},
        callbackHandler.handler,
      );
    } else {
      callbackHandler.handler.showErrorMessage?.();
    }

    const { functionName, args } = await callbackHandler.promise;
    if (functionName === 'showErrorMessage') {
      return handler.handleFailure(args[0]);
    }

    return handler.handleFailure();
  }

  const payment = response.data;

  if (payment.status === 'FAILED') {
    const callbackHandler = createCheckoutFailCallbackHandler();

    if (paymentHandlers.onCheckoutFail) {
      const message = `The payment "${payment.id}" was created but failed to be processed. Check the payment id ${payment.id} using the Payments API or search for the payment on your Dashboard for further explanations.`;

      paymentHandlers.onCheckoutFail?.(
        PrimerClientError.fromErrorCode(ErrorCode.PAYMENT_FAILED, {
          message,
        }),
        {
          payment,
        },
        callbackHandler.handler,
      );
    } else {
      callbackHandler.handler.showErrorMessage?.();
    }

    const { functionName, args } = await callbackHandler.promise;
    if (functionName === 'showErrorMessage') {
      return handler.handleFailure(args[0]);
    }

    return handler.handleFailure();
  }

  if (payment.requiredAction?.clientToken) {
    return handler.continueWithNewClientToken(
      payment.requiredAction.clientToken,
    );
  }

  if (payment.status === 'SUCCESS') {
    paymentHandlers.onCheckoutComplete?.({
      payment: internalPaymentToPayment(payment),
    });
    return handler.handleSuccess();
  }

  return handler.handleFailure();
};

export const CheckoutPaymentHandlers = {
  create(
    context: ClientContext,
    paymentHandlers: PaymentHandlers,
  ): TokenizationHandlers {
    let currentPayment: InternalPayment | undefined;

    return {
      onTokenizeError(error) {
        currentPayment = undefined;
        paymentHandlers.onCheckoutFail?.(error, {}, undefined);
      },

      onTokenizeShouldStart(data) {
        const callbackHandler = createBeforePaymentCreateCallbackHandler();

        if (paymentHandlers.onBeforePaymentCreate) {
          paymentHandlers.onBeforePaymentCreate?.(
            data,
            callbackHandler.handler,
          );
        } else {
          callbackHandler.handler.continuePaymentCreation();
        }

        return callbackHandler.promise;
      },

      onTokenizeStart() {
        currentPayment = undefined;
      },

      onTokenizeDidNotStart(reason) {
        paymentHandlers.onCheckoutFail?.(
          reason === 'TOKENIZATION_DISABLED'
            ? PrimerClientError.fromErrorCode(
                ErrorCode.PAYMENT_CREATION_DISABLED,
                {
                  message:
                    'The payment could not be created because payment creation has been disabled. Call `universalCheckout.setPaymentCreationEnabled(true)` to reenable it.',
                },
              )
            : PrimerClientError.fromErrorCode(
                ErrorCode.PAYMENT_CREATION_ABORTED,
                {
                  message:
                    'The payment could not be created because payment creation has been aborted in `onBeforePaymentCreate`.',
                },
              ),
          {},
          // No error messages can be displayed here.
          undefined,
        );
      },

      async onTokenizeSuccess(paymentMethodTokenData, handler) {
        const response = await createPayment(
          context.api,
          paymentMethodTokenData,
        );

        currentPayment = response.data ?? undefined;
        handlePaymentResponse(response, paymentHandlers, 'CREATE', handler);
      },

      async onResumeSuccess(resumeTokenData, handler) {
        const paymentId = resumeTokenData?.paymentId ?? currentPayment?.id;
        if (!paymentId) {
          //TODO: Error
          throw new Error();
        }

        const response = await resumePayment(
          context.api,
          paymentId,
          resumeTokenData,
        );

        return handlePaymentResponse(
          response,
          paymentHandlers,
          'RESUME',
          handler,
        );
      },

      onResumeError(error) {
        console.error('Payment resume failed', error);
        paymentHandlers.onCheckoutFail?.(
          error,
          {
            payment: currentPayment
              ? internalPaymentToPayment(currentPayment)
              : undefined,
          },
          undefined,
        );
      },
    };
  },
};
