import { noop } from '../utils/noop';
import { ClientContext } from '../core/ClientContextFactory';
import { DecodedClientToken } from '../core/ClientTokenHandler';
import { SuccessCallbackOptions } from '../core/ProgressNotifier';
import { PrimerClientError } from '../errors';
import {
  UniversalCheckoutOptions,
  ErrorCode,
  PaymentMethodToken,
  PaymentMethodType,
  ResumeToken,
  SinglePaymentMethodCheckoutOptions,
} from '../types';
import { ClientSessionActionService } from './client-session/ClientSessionActionService';
import { getSuccessScene } from './SuccessSceneUtils';
import { IViewUtils } from './ui/types';

interface TokenizeSuccessCallback<T = void> {
  (data: PaymentMethodToken, options?: SuccessCallbackOptions): T;
}

interface ResumeSuccessCallback<T = void> {
  (data: ResumeToken, options?: SuccessCallbackOptions): T;
}

export type IntentHandlers = {
  newClientTokenRequired?: boolean;
  handlers: Record<string, (decodedClientToken: DecodedClientToken) => void>;
};

type CallbackReturnValue = undefined | true | { clientToken: string };

const createResumeError = (message: string, isFromUser = false) =>
  PrimerClientError.fromErrorCode(ErrorCode.RESUME_ERROR, {
    message,
    isFromDeveloper: isFromUser,
  });

///////////////////////////////////////////
// Payment
///////////////////////////////////////////

const handlePromise = (
  promise: undefined | void | Promise<CallbackReturnValue>,
  context: ClientContext,
  viewUtils: IViewUtils,
  options: UniversalCheckoutOptions | SinglePaymentMethodCheckoutOptions,
  clientSessionActionService: ClientSessionActionService,
  callOptions: SuccessCallbackOptions | undefined,
  intentHandlers: IntentHandlers | undefined,
  errorHandler?: SuccessCallbackErrorHandler,
) => {
  const handlePromiseSuccess = (returnValue: CallbackReturnValue) => {
    /* Show success screen */
    if (!returnValue || returnValue === true) {
      if (intentHandlers?.newClientTokenRequired) {
        errorHandler?.(createResumeError(`Was expecting a new client token`));
        return;
      }

      const successScene = getSuccessScene(
        options as
          | UniversalCheckoutOptions
          | SinglePaymentMethodCheckoutOptions,
        callOptions?.successSceneHandler,
      );

      if (successScene) {
        viewUtils.store.setScene(successScene);
      }
      viewUtils.store.setIsLoading(false);
      viewUtils.store.setIsProcessing(false);

      return;
    }

    /* Refresh client token */
    if (typeof returnValue === 'object' && 'clientToken' in returnValue) {
      const newClientToken = returnValue.clientToken;

      // Same token
      if (
        newClientToken === context.clientTokenHandler.getCurrentClientToken()
      ) {
        errorHandler?.(createResumeError('Same client token provided'));
        return;
      }

      // Client token issue
      try {
        context.clientTokenHandler.setClientToken(returnValue.clientToken);
      } catch (e) {
        errorHandler?.(createResumeError('Cannot decode client token'));
        return;
      }
      const decodedClientToken = context.clientTokenHandler.getCurrentDecodedClientToken();
      if (!decodedClientToken) {
        errorHandler?.(createResumeError('Cannot decode client token'));
        return;
      }

      // Cannot process intent
      const intent = decodedClientToken?.intent ?? '';
      const intentHandler = intentHandlers?.handlers[intent];

      if (!intentHandler) {
        errorHandler?.(createResumeError(`Cannot do step ${intent}`));
        return;
      }

      intentHandler(decodedClientToken);
    }
  };

  if (promise && promise.then) {
    promise.then(handlePromiseSuccess).catch((error: Error) => {
      if (
        viewUtils.store.hasSelectedPaymentMethod ===
          PaymentMethodType.PAYMENT_CARD ||
        viewUtils.store.selectedVault
      ) {
        noop();
      } else {
        clientSessionActionService.unselectPaymentMethod();
      }

      viewUtils.store.setIsLoading(false);
      viewUtils.store.setIsProcessing(false);
      viewUtils.store.setCurrentToken(null);
      errorHandler?.(createResumeError(error.message, true));
    });
  } else {
    handlePromiseSuccess(undefined);
  }
};

export type SuccessCallbackErrorHandler = (e: PrimerClientError) => void;

export function createTokenizeSuccessCallback(
  fn: TokenizeSuccessCallback<void | Promise<CallbackReturnValue>>,
  context: ClientContext,
  viewUtils: IViewUtils,
  options: UniversalCheckoutOptions | SinglePaymentMethodCheckoutOptions,
  clientSessionActionService: ClientSessionActionService,
  intentHandlers?: IntentHandlers,
  errorHandler?: SuccessCallbackErrorHandler,
): TokenizeSuccessCallback {
  return (data: PaymentMethodToken, callOptions?: SuccessCallbackOptions) => {
    viewUtils.store.setCurrentToken(data);
    const promise = fn(data);
    handlePromise(
      promise,
      context,
      viewUtils,
      options,
      clientSessionActionService,
      callOptions,
      intentHandlers,
      errorHandler,
    );
  };
}

export function createResumeSuccessCallback(
  fn: ResumeSuccessCallback<void | Promise<CallbackReturnValue>>,
  context: ClientContext,
  viewUtils: IViewUtils,
  options: UniversalCheckoutOptions | SinglePaymentMethodCheckoutOptions,
  clientSessionActionService: ClientSessionActionService,
  intentHandlers?: IntentHandlers,
  errorHandler?: SuccessCallbackErrorHandler,
): ResumeSuccessCallback {
  return (data: ResumeToken, callOptions?: SuccessCallbackOptions) => {
    const promise = fn(data);
    handlePromise(
      promise,
      context,
      viewUtils,
      options,
      clientSessionActionService,
      callOptions,
      intentHandlers,
      errorHandler,
    );
  };
}

///////////////////////////////////////////
// Vault
///////////////////////////////////////////

const handleVaultPromise = (
  promise: undefined | void | Promise<CallbackReturnValue>,
  viewUtils: IViewUtils,
) => {
  //TODO(at): No success scene in vault manager
  const success = () => {
    viewUtils.store.setIsLoading(false);
    viewUtils.store.setIsProcessing(false);
  };

  if (promise && promise.then) {
    promise.then(success).catch((error: Error) => {
      viewUtils.store.setErrorMessage(error.message);
      viewUtils.store.setIsLoading(false);
      viewUtils.store.setIsProcessing(false);
      viewUtils.store.setCurrentToken(null);
    });
  } else {
    success();
  }
};

export function createVaultTokenizeSuccessCallback(
  fn: TokenizeSuccessCallback<void | Promise<CallbackReturnValue>> | undefined,
  viewUtils: IViewUtils,
) {
  return (data: PaymentMethodToken) => {
    const promise = fn?.(data);
    handleVaultPromise(promise, viewUtils);
  };
}
