/* eslint-disable prettier/prettier */
import { createGlobalStyle, css } from 'styled-components';
import {
  createWithStyle,
  getTextStyle,
} from '@primer-io/shared-library/components';

///////////////////////////////////////////
// Form Spacings
///////////////////////////////////////////

const withFormSpacingsStyle = createWithStyle((style) => style?.formSpacings);

const formSpacingsCss = css`
  .PrimerCheckout__formField {
    &:not(:first-of-type) {
      ${withFormSpacingsStyle((style) => ({
        marginTop: style?.betweenInputs,
      }))}
    }
    & > .PrimerCheckout__label {
      ${withFormSpacingsStyle((style) => ({
        marginBottom: style?.betweenLabelAndInput,
      }))}
    }
  }

  .PrimerCheckout__formField--dual > .PrimerCheckout__formField {
    margin-top: 0;
  }
`;

///////////////////////////////////////////
// Payment Method Button Style
///////////////////////////////////////////

const withPaymentMethodButtonStyle = createWithStyle(
  (style) => style?.paymentMethodButton,
);

const paymentMethodButtonCss = css`
  .PrimerCheckout__apmButton {
    ${withPaymentMethodButtonStyle((style) => ({
      borderColor: style?.borderColor,
      borderRadius: style?.borderRadius,
      boxShadow: style?.boxShadow,
      height: style?.height,
    }))}

    &:not(:first-of-type) {
      ${withPaymentMethodButtonStyle((style) => ({
        marginTop: style?.marginTop,
      }))}
    }
  }

  .PrimerCheckout__iconTextButton {
    ${withPaymentMethodButtonStyle((style) => getTextStyle(style?.primaryText))}

    ${withPaymentMethodButtonStyle((style) => ({
      background: style?.background,
    }))}
  }
`;

///////////////////////////////////////////
// Scene Style
///////////////////////////////////////////

const withSceneStyle = createWithStyle((style) => style?.scene);

const getSceneTransitionStyle = (style) => {
  return {
    opacity: style?.opacity,
    transform: style?.transform,
    transition: style?.transition,
  };
};

const getSceneTransitionEventStyle = (style, type?: string) => {
  const prefix = `${
    type ? `.PrimerCheckout__SceneTransition--${type}` : ''
  }.PrimerCheckout__scene`;

  const getClassNames = (state) => `${prefix}.PrimerCheckout--${state}`;

  return css`
    ${getClassNames('enter')} {
      ${getSceneTransitionStyle(style?.enter)}
    }

    ${getClassNames('entering')} {
      ${getSceneTransitionStyle(style?.entering)}
    }

    ${getClassNames('exit')} {
      ${getSceneTransitionStyle(style?.exit)}
    }

    ${getClassNames('exiting')} {
      ${getSceneTransitionStyle(style?.exiting)}
    }
  `;
};

const sceneCss = css`
  #primer-checkout-content {
    ${withSceneStyle((style) => ({ transition: style?.content?.transition }))}
  }

  ${withSceneStyle((style) => getSceneTransitionEventStyle(style))}
  ${withSceneStyle((style) => getSceneTransitionEventStyle(style?.pop, 'pop'))}
  ${withSceneStyle((style) =>
    getSceneTransitionEventStyle(style?.push, 'push'),
  )}

  .PrimerCheckout__scene.PrimerCheckout--entering {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
  }

  .PrimerCheckout__scene.PrimerCheckout--entered {
    position: relative;
  }

  .PrimerCheckout__scene.PrimerCheckout--exited {
    position: absolute;
    visibility: hidden;
    display: none;
    opacity: 0;
  }
`;

///////////////////////////////////////////
// Global
///////////////////////////////////////////
const GlobalStyle = createGlobalStyle`
    ${formSpacingsCss}
    ${paymentMethodButtonCss}
    ${sceneCss}
`;

export default GlobalStyle;
