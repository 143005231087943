import deepmerge from 'deepmerge';
import { createToggleVisibilityAnimated } from './ElementVisibility';
import { createStyleManager, IStyleManager } from './StyleManager';
import { defaultCheckoutStyle } from './style/defaultStyle';
import { IViewUtils, toggleVisibilityAnimatedFn } from './types';
import {
  UniversalCheckoutOptions,
  SceneTransitionOptions,
  SinglePaymentMethodCheckoutOptions,
  VaultManagerOptions,
} from '../../types';
import CheckoutStore from '../../store/CheckoutStore';
import { SceneTransitionManager } from './SceneTransitionManager';
import { SceneTransitionStyle } from '../../styles';

export class ViewUtils implements IViewUtils {
  public toggleVisibilityAnimated: toggleVisibilityAnimatedFn;

  public store: CheckoutStore;

  public styleManager: IStyleManager;
}

function createViewUtils(
  store: CheckoutStore,
  options?:
    | UniversalCheckoutOptions
    | VaultManagerOptions
    | SinglePaymentMethodCheckoutOptions,
): ViewUtils {
  const viewUtils = new ViewUtils();
  let transitionStyle: SceneTransitionStyle | undefined;
  if (store.getState().scene.transition !== false) {
    const sceneTransitionManager = new SceneTransitionManager(
      store.getState().scene.transition as SceneTransitionOptions,
    );
    transitionStyle = sceneTransitionManager.getTransitionStyles();
  } else {
    transitionStyle = SceneTransitionManager.getNoTransitionStyle();
  }

  viewUtils.toggleVisibilityAnimated = createToggleVisibilityAnimated();
  viewUtils.store = store;
  viewUtils.styleManager = createStyleManager();
  viewUtils.styleManager.setStyle(
    deepmerge(defaultCheckoutStyle, options?.style ?? {}),
    { scene: transitionStyle },
  );

  return viewUtils;
}

export const createCheckoutViewUtils = (
  store: CheckoutStore,
  options: UniversalCheckoutOptions | SinglePaymentMethodCheckoutOptions,
): ViewUtils => createViewUtils(store, options);

export const createVaultManagerViewUtils = (
  store: CheckoutStore,
  options: VaultManagerOptions,
): ViewUtils => createViewUtils(store, options);
