import { Nullable } from './utilities';

export enum ErrorCode {
  INITIALIZATION_ERROR = 'INITIALIZATION_ERROR',
  NO_PAYMENT_METHODS = 'NO_PAYMENT_METHODS',
  PRIMER_TEARDOWN = 'PRIMER_TEARDOWN',
  PRIMER_SERVER_ERROR = 'PRIMER_SERVER_ERROR',
  THREE_DS_SETUP_ERROR = 'THREE_DS_SETUP_ERROR',
  THREE_DS_AUTH_FAILED = 'THREE_DS_AUTH_FAILED',
  THREE_DS_NEGOTIATION_ERROR = 'THREE_DS_NEGOTIATION_ERROR',
  TOKENIZATION_ERROR = 'TOKENIZATION_ERROR',
  DUPLICATE_PAYMENT_METHOD_ERROR = 'DUPLICATE_PAYMENT_METHOD_ERROR',
  CARD_NUMBER_ERROR = 'CARD_NUMBER_ERROR',
  PAYMENT_METHOD_NOT_SETUP = 'PAYMENT_METHOD_NOT_SETUP',
  PAYMENT_METHOD_NOT_PROVIDED = 'PAYMENT_METHOD_NOT_SETUP',
  PAYMENT_METHOD_NOT_COMPATIBLE = 'PAYMENT_METHOD_NOT_COMPATIBLE',
  RESUME_ERROR = 'RESUME_ERROR',
  TAX_ERROR = 'TAX_ERROR',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_CREATION_ABORTED = 'PAYMENT_CREATION_ABORTED',
  PAYMENT_CREATION_DISABLED = 'PAYMENT_CREATION_DISABLED',
  CLIENT_SESSION_UPDATE_ERROR = 'CLIENT_SESSION_UPDATE_ERROR',
}

interface ErrorOptions {
  message: string;
  diagnosticsId?: string;
  errorId?: string;
  data?: any;
  isFromDeveloper?: boolean;
}

export class PrimerClientError extends Error {
  public code: ErrorCode;

  public message: string;

  public diagnosticsId: Nullable<string>;

  public data?: any;

  public isFromDeveloper: boolean;

  public static fromErrorCode(
    code: ErrorCode,
    options: ErrorOptions,
  ): PrimerClientError {
    return new PrimerClientError(code, options);
  }

  constructor(code: ErrorCode, options: ErrorOptions) {
    super(options.message);
    this.message = options.message;
    this.code = code;
    this.diagnosticsId = options.diagnosticsId || null;
    this.data = options.data;
    this.isFromDeveloper = options.isFromDeveloper ?? false;
  }
}

export const throwPrimerClientError = (error: PrimerClientError) => {
  console.error(error.message);
  throw error;
};
