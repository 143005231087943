import { PackageDeclaration } from '../modules/types';
import { IModule, IModuleFactory, IPackage, PackageKey } from './types';

type Props = {
  moduleFactory: IModuleFactory;
  key: PackageKey;
};

export default class Package implements IPackage {
  readonly key: PackageKey;

  private moduleFactory: IModuleFactory;

  private declarationModule: IModule;

  constructor({ moduleFactory, key }: Props) {
    this.key = key;
    this.moduleFactory = moduleFactory;

    this.declarationModule = this.moduleFactory.getModule({
      ...this.key,
      path: 'declaration',
    });
  }

  async getDeclaration() {
    const declaration = (await this.declarationModule.import()) as PackageDeclaration;
    return declaration;
  }

  getModule(path: string) {
    return this.moduleFactory.getModule({ ...this.key, path });
  }
}
